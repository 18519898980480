import React, { Fragment } from "react"
// import M from "materialize-css"

const Timeline = props => {

    const eventos = props.eventos || {}

    const setEventos = () => {
        const size = props.estados.length
        return props.estados.map((evento, pos)=>{
            return(
                <div className={(pos+1 === size)? "event highlight" : "event"} data-model-title={ evento.id } key={ pos }>
                    <div className="event-box">
                        <div className="event-time">
                            <div className="event-number">{ evento.numero }</div>
                            <div className="event-text">{ evento.dia }
                                <div className="event-date">{ evento.mes_anio }</div>
                            </div>
                        </div>
                        <div className="event-title">{ evento.nombre }</div>
                    </div>
                    <div className="event-author">{ evento.autor } </div>
                    <div className="time">{ evento.hora }</div>
                </div>
            )
        })
    }

    return (
        <Fragment>
            <div style={{overflowX:"auto", minHeight:250+ "px"}}>
                <div className="timeline">
                    { setEventos() }
                    <div className="event" data-model-title="50804">
                        <div className="event-box">
                            <div className="current">
                                Finalizado
                            </div>
                        </div>
                    </div>
                    <div className="event future last">
                        <div className="event-box"></div>
                    </div>
                </div>
            </div>
            <div>
                <table>
                    <thead>
                        <tr>
                            <th>Estado</th>
                            <th>Fecha</th>
                            <th>Observación</th>
                        </tr>
                    </thead>
                    <tbody>
                    {
                        props.estados.map((evento, pos)=>{
                            return (
                                <tr key={pos}>
                                    <td>{ evento.nombre }</td>
                                    <td> {evento.fecha }</td>
                                    <td>{ evento.observacion }</td>
                                </tr>
                            )
                        })
                    }
                    </tbody>
                </table>
            </div>
        </Fragment>
    )

}

export default Timeline