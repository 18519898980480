import React from "react"
import { getSedes } from "./../../services/admin.service"
import { useDataForm } from "../../context/FormProvider"
import { useUser } from "../../context/UserProvider"
import { isEmpty } from "lodash"

const InfoPostulacion = () => {
	const { dataPostulacion } = useDataForm()
	const { optionsSelect } = useUser()
	if (isEmpty(optionsSelect)) {
		return <h2>No se han cargado correctamente las opciones</h2>
	} else {
		return (
			<div className="postulacion-info left mt-2">
				<span>
					<b>Periodo:</b> {dataPostulacion?.periodo}
				</span>
				<span>
					<b>Sede:</b> {getSedes().find(x => x.id === dataPostulacion?.sede)?.nombre}
				</span>
				<span>
					<b>Tipo de Carrera:</b>{" "}
					{optionsSelect?.tipo_carrera.find(x => x.value === dataPostulacion?.tipo)?.name}
				</span>
			</div>
		)
	}
}

export default InfoPostulacion
