import React from "react"
import {withRouter} from "react-router-dom"
import Header from './../../components/Header/Header'

const Postulaciones = () => {

    return (
        <div>
            <Header />
            <div className="row container title-scope">
                <div className="title col s12 l12 left"><h6>Menú de</h6><h2>Postulaciones</h2></div>
            </div>
            <div className="row container title-scope margin-bottom-24px">
                <div className="col s12 m12 l8 note text-align-justify">A continuación se muestra el menú de Postulaciones
                </div>
            </div>
            <div className="container">
                <div className="row" style={{marginTop:64 + "px"}}>
                    <div className="col s12 m4 l3">
                        <div className="card small">
                            <div className="card-content">
                                <p>Postulaciones</p>
                                <span className="card-title text-ellipsis theme-text">Bandeja de Entrada</span>
                                <center><i className="large material-icons blue-text">mail</i></center>
                                <span className="card-content-description">Visualize las postulaciones recién llegadas</span>
                            </div>
                            <div className="card-action">
                                <a href="/admin/postulaciones/bandeja" className="btn progress-trigger theme-button-gradient-45deg">Ir</a>
                            </div>
                        </div>
                    </div>
                    <div className="col s12 m4 l3">
                        <div className="card small">
                            <div className="card-content">
                                <p>Postulaciones</p>
                                <span className="card-title text-ellipsis theme-text">Lista de Espera</span>
                                <center><i className="large material-icons purple-text">queue</i></center>
                                <span className="card-content-description">Visualize la lista de espera</span>
                            </div>
                            <div className="card-action">
                                <a href="/admin/postulaciones/listaespera" className="btn progress-trigger theme-button-gradient-45deg">Ir</a>
                            </div>
                        </div>
                    </div>
                    <div className="col s12 m4 l3">
                        <div className="card small">
                            <div className="card-content">
                                <p>Postulaciones</p>
                                <span className="card-title text-ellipsis theme-text">Todas</span>
                                <center><i className="large material-icons red-text">recent_actors</i></center>
                                <span className="card-content-description">Visualize todas las postulaciones</span>
                            </div>
                            <div className="card-action">
                                <a href="/admin/postulaciones/todas" className="btn progress-trigger theme-button-gradient-45deg">Ir</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )

}

export default withRouter(Postulaciones)