import {authHeader} from "./auth-header"
import axios from "axios"
import { API_URL } from "../config"

const API = API_URL() + "api/postulante/"
const config = {
	headers: authHeader()
}

const getGeneros = () => {
	const URL = API + "generos"
	return axios
		.get(URL, config)
		.then(res => res)
		.catch(err => console.log(err))
}

const getEstadoCivil = () => {
	const URL = API + "estados_civiles"
	return axios
		.get(URL, config)
		.then(res => res)
		.catch(err => console.log(err))
}

const getNacionalidades = () => {
	const URL = API + "nacionalidades"
	return axios
		.get(URL, config)
		.then(res => res)
		.catch(err => console.log(err))
}

const getPaises = () => {
	const URL = API + "paises"
	return axios
		.get(URL, config)
		.then(res => res)
		.catch(err => console.log(err))
}

const getVisas = () => {
	const URL = API + "visas"
	return axios
		.get(URL, config)
		.then(res => res)
		.catch(err => console.log(err))
}


/* llamada a api para traer todas las opciones en una sola */
const getOptionsSelect = () => {
	const URL = API + "opciones_etapa2"
	return axios
		.get(URL, config)
		.then(res => res.data.data)
		.catch(err => console.log(err))
}

export { getGeneros, getEstadoCivil, getNacionalidades, getPaises, getVisas, getOptionsSelect }
