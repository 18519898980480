import React from "react"
import { withRouter } from "react-router-dom"
import "./Login.scss"
import { redirectClaveUnica } from "./../../services/auth.service"

const Login = ({ isLogged, history, loader }) => {
	
	const loginCU = () => {
		redirectClaveUnica()
	}

	const loginRut = () => {
		history.push("/login")
	}

	const goForm = () => {
		history.push("/form")
	}

	const textLogin = () => {
		return isLogged
			? "Sesión iniciada correctamente"
			: "Para ingresar debes iniciar sesión con tu clave única o bien con tu cédula de identidad"
	}

	return (
		<div id="login-container" className="purple">
			<div className="container">
				<div className="row mb-0 valign-wrapper">
					<div className="col s12 m4 l4">
						<div id="app-name" className="purple-text text-lighten-5">
							Admisión en Línea
						</div>
					</div>
					<div className="col s12 m4 l4 purple-text text-lighten-5">
						{loader ? "Verificando usuario..." : textLogin()}
					</div>
					<div className="col s12 m4 l4">
						{loader ? (
							<div className="preloader-wrapper active">
								<div className="spinner-layer spinner-blue-only">
									<div className="circle-clipper left">
										<div className="circle"></div>
									</div>
									<div className="gap-patch">
										<div className="circle"></div>
									</div>
									<div className="circle-clipper right">
										<div className="circle"></div>
									</div>
								</div>
							</div>
						) : isLogged ? (
							<a className="waves-effect btn-cu hover-shw" onClick={() => goForm()}>
								<i className="material-icons right">open_in_new</i>
								Ir a formulario
							</a>
						) : ( <div style={{ display: "inline" }}>
								<a className="btn-cu hover-shw" onClick={() => loginCU()}>
									<span className="cl-claveunica"></span>
									<span className="texto">Iniciar sesión</span>
								</a>
								<span style={{paddingLeft: 10 + "px"}}></span>
								<a className="btn-cu hover-shw icon-center" onClick={() => loginRut()}>
									<i className="material-icons ">person</i>
									<span className="texto">Iniciar sesión (Rut)</span>
								</a>
							</div>
						)}
					</div>
				</div>
			</div>
		</div>
	)
}

export default withRouter(Login)
