import { isMatch } from "lodash"
import React, { createContext, useContext, useState} from "react"
import { formatDate } from "../utils"

const FormContext = createContext()

const FormProvider = ({ children }) => {
	const [dataPostulante, setDataPostulante] = useState({})
	const [dataAcademica, setDataAcademica] = useState({})
	const [dataExtranjera, setDataExtranjera] = useState({})
	const [dataCarreras, setDataCarreras] = useState({})
	const [dataPostulacion, setDataPostulacion] = useState({})
	const [dataArchivos, setDataArchivos] = useState({})
	const [tiposArchivo, setTiposArchivo] = useState([])

	/* Se ejecuta la inicio luego de login claveunica */
	const saveInitialDataFromLogin = data => {
		updatePostulante(data.postulante)
		updateAcademica(data.informacion_academica)
		updateExtranjera(data.informacion_extranjera)
		updatePostulacion(data.postulacion)
		updateCarreras(data.carreras)
		updateArchivos(data.archivos)
		// callOptions()
	}

	/* Carga la data del formulario guardado en localstorage en los hooks */
	const loadDataLocalStorage = () => {
		const dataLocal = JSON.parse(localStorage.getItem("dataForm"))
		if (dataLocal) {
			setDataExtranjera(dataLocal.informacion_extranjera || {})
			setDataAcademica(dataLocal.informacion_academica || {})
			setDataPostulante(dataLocal.postulante)
			setDataCarreras(dataLocal.carreras || {})
			setDataPostulacion(dataLocal.postulacion || {})
			setDataArchivos(dataLocal.archivos || {})
			// setOptionsSelect(dataLocal.options)
		}
	}

	/* ************************* ACTUALIZA HOOKS POSTULANTE  ***********************************/
	const updatePostulante = data => {
		const parsed = {
			...data,
			nacimiento: formatDate(data.nacimiento)
		}
		setDataPostulante({ ...dataPostulante, ...parsed })
		updateLocalStorage("postulante", parsed)
	}

	const updateAcademica = data => {
		setDataAcademica({ ...dataAcademica, ...data })
		updateLocalStorage("academica", data)
	}

	const updateExtranjera = data => {
		const parsed = data
			? {
					...data,
					fecha_emision: formatDate(data.fecha_emision),
					fecha_vencimiento: formatDate(data.fecha_vencimiento)
			  }
			: null
		setDataExtranjera({ ...dataExtranjera, ...parsed })
		updateLocalStorage("extranjera", parsed)
	}

	const updatePostulacion = data => {
		setDataPostulacion(data)
		updateLocalStorage("postulacion", data)
	}

	const updateCarreras = data => {
		setDataCarreras(data)
		updateLocalStorage("carreras", data)
	}

	const updateArchivos = data => {
		setDataArchivos(data)
		updateLocalStorage("archivos", data)
	}
	/* ******************************************************************************************* */

	/* actualiza localStorage segun el tipo de data que envio */
	const updateLocalStorage = (type, data) => {
		const dataLocal = JSON.parse(localStorage.getItem("dataForm"))
		let newDataLocal = {}

		switch (type) {
			case "postulante":
				newDataLocal = {
					...dataLocal,
					postulante: {
						...dataLocal?.postulante,
						...data
					}
				}
				break
			case "academica":
				newDataLocal = {
					...dataLocal,
					informacion_academica: {
						...dataLocal?.informacion_academica,
						...data
					}
				}
				break
			case "extranjera":
				newDataLocal = {
					...dataLocal,
					informacion_extranjera: {
						...dataLocal?.informacion_extranjera,
						...data
					}
				}
				break
			case "carreras":
				newDataLocal = {
					...dataLocal,
					carreras: Object.values({
						...data
					})
				}
				break
			case "postulacion":
				newDataLocal = {
					...dataLocal,
					postulacion: {
						...dataLocal?.postulacion,
						...data
					}
				}
				break
			case "archivos":
				newDataLocal = {
					...dataLocal,
					archivos: data
				}
				break
			default:
				newDataLocal = dataLocal
				break
		}

		localStorage.setItem("dataForm", JSON.stringify(newDataLocal))
	}

	/* Checkea si hay cambios en los formularios */
	const formHasChanges = (dataForm, step) => {
		switch (step) {
			case 2:
				return !isMatchStep2(dataForm)
			case 3:
				return !isMatchStep3(dataForm)
			case 4:
				return !isMatchStep4(dataForm)
			default:
				break
		}
	}

	const isMatchStep2 = dataForm => {
		const dataLocal = JSON.parse(localStorage.getItem("dataForm"))
		if (!dataLocal) {
			return false
		}

		const parsedPostulante = {
			...dataForm.postulante,
			estudios_previos: dataForm.postulante.estudios_previos === "true",
			periodo_egreso_em:
				typeof dataLocal.postulante.periodo_egreso_em === "number"
					? parseInt(dataForm.postulante.periodo_egreso_em)
					: dataForm.postulante.periodo_egreso_em.toString()
		}
		const newDataExtranjera = dataForm.informacion_extranjera
		const newDataAcademica = dataForm.informacion_academica

		return (
			isMatch(dataLocal.postulante, parsedPostulante) &&
			isMatch(dataLocal.informacion_academica, newDataAcademica) &&
			isMatch(dataLocal.informacion_extranjera, newDataExtranjera)
		)
	}

	const isMatchStep3 = dataForm => {
		const dataLocal = JSON.parse(localStorage.getItem("dataForm"))
		if (!dataLocal) {
			return false
		}

		// para que coincidan los tipos de datos del telefono
		let parsedPostulante = {
			...dataForm.postulante,
			depto: dataForm.postulante.depto === "" ? null : dataForm.postulante.depto
		}
		if (typeof dataLocal.postulante.telefono === "number") {
			parsedPostulante = {
				...parsedPostulante,
				telefono: parseInt(dataForm.postulante.telefono),
				telefono_trabajo: parseInt(dataForm.postulante.telefono_trabajo)
			}
		} else {
			parsedPostulante = {
				...parsedPostulante,
				telefono: dataForm.postulante.telefono.toString(),
				telefono_trabajo: dataForm.postulante.telefono_trabajo.toString()
			}
		}

		return isMatch(dataLocal.postulante, parsedPostulante)
	}

	const isMatchStep4 = dataForm => {
		const dataLocal = JSON.parse(localStorage.getItem("dataForm"))
		if (!dataLocal) {
			return false
		}
		return isMatch(dataLocal.carreras, dataForm)
	}

	/* Valores a retornar */
	const value = {
		saveInitialDataFromLogin,
		dataPostulante,
		dataAcademica,
		dataExtranjera,
		dataCarreras,
		dataPostulacion,
		setDataPostulante,
		setDataExtranjera,
		setDataAcademica,
		updatePostulante,
		updateAcademica,
		updateExtranjera,
		updatePostulacion,
		updateCarreras,
		loadDataLocalStorage,
		updateArchivos,
		dataArchivos,
		formHasChanges,
		tiposArchivo,
		setTiposArchivo
	}
	return <FormContext.Provider value={value}>{children}</FormContext.Provider>
}

const useDataForm = () => {
	const context = useContext(FormContext)
	if (!context) {
		throw new Error("useFormContet debe estar dentro del proveedor ContextProvider")
	}
	return context
}

export { useDataForm, FormProvider }
