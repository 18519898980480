import React, { Fragment, useState } from "react"
import {setPeriodos} from '../../../services/admin.service'
import showToast from "../../Toast/Toast";
import Loader from "../../Loader/Loader";

const ModalPeriodo = () => {
    //const { id } = props
    const [loader, setLoader] = useState(false)
    const [periodo, setPeriodo] = useState(null)
    const periodoChange = () => e => {
        setPeriodo(e.target.value)
    }
    const [fecha_inicio, setFechaInicio] = useState(null)
    const fiChange = () => e => {
        setFechaInicio(e.target.value)
    }
    const [fecha_termino, setFechaTermino] = useState(null)
    const ftChange = () => e => {
        setFechaTermino(e.target.value)
    }

    const submitPeriodo = async () => {
        if(periodo===null){
            showToast("Debe ingresar un Periodo")
            return null
        }
        if(fecha_inicio===null){
            showToast("Debe ingresar una Fecha de Inicio")
            return null
        }
        if(fecha_termino===null){
            showToast("Debe ingresar una Fecha de Término")
            return null
        }
        setLoader(true)
        const data = {
            'periodo' : periodo,
            'fecha_inicio' : fecha_inicio,
            'fecha_termino' : fecha_termino
        }
        const result = await setPeriodos(data)
        if(result?.status === 200){
            showToast("Periodo ingresado exitosamente")
        }
        else{
            showToast("Error al ingresar periodo")
        }
        setLoader(false)
    }


    return (
        <Fragment>
            <div className="modal" id="modal-periodo">
                <div className="modal-content">
                    <h4>Agregar un nuevo Periodo</h4>
                    <span>Es importante recalcar que la plataforma admite solo un Periodo activo, por lo tanto, si se ingresa uno nuevo, éste automáticamente reemplazará al anterior</span>
                    <div className="row">
                        <div className="col s4 m4 l4">
                            <div className="input-field">
                                <input type="number" id="periodo" onChange={ periodoChange() } />
                                <label htmlFor="periodo">Periodo</label>
                            </div>
                        </div>
                        <div className="col s4 m4 l4">
                            <div className="input-field">
                                <input type="date" id="fecha_inicio" onChange={ fiChange() } />
                                <label htmlFor="fecha_inicio">Fecha de Inicio</label>
                            </div>
                        </div>
                        <div className="col s4 m4 l4">
                            <div className="input-field">
                                <input type="date" id="fecha_termino" onChange={ ftChange() } />
                                <label htmlFor="fecha_termino">Fecha de Término</label>
                            </div>
                        </div>
                    </div>
                    <Loader loader={loader} />
                </div>
                <div className="modal-footer">
                    <a role="button" className="modal-close waves-effect waves-green btn-flat">Cerrar</a>
                    <a role="button" id={"modal-p-close"} onClick={()=>submitPeriodo() } className="waves-effect waves-green btn">Agregar</a>
                </div>
            </div>
        </Fragment>
    )

}

export default ModalPeriodo