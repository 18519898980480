/* Opciones para los Select */

const Options = () => {
	return {
		genero: [
			{ value: "F", name: "Femenino" },
			{ value: "M", name: "Masculino" }
		],
		estadoCivil: [
			{ value: "S", name: "Soltero" },
			{ value: "C", name: "Casado" }
		],
		nacionalidad: [
			{ value: "N", name: "Nacional" },
			{ value: "E", name: "Extranjero" }
		],

		paises: [
			{ value: "arg", name: "Argentina" },
			{ value: "br", name: "Brasil" }
		],

		tipoVisa: [
			{ value: "visa1", name: "Visa 1" },
			{ value: "visa2", name: "Visa 2" },
			{ value: "visa3", name: "Visa 3" },
			{ value: "visa4", name: "Visa 4" },
			{ value: "visa5", name: "Visa 5" }
		],

		estadoCarrera: [
			{ value: "T", name: "Titulado" },
			{ value: "ETC", name: "ETC??" }
		],

		regiones: [
			{ value: "1", name: " 1" },
			{ value: "2", name: " 2" },
			{ value: "3", name: " 3" },
			{ value: "4", name: " 4" },
			{ value: "5", name: " 5" }
		],

		provincias: [
			{ value: "1", name: " 1" },
			{ value: "2", name: " 2" },
			{ value: "3", name: " 3" },
			{ value: "4", name: " 4" },
			{ value: "5", name: " 5" }
		],

		ciudades: [
			{ value: "1", name: " 1" },
			{ value: "2", name: " 2" },
			{ value: "3", name: " 3" },
			{ value: "4", name: " 4" },
			{ value: "5", name: " 5" }
		],

		comunas: [
			{ value: "1", name: " 1" },
			{ value: "2", name: " 2" },
			{ value: "3", name: " 3" },
			{ value: "4", name: " 4" },
			{ value: "5", name: " 5" }
		],

		siOno: [
			{ value: "true", name: "Sí" },
			{ value: "false", name: "No" }
		],

		tiposTelefono: [
			{ value: "C", name: "Celular" },
			{ value: "F", name: "Teléfono fijo" }
		],

		egresoMedia: generateYearsArray().map(year => {
			return { value: year, name: year }
		})
	}
}

const generateYearsArray = () => {
	const max = new Date().getFullYear()
	const min = 1970
	const years = []
/* 	for (let index = min; index <= max; index++){
		years.push(index.toString())
	}	 */
		for (let index = max; index >= min; index--) {
			years.push(index.toString())
		}	

	return years
}

export default Options