import React from "react"
import { Draggable, Droppable } from "react-beautiful-dnd"

const CareerListSelected = ({ selectedCarreras }) => {
	if(selectedCarreras.length > 0) {
	return (
		<Droppable droppableId="seletedCarsDroppable">
			{provided => (
				<ul className={`collection with-header custom-carreras-1`} ref={provided.innerRef}>
					<li className="collection-header">
						<h6>Carreras Seleccionadas</h6>
					</li>
					{selectedCarreras.map((car, index) => (
						<Draggable draggableId={car.codigo_carrera} index={index} key={car.codigo_carrera}>
							{(provided, snapshot) => (
								<li
									key={index}
									className="collection-item"
									ref={provided.innerRef}
									{...provided.draggableProps}
									{...provided.dragHandleProps}
								>
									<span>
										<b>{index + 1}°</b>- {car.nombre} - ({car.jornada})
									</span>
								</li>
							)}
						</Draggable>
					))}
					{provided.placeholder}
				</ul>
			)}
		</Droppable>
	)
	}else{
		return null
	}

}

export default CareerListSelected
