import React, { Fragment, useEffect, useState } from "react"
import showToast from "../../Toast/Toast"
// import { setCambioEstado, setMensajePostulacion } from "../../../services/admin.service"
import Loader from "../../Loader/Loader"
import M from "materialize-css"
import { setConfirmaSerie } from "../../../services/admin.service"

const ModalConfirmaSerie = props => {
	const { user, refresh } = props
	const [loader, setLoader] = useState(false)
	const [isUser, setIsUser] = useState(false)
	const [nroIsConfirm, setNroIsConfirm] = useState(false)

	useEffect(() => {
		const checkUser = () => {
			if (user) {
				setIsUser(true)
				setNroIsConfirm(user.serie_confirmada)
			} else {
				setIsUser(false)
			}
		}
		checkUser()
	}, [user])

	const styleInput = {
		backgroundColor: "#86438c12",
		minHeight: 2.9 + "rem",
		width: 45 + "%",
		fontSize: 17 + "px",
		paddingLeft: 10 + "px",
		border: "1px solid #86438c",
		cursor: "pointer"
	}

	const handleChangeCheck = () => e => {
		setNroIsConfirm(e.target.value === "true")
	}

	const handleSubmit = () => async e => {
		e.preventDefault()

		setLoader(true)
		const response = await setConfirmaSerie(nroIsConfirm, user.id)

		if (response?.status === 200) {
			showToast("Cambio registrado correctamente")
		} else {
      showToast("Se produjo un problema. Cambio no realizado")
		}
    refresh()
		setLoader(false)
		const btn = document.getElementById("modal-confirm-close")
		btn.click()
	}

	const copyToClipboard = tipo => {
		switch (tipo) {
			case "rut":
				document.getElementById("user_rut").select()
				document.execCommand("copy")
				showToast("Rut copiado")
				return
			case "nro":
				document.getElementById("user_nro").select()
				document.execCommand("copy")
				showToast("Nro Documento copiado")
				return
			default:
				return
		}
	}

	return (
		<Fragment>
			<div className="modal" id="modal-confirma-serie">
				<div className="modal-content">
					<div className="text-center">
						<h4>Verificar Número de Serie Documento</h4>
						Para verificar la vigencia y validez del número de documento debe dirigirse a la
						siguiente web:
						<br />
						<br />
						<a
							href="https://portal.sidiv.registrocivil.cl/usuarios-portal/pages/DocumentRequestStatus.xhtml"
							target="_blank"
							rel="noopener noreferrer"
							className="mx-2 my-4"
						>
							<b style={{ fontSize: 24 + "px" }}> Link Registro Civil</b>
						</a>
						<br />
						<br />
						En ella debe especificar el Rut del postulante, tipo de documento y el número de
						documento. Luego de responder el captcha, el sistema devolverá el estado del
						documento.
						<br />
						<br />
						{isUser && (
							<Fragment>
								<div className="row">
									<div className="col s12 m12 l6">
										<h5 style={{ display: "inline", marginRight: 2 + "rem" }}>RUT </h5>
										<input
											type="text"
											onClick={() => copyToClipboard("rut")}
											id="user_rut"
											value={user.run + "-" + user.dv}
											style={styleInput}
											readOnly
											className="browser-default"
										/>
									</div>
									<div className="col s12 m12 l6">
										<h5 style={{ display: "inline", marginRight: 2 + "rem" }}>DOCUMENTO </h5>
										<input
											type="text"
											onClick={() => copyToClipboard("nro")}
											id="user_nro"
											value={user.numero_serie}
											style={styleInput}
											readOnly
											className="browser-default"
										/>
									</div>
								</div>
							</Fragment>
						)}
					</div>
					<div className="row mt-5">
						<div className="form-title">
							Según el resultado del Registro Civil seleccione la opción válida:
						</div>
						<div className="col s12 m12 l12">
							<h6 style={{ display: "inline", marginRight: 2 + "rem" }}>
								Actualmente {user?.serie_confirmada ? "" : "NO"} se encuentra VERIFICADO
							</h6>
						</div>
						<div className="input-field col s12">
							<form onSubmit={handleSubmit()}>
								<p>
									<label htmlFor="checkConfirma">
										<input
											id="checkConfirma"
											name="group1"
											type="radio"
											value={true}
											checked={nroIsConfirm}
											onChange={handleChangeCheck()}
										/>
										<span>VERIFICADA</span>
									</label>
								</p>
								<p>
									<label htmlFor="checkNoConfirma">
										<input
											id="checkNoConfirma"
											name="group1"
											type="radio"
											value={false}
											checked={!nroIsConfirm}
											onChange={handleChangeCheck()}
										/>
										<span>NO VERIFICADA</span>
									</label>
								</p>
								{loader ? (
									<Loader loader={loader} />
								) : (
									<button type="submit">Cambiar confirmación</button>
								)}
							</form>
						</div>
					</div>
				</div>
				<div className="modal-footer">
					<a id="modal-confirm-close" className="modal-close waves-effect waves-green btn-flat">
						Cerrar
					</a>
				</div>
			</div>
		</Fragment>
	)
}

export default ModalConfirmaSerie
