import React from "react"

const HomeDescription = () => {
	return (
		<div className="row">
			<div className="col s12 m7 l7 right">
				<div className="py-2 px-4">
					<p>
						Estimado Postulante,
						Para ingresar a esta plataforma y postular a las Carreras de la Universidad de Los Lagos,
						debes contar con tu Clave Única del Registro Civil o bien, registrarte con tu Rut y tu Cédula de identidad.
						Si no la tienes, ingresa al siguiente link para mayor información https://claveunica.gob.cl/
					</p>
					<p>
						Para dudas del proceso de Admisión y Matrícula contáctenos en nuestro call center: 64 2 333000 en horario de 9:00 a 18:00h de lunes a viernes
					</p>
				</div>
			</div>
		</div>
	)
}

export default HomeDescription
