import React, { useEffect, useState } from "react"
import {withRouter} from "react-router-dom"
import Header from './../../components/Header/Header'
import {getCarrerasLESede, getPostulacionesLEPorCarrera } from "../../services/admin.service";
import Loader from "../../components/Loader/Loader";
import M from "materialize-css";

const ListaEspera = () => {
    const [carreras, setCarreras] = useState([])
    const [loaderCar, setLoaderCar] = useState(false)
    const [loaderPost, setLoaderPost] = useState(false)
    const [postulaciones, setPostulaciones] = useState([])

    useEffect(() => {

        const fetchData = async () => {
            setLoaderCar(true)
            const result = await getCarrerasLESede()
            if(result.status === 200){
                console.log(result.data)
                setCarreras(result.data)
                await M.AutoInit()
                M.FormSelect.init(document.querySelectorAll('select'));
            }
            else
                console.log(result)
            setLoaderCar(false)
        };

        fetchData()
    }, [])

    const syncPostulaciones = async (id) => {
        setLoaderPost(true)
        const result = await getPostulacionesLEPorCarrera(id)
        if(result.status === 200){
            console.log(result.data)
            setPostulaciones(result.data)
        }
        else
            console.log(result)
        setLoaderPost(false)
    }

    const loadInfoCarrera = () => e => {
        console.log(e.target.value)
        syncPostulaciones(e.target.value)
    }

    const tablaPostulaciones = () => {
        if(postulaciones.length===0)
            return (<br />)
        return (
            <table>
                <tbody>
                { postulaciones.map((user) => {
                    return (<tr key={user.datos_personales.run} id={user.datos_personales.run}>
                        <td></td>
                        <td>
                            <div className="row" style={{marginBottom:0}}>
                                <div className="col s2 m1 l1">
                                    <a href={"/admin/postulaciones/p/"+ user.postulacion.id}>
                                        <b>{ user.postulacion.id }</b>
                                    </a>
                                </div>
                                <div className="col s10 m11 l11">
                                    <a href={"/admin/postulaciones/p/"+ user.postulacion.id}
                                       className="black-text">
                                        <b>{ user.datos_personales.nombres + " " + user.datos_personales.paterno + " " + user.datos_personales.materno }</b>
                                    </a>
                                </div>
                            </div>
                            <div className="row" style={{marginBottom:0}}>
                                <div className="col s8 m7 offset-m1 l7 offset-l1">
                                    <div
                                        style={{fontSize:.825 + "rem", color:"#777", fontStyle:"italic", fontWeight:"100"}}>
                                        <b>Fecha Postulación</b> {user.postulacion.fecha_validacion} -- <b>Rut</b>: {user.datos_personales.run}-{user.datos_personales.dv}
                                        <br />
                                        <b>Sede</b>: {user.postulacion.sede}
                                        -- <b>Período</b>: {user.postulacion.periodo}
                                    </div>
                                </div>
                                <div className="col s4 m4 l4">
                                    <span className="new badge theme lighten-0 right" data-badge-caption=""><b>{ user.estado.nombre }</b></span>
                                </div>
                            </div>
                        </td>
                    </tr>)
                })
                }
                </tbody>
            </table>
        )
    }

    return (
        <div>
            <Header />
            <div className="row container title-scope">
                <div className="title col s12 l12 left"><h6>Lista de</h6><h2>Espera</h2></div>
            </div>
            <div className="row container title-scope margin-bottom-24px">
                <div className="col s12 m12 l8 note text-align-justify">A continuación se muestra la lista de espera de la Carrera
                </div>
            </div>
            <div className="container">
                <div className="card">
                    <div className="card-content">
                        <div className="row">
                            <div className="col s6">
                                <div className="input-field">
                                    <select defaultValue={"-select-option-"} onChange={loadInfoCarrera()}>
                                        <option value="-select-option-" disabled>Seleccione una Carrera</option>
                                        {
                                            carreras.map((data)=>{
                                                return (
                                                    <option key={data.carrera.id} value={data.carrera.id}>{ "[" + data.carrera.codigo_carrera + "] " + data.carrera.nombre + " - " + data.carrera.sede + " (" + data.numero + " en L.E.)" }</option>
                                                )
                                            })
                                        }
                                    </select>
                                    <label>Carreras</label>
                                    <Loader loader={loaderCar} />
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col s12">
                                { tablaPostulaciones() }
                                { <Loader loader={loaderPost} /> }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )

}

export default withRouter(ListaEspera)