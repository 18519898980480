import React, { Fragment } from "react"
import { useUser } from "./../../context/UserProvider"
import { useDataForm } from "./../../context/FormProvider"
import AntecedentesPersonales from "./AntecedentesPersonales"
import Carreras from "./Carreras"
import Archivos from "./Archivos"

const FichaPostulacion = ({showTitle = true}) => {
	const { user, optionsSelect } = useUser()
	const {
		dataPostulante,
		dataAcademica,
		dataExtranjera,
		dataCarreras,
		dataArchivos
	} = useDataForm()

	if(Object.keys(dataPostulante)?.length>0){
		return (
			<Fragment>
			
			{showTitle &&
				<div className="row">
					<div className="title col s12 l12 center purple-text text-lighten-5">
						<h6>Ficha de</h6>
						<h2>Postulación</h2>
					</div>
				</div>
			}
	
				<AntecedentesPersonales
					user={user}
					dataPostulante={dataPostulante}
					dataAcademica={dataAcademica}
					dataExtranjera={dataExtranjera}
					optionsSelect={optionsSelect}
				/>
	
				 <Carreras dataCarreras={dataCarreras} /> 
	
				<Archivos dataArchivos={dataArchivos} />
			</Fragment>
		)
	}else{
		return <h2>Cargando postulación...</h2>
	}
}

export default FichaPostulacion
