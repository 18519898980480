import React, { Fragment, useState } from "react"
import {getRoles, setRolUsuario} from "../../../services/admin.service"

const ModalRoles = props => {
    const { id, rol, usuario, refresh } = props
    const [ roles , setRoles] = useState(getRoles())

    const [nRol, setRol] = useState("")
    const [desde, setDesde] = useState()
    const [hasta, setHasta] = useState()


    const viewSelectRol = () => {
        const options = roles.map(function(r){
            return <option value={ r.id } key={r.id}>{ r.rol }</option>
        })

        return (
            <div className="input-field">
                <select defaultValue={ rol.rol } onChange={e => setRol(e.target.value)}>
                    <option value="0" disabled>Seleccione un Rol</option>
                    { options }
                </select>
                <label>Roles Disponibles</label>
            </div>
        )
    }

    const cambiarRol = async () => {
        if(nRol===""){
            refresh()
            return
        }
        const params = {
            rol: nRol,
            usuario: usuario.id,
            desde: desde,
            hasta: hasta
        }
        const result = await setRolUsuario("",params)
        if(result.status === 200){
            //setLoaded(true)
            console.log(result)
        }
        else{
            //setLoaded(false)
            console.log(result)
        }
        refresh()
    }

    return (
        <Fragment>
        <div className="modal" id={"modal-roles-"+ id}>
            <div className="modal-content">
                <h4>Cambiar Rol de { usuario.nombres }</h4>
                <span>Rol actual: { rol.rol }</span><br />
                <span>Desde: { rol.fecha_inicio } - Hasta: { rol.fecha_termino }</span><br /><br />
                <form id={"form-roles-" + id}>
                    <div className="row">
                        <div className="col s12 m12 l12">
                            { viewSelectRol() }
                        </div>
                    </div>
                    <div className="row">
                        <div className="col s12 m6 l6">
                            <div className="input-field">
                                <input id="desde" type="text" className="datepicker validate" onChange={ e => setDesde(e.target.value) } />
                                <label htmlFor="desde">Desde (Opcional)</label>
                            </div>
                        </div>
                        <div className="col s12 m6 l6">
                            <div className="input-field">
                                <input id="hasta" type="text" className="datepicker validate" onChange={ e => setHasta(e.target.value) } />
                                <label htmlFor="hasta">Hasta (Opcional)</label>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
            <div className="modal-footer">
                <a href="#!" className="modal-close waves-effect waves-green btn-flat">Cerrar</a>
                <a href="#!" className="waves-effect waves-green btn" onClick={()=>cambiarRol()}>Cambiar</a>
            </div>
        </div>
        </Fragment>
    )

}

export default ModalRoles