import React from "react"
import SectionResidencia from "./SectionResidencia"
import SectionTelefonia from "./SectionTelefonia"
import { useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import schema from "./schema"
import { sendDataStep3 } from "./../../../../services/stepForm.service"
import { useUser } from "../../../../context/UserProvider"
import { useDataForm } from "../../../../context/FormProvider"
import showToast from "../../../Toast/Toast"
import { useState } from "react"

const Step3 = props => {
	const { setLoader } = props
	const [disableBtn, setDisableBtn] = useState(false)
	const { register, handleSubmit, errors } = useForm({
		resolver: yupResolver(schema())
	})

	const { user, updateUserWithStep, prevStep,optionsSelect, nextStep } = useUser()
	const { dataPostulante, updatePostulante, formHasChanges } = useDataForm()

	/* Recibe los datos del form para enviarlos a API */
	const onSubmitStep3 = async data => {
		setLoader(true)
		setDisableBtn(true)
		const noChanges = await !formHasChanges(data, 3)

		if (noChanges) {
			nextStep()
			setLoader(false)
			setDisableBtn(false)
			return
		}

		const _data = { user: user, data: data, etapa: 3 }
		const responseFromApi = await sendDataStep3(_data)

		if (responseFromApi?.response === "success") {
			updatePostulante(responseFromApi.data.postulante)
			updateUserWithStep(responseFromApi.user)
		} else {
			showToast("Se ha producido un problema al enviar formulario")
		}
		setDisableBtn(false)
		setLoader(false)
	}

	return (
		<form onSubmit={handleSubmit(onSubmitStep3)} className="custom-form">
			<SectionResidencia
				title="Residencia Grupo Familiar"
				dataPostulante={dataPostulante}
				register={register}
				errors={errors}
				optionsSelect={optionsSelect}
			/>

			<SectionTelefonia
				title="Telefonía"
				dataPostulante={dataPostulante}
				register={register}
				errors={errors}
				optionsSelect={optionsSelect}
			/>

			<div className="step-buttons">
				<button
					type="button"
					disabled={disableBtn}
					className={"waves-effect waves-light purple darken-1 btn-small mx-2"}
					onClick={() => prevStep()}
				>
					<i className="large material-icons left">navigate_before</i>
					Anterior
				</button>

				<button
					type="submit"
					disabled={disableBtn}
					className="waves-effect waves-light purple darken-1 btn-small right"
				>
					Siguiente
					<i className="large material-icons right">navigate_next</i>
				</button>
			</div>
		</form>
	)
}

export default Step3
