import React, { useEffect } from "react"
// import M from "materialize-css/dist/js/materialize"
import {withRouter} from "react-router-dom"
import Header from './../../components/Header/Header'

const Configuracion = () => {

/*     const openModal = (id) => {
        console.log(id)
        const modal = document.getElementById(id)
        const instance = M.Modal.init(modal)
        instance.open()
    } */

/*     const initAndOpenModal = async (id) => {
        await M.AutoInit()
        await openModal(id)
    } */

    useEffect( () => {
       /* const fetchData = async () => {
            const result = await getInfoUsuarios()
            if(result.status === 200){
                setData(result.data)
            }
            else
                console.log(result)
        };

        fetchData();*/

    }, []);


    return (
        <div>
            <Header />
            <div className="row container title-scope">
                <div className="title col s12 l12 left"><h6>Menú de</h6><h2>Configuración</h2></div>
            </div>
            <div className="row container title-scope margin-bottom-24px">
                <div className="col s12 m12 l8 note text-align-justify">A continuación se muestra el menú de Configuraciones disponible
                </div>
            </div>
            <div className="container">
                <div className="row" style={{marginTop:64 + "px"}}>
                    <div className="col s12 m4 l3">
                        <div className="card small">
                            <div className="card-content">
                                <p>Configuración</p>
                                <span className="card-title text-ellipsis theme-text">Usuarios</span>
                                <center><i className="large material-icons blue-text">people</i></center>
                                <span className="card-content-description">Visualize los Usuarios de sistema, asigne permisos y acceso a Sedes</span>
                            </div>
                            <div className="card-action">
                                <a href="/admin/configuracion/usuarios" className="btn progress-trigger theme-button-gradient-45deg">Ir</a>
                            </div>
                        </div>
                    </div>
                    <div className="col s12 m4 l3">
                        <div className="card small">
                            <div className="card-content">
                                <p>Configuración</p>
                                <span className="card-title text-ellipsis theme-text">Parámetros</span>
                                <center><i className="large material-icons red-text">build</i></center>
                                <span className="card-content-description">Visualize los parámetros de configuración del Sistema</span>
                            </div>
                            <div className="card-action">
                                <a href="/admin/configuracion/parametros" className="btn progress-trigger theme-button-gradient-45deg">Ir</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default withRouter(Configuracion)
