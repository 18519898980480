import React, {Fragment, useEffect, useState} from "react"
import showToast from "../../Toast/Toast";
import {migrarExcelEdelfos, getDetalleById} from "../../../services/admin.service";
import Loader from "../../Loader/Loader";
import M from "materialize-css";

const ModalSyncDelfos = props => {

    const { id_fila, carga, refresh } = props

    const [loader,  setLoader]  = useState(false)

    const [fila, setFila] = useState(null)

    const fetchData = async () => {
        if(id_fila!==null){
            setLoader(true)
            const data = {
                id: id_fila
            }
            const res = await getDetalleById(data)
            if (res.status === 200) {
                setFila(res.data)
            } else {
                showToast("No se cargó la información, intente de nuevo")
            }
            setLoader(false)
        }
    }


    useEffect(()=>{
        if(id_fila!==null){
            fetchData()
        }
    }, [id_fila])


    const sincronizar = async () => {
        setLoader(true)

        let params = {}
        let result
        params = {
            fila: fila
        }
        result = await migrarExcelEdelfos(params)
        if(result?.status === 200){
            showToast("Fila Sincronizada Exitosamente")
        }
        else{
            console.log(result)
            showToast( result?.message || "Se ha producido un error")
        }
        setLoader(false)
        refresh()
        const btn = document.getElementById('modal-m-close')
        btn.click()
        /*
        const modal = document.getElementById('modal-revisar')
        const instance = M.Modal.init(modal)
        instance.close()*/
    }


    return (
        <div className="modal" id="modal-reload">
            <div className="modal-content">
                <h4>Sincronización con eDelfos</h4>
                Confirme para realizar la sincronización con eDelfos
                <br/><br/>
                <Loader loader={loader} />
                <div className="row">
                    <div className="col s12 m12 l12">
                        <h2 className="subtitle"><span className="blue-grey-text">Sincronizando fila - { id_fila || "" }</span></h2>
                    </div>
                </div>
                <div className="row">
                    <div className="col s12 m12 l12">
                        <h2 className="subtitle"><span className="blue-grey-text">Resultado</span></h2>
                    </div>
                    <div className="input-field col s12" id="resultado">
                    </div>
                </div>
            </div>
            <div className="modal-footer">
                <a href="#" id="modal-m-close" className="modal-close waves-effect waves-green btn-flat">Cerrar</a>
                <a href="#" className="waves-effect waves-green btn green" onClick={() => sincronizar()}>Sincronizar</a>
            </div>
        </div>
    )

}

export default ModalSyncDelfos