import axios from "axios"
import { API_URL } from "../config"

const API = API_URL()
const token = localStorage.getItem("ssid")
const config = { headers: { Authorization: "Bearer " + token } }
const LOGOUT_CU = "https://accounts.claveunica.gob.cl/api/v1/accounts/app/logout?redirect="+encodeURIComponent(API)

/* LLamamos a api para redireccion a claveunica */
const redirectClaveUnica = () => {
	window.location.href = API + "claveunica/autenticar"
}

const logoutCU = () => {
	window.location.href = LOGOUT_CU
}

const logoutUser = async () => {
	const URL = API + "api/logout"
	await axios
		.get(URL, config)
		.then(res => res)
		.catch(err => console.log(err))
}

const getCurrentUser = () => {
	// return JSON.parse(localStorage.getItem("ssid"))
	if (token && token !== "undefined") {
		const URL = API + "api/get_user"
		return axios
			.get(URL, config)
			.then(({ data }) => data)
			.catch(err => console.log(err))
	} else {
		console.log("EN get current user error sin token")
	}
}

const redirectResponseClaveUnicaToApi = ({ code, state }) => {
	//TODO: responder a clave unica
	const URL = API + `api/claveunica/validar?code=${code}&state=${state}`
	return axios
		.get(URL)
		.then(response => response)
		.catch(error => console.log(error))
}

const saveUserLocalStorage = async data => {
	// const userLocal = (await JSON.parse(localStorage.getItem("ssid"))) || {}
	await localStorage.setItem("ssid", JSON.stringify(data.access_token))
	return localStorage.getItem("ssid") !== null
}

const loginRut = ({ rut, pass }) => {
	const URL = API + "api/login_rut"
	var par = {
		username: rut,
		password: pass
	}
	return axios
		.post(URL, par)
		.then(function (response) {
			return response
		})
		.catch(function (error) {
			// handle error
			//console.log("ERROR: " + error.response.data.error)
			return error
		})
}

const registerUser = ({data}) => {
	const URL = API + "api/register_user"
	return axios
		.post(URL, data)
		.then(function (response) {
			return response.data
		})
		.catch(function (error) {
			// handle error
			console.log("ERROR: " + error.response.data.error)
			return error
		})
}

/*

	LOGIN ADMIN AREA

 */
const loginLdap = ({ mail, pass }) => {
	const URL = API + "api/login_admin"
	var par = {
		mail: mail,
		pass: pass
	}
	return axios
		.post(URL, par)
		.then(function (response) {
			return response
		})
		.catch(function (error) {
			// handle error
			console.log("ERROR: " +error)
			return error
		})
}

const getCurrentAdminUser = () => {
	//console.log(localStorage.getItem("admin"))
	return JSON.parse(localStorage.getItem("admin"))
}

const saveAdminUserLocalStorage = async data => {
	const adminLocal = (await JSON.parse(localStorage.getItem("admin"))) || {}
	await localStorage.setItem("admin", JSON.stringify({ ...adminLocal, ...data }))
	return localStorage.getItem("admin") !== null
}

const logoutAdminUser = () => {
	if (localStorage.getItem("admin") === null) {
		return false
	} else {
		localStorage.removeItem("admin")
		return true
	}

	//TODO: enviar la peticion a la api para que cierre session
}

export {
	saveUserLocalStorage,
	logoutUser,
	logoutAdminUser,
	getCurrentUser,
	redirectResponseClaveUnicaToApi,
	redirectClaveUnica,
	loginLdap,
	getCurrentAdminUser,
	saveAdminUserLocalStorage,
	logoutCU,
	loginRut,
	registerUser
}
