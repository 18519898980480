import React, { Fragment, useState } from "react"
import { getSedes, setSedeUsuario } from "../../../services/admin.service"

const ModalSedes = props => {
    const { id, sedes, usuario, refresh } = props

    const [ modalSedes, setModalSedes ] = useState(sedes)
    const [ arraySedes, setArraySedes ] = useState(sedes.split(","))

    const setVistaSedes = () => {
        //setArraySedes(sedes.split(","))
        let local_sedes =  getSedes()
        return local_sedes.map(function(sede){
            return (
                <tr key={"sede-" + sede.id}>
                    <td>{ sede.id }</td>
                    <td>{ sede.nombre }</td>
                    <td>
                        <label>
                            <input type="checkbox" className="filled-in" value={sede.id} id={"check-rol-" + usuario.id + "-" + sede.id} defaultChecked={(arraySedes.includes(sede.id))} onChange={()=>actualizaSede(sede.id)}/>
                            <span></span>
                        </label>
                    </td>
                </tr>
            )
        })
    }

    const refreshVista = () => {
        let local_sedes =  getSedes()
        setArraySedes(sedes.split(","))
        for (let i=0; i < local_sedes.length; i+=1){
            //console.log("check-rol-" + usuario.id + "-" + local_sedes[i])
            const input = document.getElementById("check-rol-" + usuario.id + "-" + local_sedes[i].id)
            if(input !== null){
                input.checked = false;
            }
        }
        for (let i=0; i < arraySedes.length; i+=1){
            //console.log("check-rol-" + usuario.id + "-" + arraySedes[i])
            const input = document.getElementById("check-rol-" + usuario.id + "-" + arraySedes[i])
            if(input !== null){
                input.checked = true;
            }
        }
    }

    const actualizaSede = async (id) => {
        for (let i=0; i < arraySedes.length; i+=1){
            if(arraySedes[i].id===id){
                arraySedes.remove(i)
                break
            }
        }
        /*const params = {
            sede: id,
            usuario: usuario.id
        }*/
        //await setSedeUsuario("",params)
        //console.log(id)
    }

    const actualizarSedes = async () => {
        const inputs = document.getElementById( "form-sedes-"+ usuario.id).getElementsByTagName( 'input' );
        let asedes = ""
        let i = 0
        for (let inp of inputs) {
            if(inp.checked){
                if(i===0)
                    asedes += inp.value
                else
                    asedes += "," + inp.value
                i+=1
            }
        }
        const params = {
            sedes: asedes,
            usuario: usuario.id
        }
        const result = await setSedeUsuario(params)
        if(result.status === 200){
            //setLoaded(true)
            console.log(result)
        }
        else{
            //setLoaded(false)
            console.log(result)
        }
        refresh()
    }

    return (
        <Fragment>
            <div className="modal" id={"modal-sedes-"+ id}>
                <div className="modal-content">
                    <h4>Sedes de { usuario.nombres }</h4>
                    <form id={"form-sedes-"+ usuario.id}>
                    <table>
                        <thead>
                            <tr>
                                <th>Código</th>
                                <th>Nombre</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            { setVistaSedes() }
                        </tbody>
                    </table>
                    </form>
                </div>
                <div className="modal-footer">
                    <a href="#!" className="modal-close waves-effect waves-green btn-flat" onClick={()=>refreshVista()}>Cerrar</a>
                    <a href="#!" className="waves-effect waves-green btn" onClick={()=>actualizarSedes()}>Actualizar</a>
                </div>
            </div>
        </Fragment>
    )

}

export default ModalSedes