import * as yup from "yup"

/* Esquema de formulario en etapa3. tipos de datos, si son requeridos y validaciones custom */
const schema = () => {
	return yup.object().shape({
		postulante: yup.object().shape({
			calle: yup.string().required(),
			numero: yup.string(),
			villa: yup.string(),
			region: yup.string().required(),
			depto: yup.string().max(10, "Excede el máximo de caracteres"),
			provincia: yup.string().required(),
			ciudad: yup.string().required(),
			comuna: yup.string().required(),
			tipo_telefono: yup.string().required(),
			telefono: yup.number().required(),
			tipo_telefono_trabajo: yup.string().required(),
			telefono_trabajo: yup.number().required() 
		})
	})
}

export default schema
