import React, { Fragment, useEffect, useState } from "react"
import M from "materialize-css/dist/js/materialize"
import {withRouter} from "react-router-dom"
import Header from './../../components/Header/Header'
import { getCurrentAdminUser } from '../../services/auth.service'
import { getInfoUsuarios } from "../../services/admin.service";
import {useAdmin} from "../../context/AdminProvider";

const Reportes = props => {

    const {history} = props
    const { admin, updateAdmin } = useAdmin()
    const [data, setData] = useState([]);

    const openModal = (id) => {
        console.log(id)
        const modal = document.getElementById(id)
        const instance = M.Modal.init(modal)
        instance.open()
    }

    const initAndOpenModal = async (id) => {
        await M.AutoInit()
        await openModal(id)
    }

    useEffect( () => {

    }, []);


    return (
        <div>
            <Header />
            <div className="row container title-scope">
                <div className="title col s12 l12 left"><h6>Menú de</h6><h2>Reportes</h2></div>
            </div>
            <div className="row container title-scope margin-bottom-24px">
                <div className="col s12 m12 l8 note text-align-justify">A continuación se muestra el menú con los reportes disponibles
                </div>
            </div>
            <div className="container content margin-top-48px">
                <div className="row" style={{ marginTop: 64 + "px" }}>
                    <div className="col s12 m4 l4">
                        <div className="card">
                            <div className="card-content">
                                <p>Proceso</p>
                                <span className="card-title text-ellipsis theme-text">Admisión En-Línea</span>
                                <center>
                                    <i className="large material-icons blue-text">insert_drive_file</i>
                                </center>
                                <span className="card-content-description">
									Reportes de Admisión En-Línea.
								</span>
                            </div>
                            <div className="card-action">
                                <a
                                    href="/admin/reportes/admision"
                                    className="btn progress-trigger theme-button-gradient-45deg"
                                >
                                    Ir
                                </a>
                            </div>
                        </div>
                    </div>

                    <div className="col s12 m4 l4">
                        <div className="card">
                            <div className="card-content">
                                <p>Proceso</p>
                                <span className="card-title text-ellipsis theme-text">Ingresos Especiales</span>
                                <center>
                                    <i className="large material-icons blue-text">insert_drive_file</i>
                                </center>
                                <span className="card-content-description">
									Reporte de Ingresos Especiales.
								</span>
                            </div>
                            <div className="card-action">
                                <a
                                    href="/admin/reportes/cargas"
                                    className="btn progress-trigger theme-button-gradient-45deg"
                                >
                                    Ir
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default withRouter(Reportes)
