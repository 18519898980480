/* Para formatear la fecha que llega desde la api en un formato distinto al del form */
const formatDate = date => {
	if (!date) return null
	if (date.split("-").length === 1) return date

	let day = date.split("-")[2].split("T")[0]
	let month = date.split("-")[1]
	let year = date.split("-")[0]

	return `${day}/${month}/${year}`
}

/* Valida que el valor ingresado a un input sea numerico y longitud maxima "max" */
const valideNumberInput = max => e => {
	const regex = /^[0-9\b]+$/
	const re = new RegExp(regex)
	if (e.target.value.match(re) && e.target.value.length <= max) {
		return (e.target.value)
	} else {
		return (e.target.value = e.target.value.slice(0, -1))
	}
}

/* retorna true o false dependiendo si el input se encuentra con la extension dada */ 
const validExtensionInputFile = (inputValue, extensionArray = ["pdf", "jpeg", "jpg", "png"]) => {
	let extension = inputValue.split(".").pop().toLowerCase()
	return extensionArray.includes(extension)
}


const isValidRut = (rut) => {
	if (!rut || rut.trim().length < 3) return false
	const rutLimpio = rut.replace(/[^0-9kK-]/g, "")

	if (rutLimpio.length < 3) return false

	const num = rutLimpio.slice(0, rut.length-1)
	const dgv = rutLimpio.split("").pop()

	const dvCalc = calculateDV(num)

	return dvCalc.toUpperCase() === dgv.toUpperCase()
}

const calculateDV = (rut) => {
	const cuerpo = `${rut}`
	// Calcular Dígito Verificador
	let suma = 0
	let multiplo = 2

	// Para cada dígito del Cuerpo
	for (let i = 1; i <= cuerpo.length; i++) {
		// Obtener su Producto con el Múltiplo Correspondiente
		const index = multiplo * cuerpo.charAt(cuerpo.length - i)

		// Sumar al Contador General
		suma += index

		// Consolidar Múltiplo dentro del rango [2,7]
		if (multiplo < 7) {
			multiplo += 1
		} else {
			multiplo = 2
		}
	}

	// Calcular Dígito Verificador en base al Módulo 11
	const dvEsperado = 11 - (suma % 11)
	if (dvEsperado === 10) return "k"
	if (dvEsperado === 11) return "0"
	return `${dvEsperado}`
}


export { formatDate, valideNumberInput, validExtensionInputFile, isValidRut }
