import React, { createContext, useState, useMemo } from "react"
import { useContext } from "react"
import { getCurrentAdminUser, logoutAdminUser, saveAdminUserLocalStorage } from "../services/auth.service"

const AdminContext = createContext()

const AdminProvider = ({ children }) => {
    const [admin, setAdmin] = useState(getCurrentAdminUser())

    const updateAdmin = async (updatedAdmin) => {
        if (updatedAdmin) {
            setAdmin({ ...admin, ...updatedAdmin })
            await saveAdminUserLocalStorage(updatedAdmin)
        } else {
            setAdmin(null)
            logoutAdminUser()
            window.location = "/"
        }
    }

    const value = useMemo(() => {
        return {
            admin,
            updateAdmin,
        }
    })


    return <AdminContext.Provider value={value}>{children}</AdminContext.Provider>
}

const useAdmin = () => {
    const context = useContext(AdminContext)
    if (!context) {
        throw new Error("UseAdmin debe estar dentro del proveedor AdminProvider")
    }
    return context
}

export { useAdmin, AdminProvider }
