import React, { Fragment, useState } from 'react'
import InputField from "../../../InputField/InputField"
import Select from "../../../Select/Select"
import TitleForm from "../../../TitleForm/TitleForm"
import {valideNumberInput} from "./../../../../utils"

const PREFIX_CEL = '+569'
const PREFIX_TEL = '+56'

const SectionTelefonia = (props) => {
	const { dataPostulante, register, errors, title, optionsSelect } = props
	const [prefix1, setPrefix1] = useState("")
	const [prefix2, setPrefix2] = useState("")

	const changeTipoTelefono = (tipo) => (e)=> {
		const {value} = e.target
		switch (tipo) {
			case 1:
				setPrefix1(value === "C" ? PREFIX_CEL : PREFIX_TEL)
				break
			case 2:
				setPrefix2(value === "C" ? PREFIX_CEL : PREFIX_TEL)
				break
			default:
				break
		}
	}
	
	
  return (
		<Fragment>
			<TitleForm>{title}</TitleForm>
			<div className="row">
				<div className="col s2 input-field">
					<Select
						name="postulante.tipo_telefono"
						label="Teléfono Personal"
						defaultValue={dataPostulante?.tipo_telefono || ""}
						options={optionsSelect?.tipo_telefono}
						onChange={changeTipoTelefono(1)}
						register={register}
						errors={errors?.postulante?.tipo_telefono}
					/>
				</div>

				<div className="col s1 input-field">
					<InputField
						id="inputPrefijo1"
						name="prefijo1"
						noValidate
						label=""
						readOnly
						defaultValue={prefix1}
					/>
				</div>

				<div className="col s2 input-field">
					<InputField
						id="inputTelefono"
						name="postulante.telefono"
						label="Número"
						onChange={valideNumberInput(9)}
						defaultValue={dataPostulante?.telefono || ""}
						register={register}
						errors={errors?.postulante?.telefono}
					/>
				</div>

				<div className="col s2 offset-s1 input-field">
					<Select
						name="postulante.tipo_telefono_trabajo"
						label="Teléfono Adicional"
						defaultValue={dataPostulante?.tipo_telefono_trabajo || ""}
						options={optionsSelect?.tipo_telefono}
						register={register}
						onChange={changeTipoTelefono(2)}
						errors={errors?.postulante?.tipo_telefono_trabajo}
					/>
				</div>

				<div className="col s1 input-field">
					<InputField
						id="inputPrefijo2"
						name="prefijo2"
						label=""
						noValidate
						readOnly
						defaultValue={prefix2}
					/>
				</div>

				<div className="col s2 input-field">
					<InputField
						id="inputTelefonoTrabajo"
						name="postulante.telefono_trabajo"
						label="Número"
						onChange={valideNumberInput(9)}
						defaultValue={dataPostulante?.telefono_trabajo || ""}
						register={register}
						errors={errors?.postulante?.telefono_trabajo}
					/>
				</div>
			</div>
			<div className="row">
				<div className="col s7 offset-s5" style={{fontSize: 85+"%", marginTop: -1.25+"rem"}}>
					Debes ingresar dos números telefónicos. Número Personal y uno adicional de contacto.
				</div>
			</div>
		</Fragment>
	)
}

export default SectionTelefonia
