import React, { useEffect, useState } from "react"
import { withRouter } from "react-router-dom"
import Header from "./../../components/Header/Header"
import { getPostulacionesPorSede } from "../../services/admin.service"
import Loader from "../../components/Loader/Loader"
import { useAdmin } from "../../context/AdminProvider"
import { formatDate } from "./../../utils"

const Bandeja = props => {
	const { admin } = useAdmin()
	const [postulaciones, setPostulaciones] = useState([])

	const [loader, setLoader] = useState(true)

	useEffect(() => {
		const fetchData = async () => {
			if (admin !== null) {
				setLoader(true)
				const result = await getPostulacionesPorSede()
				if (result?.status === 200) {
					setPostulaciones(result.data)
				} else console.log(result)
				setLoader(false)
			}
		}

		//setData(dataPostulaciones)

		fetchData()
	}, [admin])

	const rowPostulaciones = () => {
		return postulaciones.map(data => {
			return (
				<tr
					key={data.postulacion.id}
					className={data.estado.nombre === "INTERESA" ? "blink" : null}
				>
					<td></td>
					<td>
						<div className="row" style={{ marginBottom: 0 }}>
							<div className="col s2 m1 l1">
								<a href={"/admin/postulaciones/p/" + data.postulacion.id}>
									<b>{data.postulacion.id}</b>
								</a>
							</div>
							<div className="col s10 m11 l11">
								<a
									href={"/admin/postulaciones/p/" + data.postulacion.id}
									className="black-text"
								>
									<b>
										{data.datos_personales.nombres +
											" " +
											data.datos_personales.paterno +
											" " +
											data.datos_personales.materno}
									</b>
								</a>
							</div>
						</div>
						<div className="row" style={{ marginBottom: 0 }}>
							<div className="col s8 m7 offset-m1 l7 offset-l1">
								<div
									style={{
										fontSize: 0.825 + "rem",
										color: "#777",
										fontStyle: "italic",
										fontWeight: "100"
									}}
								>
									<b>Fecha Postulación</b> {formatDate(data.postulacion.fecha_validacion)} --{" "}
									<b>Rut</b>: {data.datos_personales.run}-{data.datos_personales.dv}
									<br />
									<b>Sede</b>: {data.postulacion.sede}
									-- <b>Período</b>: {data.postulacion.periodo}
								</div>
							</div>
							<div className="col s4 m4 l4">
								<span className="new badge theme lighten-0 right" data-badge-caption="">
									<b>{data.estado.nombre}</b>
								</span>
							</div>
						</div>
					</td>
				</tr>
			)
		})
	}

	return (
		<div>
			<Header />
			<div className="row container title-scope">
				<div className="title col s12 l12 left">
					<h6>Bandeja de</h6>
					<h2>Entrada</h2>
				</div>
			</div>
			<div className="row container title-scope margin-bottom-24px">
				<div className="col s12 m12 l8 note text-align-justify">
					A continuación se muestra la bandeja de entrada de Postulaciones
				</div>
			</div>
			<div className="container">
				<div className="card">
					<div className="card-content">
						<table>
							<tbody>{rowPostulaciones()}</tbody>
						</table>
						{<Loader loader={loader} />}
					</div>
				</div>
			</div>
		</div>
	)
}

export default withRouter(Bandeja)
