import M from "materialize-css/dist/js/materialize"
import "./Toast.scss"

    const showToast = (msg) => {
        M.toast({
            html: msg,
            classes:"toast-custom bg-ula-blue"
        })
  }

export default showToast