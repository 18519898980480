import React, { Fragment, useEffect } from "react"
import {withRouter} from "react-router-dom"
import SidenavAdmin from "../SidenavAdmin/SidenavAdmin"
import {useAdmin} from "../../context/AdminProvider";
import M from "materialize-css";

const Header = () => {
	const { updateAdmin } = useAdmin()
	useEffect(()=>{
	 M.Sidenav.init( document.getElementById('slide-out'));
	}, [])

	const logout = () => {
		updateAdmin(null)
	}

	return (
		<Fragment>
			<header>
					{(true)?
						<nav className="purple" style={{marginBottom: 40 + "px"}}>
							<div className="nav-wrapper">
								<span data-target="slide-out" className="brand-logo sidenav-trigger show-on-medium-and-up pointer"><i className="material-icons">menu</i> Admisión En Línea</span>
								<ul className="right hide-on-med-and-down">
									{/* <li><a href="#"></a></li> */}
									<li><span onClick={logout} className="pointer"><i className="material-icons mx-4">directions_walk</i></span></li>
								</ul>
							</div>
							<SidenavAdmin />
						</nav>

						: null
					}
			</header>

		</Fragment>
	)
}

export default withRouter(Header)
