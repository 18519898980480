import React, { Fragment, useState } from "react"
import TitleForm from "../../TitleForm/TitleForm"
import M from "materialize-css"
import { useUser } from "../../../context/UserProvider"
import { sendDataStep6 } from "./../../../services/stepForm.service"
import ModalResumen from "../../ResumenAlumno/ModalResumen"
import showToast from "../../Toast/Toast"
import { useDataForm } from "../../../context/FormProvider"

const Step6 = () => {
	const [validaInfo, setValidaInfo] = useState(false)
	const { user, prevStep, updateUserWithStep } = useUser()
	const { updatePostulacion, dataPostulante } = useDataForm()
	const [loader, setLoader] = useState(false)

	const getModal = () => {
		const modal = document.getElementById("modal-enviar")
		return M.Modal.init(modal, { preventScrolling: false })
	}
	const openModal = () => {
		getModal().open()
	}

	const initAndOpenModal = async id => {
		await M.AutoInit()
		await openModal(id)
	}

	const handleChangeCheckbox = e => {
		setValidaInfo(e.target.checked)
	}

	const sendStep6 = async valida => {
		setLoader(true)
		const res = await sendDataStep6(valida)
		if (res?.response === "success") {
			updatePostulacion(res.data.postulacion)
			showToast("Postulación enviada correctamente")
			updateUserWithStep(res.user)
		} else {
			showToast("Ha ocurrido un problema al enviar")
		}
		setLoader(false)
	}

	return (
		<Fragment>
			<TitleForm>Finalización</TitleForm>
			<p>
				{dataPostulante.sexo === "M" ? "Estimado" : "Estimada"} {user.nombres}, te encuentras
				en la última etapa para envío de tu Postulación. Al enviar tu solicitud, declaras que la
				información ingresada es fidedigna, actualizada y correcta. En caso de dudas o
				consultas, puedes contactarte con el equipo de Admisión ULagos, mediante las siguientes
				vías, no olvides de indicar tu rut, carrera y detalle de tu duda o consulta. <br/><br />
				<b>Correo electrónico: admision@ulagos.cl</b> <br/> Números telefónicos: +56 9 53581342 - +56 64
				2333000
			</p>
			<div className="row p-4">
				<div className="col s12">
					<p>
						<label>
							<input type="checkbox" onChange={handleChangeCheckbox} />
							<span>Declaro haber completado el formulario con datos verídicos.</span>
						</label>
					</p>
				</div>
			</div>

			<div className="step-buttons">
				<button
					type="button"
					className={"waves-effect waves-light purple darken-1 btn-small mx-2"}
					onClick={() => prevStep()}
				>
					<i className="large material-icons left">navigate_before</i>
					Anterior
				</button>
				{validaInfo && (
					<button
						type="button"
						disabled={!validaInfo}
						className="waves-effect waves-light purple darken-4 btn-small right "
						onClick={() => initAndOpenModal("modal-enviar")}
					>
						Revisar y enviar
						<i className="large material-icons right">check</i>
					</button>
				)}
			</div>
			<ModalResumen sendStep6={sendStep6} loader={loader} />
		</Fragment>
	)
}

export default Step6
