import React, { createContext, useState } from "react"
import { useContext } from "react"
import showToast from "../components/Toast/Toast"
import { logoutUser, logoutCU } from "../services/auth.service"
import { getOptionsSelect } from "./../services/options.service"

const UserContext = createContext()

const UserProvider = ({ children }) => {
	const [user, setUser] = useState(null)
	const [currentStep, setCurrentStep] = useState(null)
	const [optionsSelect, setOptionsSelect] = useState({})

	const updateUserWithStep = (updatedUser, updateStep = true) => {
		if (updatedUser) {
			setUser({ ...user, ...updatedUser })
			if (updateStep) {
				setCurrentStep(updatedUser.etapa)
			}
		} else {
			logout()
		}
	}

	const nextStep = () => {
		setCurrentStep(currentStep + 1)
	}

	const prevStep = () => {
		setCurrentStep(currentStep - 1)
	}

	const setUserToken = token => {
		localStorage.setItem("ssid", token)
	}

	const logout = async () => {
		setUser(null)
		localStorage.removeItem("ssid")
		localStorage.removeItem("dataForm")
		const logApi = await logoutUser()
		if (logApi?.response === "success") {
			showToast("Sesión finalizada")
		}
		logoutCU()
		return true
	}

	/* Trae y guarda las opciones por defecto de delfos para los select del form */
	const callOptions = async () => {
		const optionsLocal = localStorage.getItem("options")

		if (!optionsLocal || optionsLocal === "undefined") {
			const options = await getOptionsSelect()
			setOptionsSelect(options)
			localStorage.setItem("options", JSON.stringify(options))
		} else {
			setOptionsSelect(JSON.parse(optionsLocal))
		}
	}

	const value =
		{
			user,
			updateUserWithStep,
			currentStep,
			setUser,
			setCurrentStep,
			nextStep,
			prevStep,
			setUserToken,
			callOptions,
			optionsSelect,
			setOptionsSelect,
			logout
		}
		
	return <UserContext.Provider value={value}>{children}</UserContext.Provider>
}

const useUser = () => {
	const context = useContext(UserContext)
	if (!context) {
		throw new Error("UseUser debe star dentro del proveedor UserProvider")
	}
	return context
}

export { useUser, UserProvider }
