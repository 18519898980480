import React, { Fragment, useEffect } from "react"
import M from "materialize-css"
import { withRouter } from "react-router-dom"
import { acceptTermsStep0 } from "./../../../services/stepForm.service"
import Loader from "../../Loader/Loader"
import showToast from "../../Toast/Toast"
import { useUser } from "./../../../context/UserProvider"
import { useState } from "react"

const openModal = () => {
	const modal = document.getElementById("modalStep0")
	const instance = M.Modal.getInstance(modal)
	instance.open()
}

const initAndOpenModal = async () => {
	await M.AutoInit()
	openModal()
}

const Step0 = ({history}) => {
	const [loader, setLoader] = useState(false)

	const { user, updateUserWithStep } = useUser()

	useEffect(() => {
		initAndOpenModal()
	}, [])

	/* envia al api la opcion que eligió el usuario, si no acepta cierra sesion
	 * si acepta y api retorna success se pasa a la siguiente etapa y guarda datos del usuario
	 */
	const handleButton = async accept => {
		if (!accept) {
			if(updateUserWithStep(null)){
				window.location=("/")
			}
			return
		}
		
		setLoader(true)
		
		const response = await acceptTermsStep0(accept)
		const responseOk = response?.response === "success"

		if (responseOk) {
			updateUserWithStep(response.user)
			history.push("/form")
		} else {
			showToast("Ha ocurrido un problema. Intenta nuevamente")
			initAndOpenModal()
		}

		setLoader(false)
	}

	return (
		<Fragment>
			<div className="container">
				<div id="modalStep0" className="modal modal-fixed-footer">
					<div className="modal-content">
						<h4>Admisión ULagos</h4>
						<p>
							<b>Estimada/o {user.nombres} {user.paterno} {user.materno}</b>
						</p>
						<p>
						 Has iniciado el proceso de postulación a las carreras de la Universidad de Los Lagos, te invitamos a completar cada una de las etapas.
						</p>
						<p>
							La Universidad de Los Lagos, velará por el cumplimiento a la normativa de la Ley N° 21.096, sobre protección de datos personales.
						</p>
					</div>
					<div className="modal-footer">
						<button
							className="modal-close waves-effect waves-purple bg-ula-blue btn-small mx-2"
							onClick={() => handleButton(true)}
						>
							Acepto
						</button>
						<button
							className="modal-close waves-effect waves-purple grey btn-small"
							onClick={() => handleButton(false)}
						>
							No acepto
						</button>
					</div>
				</div>

				<Loader loader={loader} />
			</div>
		</Fragment>
	)
}

export default withRouter(Step0)
