import { withRouter } from "react-router-dom"
import Loader from "../components/Loader/Loader"
import React, { useState, useEffect } from "react"
import { registerUser } from "../services/auth.service"
import showToast from "../components/Toast/Toast"
import Inputmask from "inputmask"
import { isValidRut } from "../utils"
import ContainerAuth from "../components/ContainerAuth/ContainerAuth"

const Register = props => {
	const [loading, setLoading] = useState(false)
	const { history } = props

	const [checkValid, setCheckValid] = useState(false)

	const [rut, setRut] = useState()
	const rutChange = () => e => {
		setRut(e.target.value)
	}
	const [serie, setSerie] = useState()
	const serieChange = () => e => {
		setSerie(e.target.value)
	}
	const [nombres, setNombres] = useState()
	const nombresChange = () => e => {
		setNombres(e.target.value)
	}
	const [paterno, setPaterno] = useState()
	const paternoChange = () => e => {
		setPaterno(e.target.value)
	}
	const [materno, setMaterno] = useState()
	const maternoChange = () => e => {
		setMaterno(e.target.value)
	}
	const [password, setPassword] = useState()
	const passwordChange = () => e => {
		setPassword(e.target.value)
	}
	const [rpassword, setRPassword] = useState()
	const rpasswordChange = () => e => {
		setRPassword(e.target.value)
	}

	const handleChangeCheckbox = e => {
		setCheckValid(e.target.checked)
	}

	const submitHandle = async e => {
		e.preventDefault()

		if (!isValidRut(rut)) {
			return showToast("El Rut ingresado no es válido")
		}

		if (password !== rpassword) {
			return showToast("Las Contraseñas no coinciden")
		}
		setLoading(true)
		const data = {
			rut: rut.slice(0, rut.length - 1),
			dv: rut.slice(-1),
			serie: serie,
			nombres: nombres,
			paterno: paterno,
			materno: materno,
			password: password
		}

		const response = await registerUser({ data })
		if (response.success) {
			showToast(response.message)
			history.push("/login")
		} else {
			showToast("Ha ocurrido un problema al registrarse. ", response.message)
		}
		setLoading(false)
	}

	const setInputMasks = () => {
		const maskConfigRut = {
			mask: "(9.999.999-K)|(99.999.999-K)",
			autoUnmask: true, //para que .val() devuelva sin mascara (sin puntos ni guion)
			keepStatic: true, //para que el formato de mascara mas corta se mantenga hasta que sea necesario el mas largo
			showMaskOnFocus: false, //oculta la mascara en focus
			showMaskOnHover: false, //oculta la mascara en hover
			definitions: {
				K: {
					validator: "[0-9|kK]",
					casing: "upper"
				}
			}
		}
		const maskConfigSerie = {
			mask: "(9{9})|(*{10})",
			autoUnmask: true,
			showMaskOnHover: false,
			showMaskOnFocus: false
		}
		const inputMaskRut = new Inputmask("inputrut", maskConfigRut)
		const inputMaskSerie = new Inputmask("inputserie", maskConfigSerie)
		inputMaskRut.mask(document.getElementById("rut"))
		inputMaskSerie.mask(document.getElementById("serie"))
	}

	
	useEffect(() => {
		setInputMasks()
	}, [])

	return (
		<ContainerAuth onSubmit={submitHandle} title="Formulario de Registro">
			<div className="form-group">
				<label className="text_label" htmlFor="rut">
					Rut
				</label>
				<input
					id="rut"
					className="browser-default "
					placeholder="11.111.111-1"
					required="required"
					autoComplete="off"
					type="text"
					onChange={rutChange()}
				/>
			</div>

			<div className="form-group">
				<label className="text_label" htmlFor="serie">
					Número Serie Cédula
				</label>
				<input
					id="serie"
					type="text"
					className="browser-default"
					required
					onChange={serieChange()}
				/>
			</div>

			<div className="form-group">
				<label className="text_label" htmlFor="nombres">
					Nombres
				</label>
				<input
					id="nombres"
					className="browser-default"
					type="text"
					required
					autoComplete="off"
					onChange={nombresChange()}
				/>
			</div>

			<div className="form-group">
				<label className="text_label" htmlFor="paterno">
					Apellido Paterno
				</label>
				<input
					id="paterno"
					className="browser-default"
					required
					autoComplete="off"
					onChange={paternoChange()}
					type="text"
				/>
			</div>

			<div className="form-group">
				<label className="text_label" htmlFor="materno">
					Apellido Materno
				</label>
				<input
					id="materno"
					className="browser-default"
					required
					autoComplete="off"
					type="text"
					onChange={maternoChange()}
				/>
			</div>

			<div className="form-group">
				<label className="text_label" htmlFor="password">
					Contraseña
				</label>
				<input
					id="password"
					type="password"
					className="browser-default"
					onChange={passwordChange()}
				/>
			</div>

			<div className="form-group">
				<label className="text_label" htmlFor="password">
					Repetir Contraseña
				</label>
				<input
					id="-rpassword"
					type="password"
					className="browser-default"
					onChange={rpasswordChange()}
				/>
			</div>

			<div className="form-group p-4">
				<label>
					<input type="checkbox" onChange={handleChangeCheckbox} />
					<span className="text-center">
						Acepto que los datos registrados son válidos y que de no ser así podría concluir en
						la cancelación de la postulación.
					</span>
				</label>
			</div>

			{checkValid && (
				<div className="form-group center-align mt-5">
					<button className="contact-btn" type="submit">
						REGISTRARSE
					</button>
				</div>
			)}
			<Loader loader={loading} />
		</ContainerAuth>
	)
}

export default withRouter(Register)
