import React, { Fragment } from "react"
import TitleForm from "../TitleForm/TitleForm"

const Carreras = ({ dataCarreras }) => {

	const rowsCarreras = () => {
		return (
			Object.values(dataCarreras).map((car, index) => (
				<tr key={index}>
					<td>Preferencia N°{car.orden + 1}</td>
					<td>{car.nombre}</td>
					<td>{car.jornada}</td>
				</tr>
			)) || null
		)
	}

	return (
		<Fragment>
			<div className="row mb-0 pt-3">
				<div className="col s12 m12 l12">
					<TitleForm light={true}>Selección Carreras</TitleForm>
				</div>
			</div>

			<div className="row">
				<div className="col s12 m12 l12 px-5">
					<table className="table tabla-resumen">
						<tbody>{rowsCarreras()}</tbody>
					</table>
				</div>
			</div>
		</Fragment>
	)
}

export default Carreras
