import React, { Fragment } from "react"
import "./styles.scss"
import FichaPostulacion from "../FichaPostulacion/FichaPostulacion"
import InfoPostulacion from "../FichaPostulacion/InfoPostulacion"

const ModalResumen = ({ readOnly = false, sendStep6, loader }) => {
	return (
		<Fragment>
			<div className="modal modal-fixed-footer purple darken-1" id="modal-enviar">
				<div className="modal-content">
					<FichaPostulacion />
				</div>

				<div className="modal-footer footer-ficha purple darken-4">
					<InfoPostulacion />

					<div className="btns right">
						{loader ? (
							<div className="preloader-wrapper active small m-2">
								<div className="spinner-layer spinner-blue-only">
									<div className="circle-clipper left">
										<div className="circle"></div>
									</div>
									<div className="gap-patch">
										<div className="circle"></div>
									</div>
									<div className="circle-clipper right">
										<div className="circle"></div>
									</div>
								</div>
							</div>
						) : (
							<Fragment>
								<a className="modal-close waves-effect waves-green btn-flat white-text ">
									Volver
								</a>
								{readOnly ? null : (
									<a
										onClick={() => sendStep6(true)}
										className="waves-effect waves-light btn-small btn"
									>
										<i className="material-icons right">send</i>
										Enviar
									</a>
								)}
							</Fragment>
						)}
					</div>
				</div>
			</div>
		</Fragment>
	)
}

export default ModalResumen
