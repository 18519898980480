import React, { Fragment, useEffect } from "react"
import M from "materialize-css"

const Select = ({ register, defaultValue, errors, label,  options=[], ...props }) => {
	useEffect(() => {
		M.FormSelect.init(document.getElementsByName(props.name))
	})

	let errorMsg = errors?.type === "required" ? "El campo es requerido" : errors?.message
 
	return (
		<Fragment>
			<select {...props} ref={register}>
				<option value="" disabled selected={defaultValue === "" ? "selected" : false}>
					Selecciona
				</option>
				{options.map((opt, index) => (
					<option
						key={index}
						value={opt.value}
						selected={defaultValue === opt.value ? "selected" : false}
					>
						{opt.name}
					</option>
				))}
			</select>
			<label htmlFor={props.id}>
				{label} 
			</label>

			{errors && (
				<span className="helper-text" data-error="wrong" data-success="right">
					{errorMsg}
				</span>
			)}
		</Fragment>
	)
}

export default Select
