/* Checkea si el usuario está en el local storage y tiene un token..
 * Si es así retorna el formato de bearer token para el header  */

const authHeader = () => {
  const token =localStorage.getItem("ssid")
  if(token){
    return {'Authorization': 'Bearer '+token}
  }else{
    return null
  }
}

const authAdminHeader = () => {
  const user = JSON.parse(localStorage.getItem("admin"))
  if(user && user.access_token){
    console.log(user.access_token)
    return {Authorization: 'Bearer '+user.access_token}
  }else{
    return null
  }
}

export  {authHeader, authAdminHeader}