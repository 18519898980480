import React from "react"
import { useUser } from "../../context/UserProvider"
import "./styles.scss"
import logo_admision from "../../assets/images/logo_admision.png"

const HeaderAlumno = () => {
	const { user, updateUserWithStep } = useUser()
	const logout = async () => {
		if (await updateUserWithStep(null)) {
			window.location.href = "/"
		}
	}

	return (
		<header className="py-3 header-light">
			<div className="row">
				<div className="col s6 center col-logo">
					<img
						alt=""
						className="ula-logo mt-2"
						src={logo_admision}
						width="auto"
						height="34px"
					/>
				</div>
				<div className="col s4 center col-logo">
					<img
						alt=""
						className="ula-logo-acreditacion"
						src="http://vari.ulagos.cl/header-footer/img/acreditacion.png"
					/>
				</div>
				{user && (
					<div className="col s2 right pr-4 col-user">
						<div className="mr-4">
							<i className="material-icons purple-text">person</i> <span>{user.nombres}</span>
						</div>
						<div className=" waves-effect" onClick={() => logout()}>
							<i className="material-icons purple-text">exit_to_app</i> <span>Salir</span>
						</div>
					</div>
				)}
			</div>
		</header>
	)
}

export default HeaderAlumno
