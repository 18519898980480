import React from 'react';
import ReactDOM from 'react-dom';
import App from './App.jsx';
import './css/index.css';
import './css/ula.css';
import './css/ula-purple.css';
import './css/timeline.css';
import { UserProvider } from './context/UserProvider.jsx';



ReactDOM.render(
  <React.StrictMode>
    <UserProvider>
      <App /> 
    </UserProvider>
  </React.StrictMode>,
  document.getElementById('root')
);
