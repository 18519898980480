import React, { Fragment, useState, useEffect } from "react"
import TitleForm from "../../TitleForm/TitleForm"
import { useUser } from "../../../context/UserProvider"
import { sendDataStep5, getTiposArchivo } from "./../../../services/stepForm.service"
import { useDataForm } from "../../../context/FormProvider"
import { validExtensionInputFile } from "../../../utils"
import showToast from "../../Toast/Toast"
import {isEmpty} from "lodash"
const FORMATOS_PERMITDOS = ["pdf", "jpeg", "jpg", "png"]


const Step5 = ({ setLoader }) => {
	const [disableBtn, setDisableBtn] = useState(false)
	const { prevStep, updateUserWithStep, nextStep, user } = useUser()
	const { dataPostulacion, updateArchivos, dataArchivos, tiposArchivo, setTiposArchivo, dataPostulante } = useDataForm()

	useEffect(() => {
		const getData = async () => {
			setLoader(true)
			const res = await getTiposArchivo(dataPostulacion.tipo)
			if (res.response === "success") {
				setTiposArchivo(res.data)
			}
			setLoader(false)
		}

		if (tiposArchivo.length === 0 && dataPostulacion.tipo) {
			getData()
		}
	}, [dataPostulacion])


	const handleSubmitFiles = async e => {
		e.preventDefault()
		setLoader(true)
		setDisableBtn(true)


		const formData = new FormData()
		let contEmpty = 0

		for (let index = 0; index < tiposArchivo.length; index++) {
			const idArchivo = tiposArchivo[index]["id"]
			const inputFile = document.getElementById(`inputFile_${idArchivo}`).files[0]
			// contEmpty = inputFile ? contEmpty : contEmpty+1
			if(inputFile){
				formData.append("archivos[]", inputFile)
				formData.append("id_archivos[]", idArchivo)
			}else{
				contEmpty++
			}
		}

		if(dataArchivos && contEmpty === tiposArchivo.length){
			setLoader(false)
			setDisableBtn(false)
			return nextStep()
		}

		const res = await sendDataStep5(formData)
		if (res?.response === "success") {
			updateArchivos(res.data.archivos)
			showToast("Archivos cargados correctamente")
			updateUserWithStep(res.user)
		} else {
			showToast("Ha ocurrido un problema. Intenta nuevamente")
		}
		setLoader(false)
		setDisableBtn(false)

	}


	const validFormat = id => e => {
		if (!validExtensionInputFile(e.target.value, FORMATOS_PERMITDOS)) {
			showToast("El formato del archivo no es válido")
			document.getElementById(`inputName_${id}`).value = ""
			return (e.target.value = "")
		}
	}

	return (
		<Fragment>
			<form onSubmit={handleSubmitFiles} className="custom-form">
				<TitleForm>Documentos Solicitados</TitleForm>
				<p>
					{dataPostulante.sexo === "M" ? "Estimado" : "Estimada"} {user.nombres} <br/> En este paso
					debes subir los documentos obligatorios para tu postulación, tus documentos deben ser
					auténticos y legibles, <b>recuerda que debes subir su cédula de identidad por ambos lados en un solo archivo.</b>
					<br />
					Formatos permitidos: {FORMATOS_PERMITDOS.toString().replaceAll(",", ", ")}
				</p>

				<div className="row mx-2">
					<div className="col s12">
						{tiposArchivo?.map((tipo, index) => (
							<div className="row my-0" key={index}>
								<div className="col s6">
									<h6>
										<u>{tipo.nombre}</u>
									</h6>
									<div className="file-field input-field">
										<div className="btn-small waves-light purple darken-3">
											<i className="large material-icons">file_upload</i>
											<input
												type="file"
												name="input_archivos"
												id={`inputFile_${tipo.id}`}
												required={
													!isEmpty(dataArchivos) && dataArchivos?.find(x => x.id === tipo.id)
														? false
														: tipo.pivot.obligatorio
												}
												onChange={validFormat(tipo.id)}
											/>
										</div>
										<div className="file-path-wrapper">
											<input
												id={`inputName_${tipo.id}`}
												className="file-path validate"
												type="text"
											/>
										</div>
									</div>
								</div>

								<div className="col s6 pt-5 mt-4">
									{!isEmpty(dataArchivos) && dataArchivos?.find(x => x.id === tipo.id) ? (
										<div>
											<a
												href={`${dataArchivos?.find(x => x.id === tipo.id).pivot.path}`}
												target="_blank"
												rel="noopener noreferrer"
											>
												{dataArchivos?.find(x => x.id === tipo.id).pivot.nombre}
											</a>
										</div>
									) : (
										""
									)}
								</div>
							</div>
						))}
					</div>
				</div>

				<div className="step-buttons">
					<button
						type="button"
						disabled={disableBtn}
						className={"waves-effect waves-light purple darken-1 btn-small mx-2"}
						onClick={() => prevStep()}
					>
						<i className="large material-icons left">navigate_before</i>
						Anterior
					</button>

					<button
						type="submit"
						disabled={disableBtn}
						className=" waves-effect waves-light purple darken-1 btn-small right"
					>
						Siguiente
						<i className="large material-icons right">navigate_next</i>
					</button>
				</div>
			</form>
		</Fragment>
	)
}

export default Step5
