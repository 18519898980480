import React, { useEffect, useState } from "react"
import M from "materialize-css/dist/js/materialize"
import {withRouter} from "react-router-dom"
import Header from './../../components/Header/Header'
import ModalPeriodo from "../../components/ModalAdmin/Configuraciones/ModalPeriodo";
import ModalCarrera from "../../components/ModalAdmin/Configuraciones/ModalCarrera";
import ModalArchivo from "../../components/ModalAdmin/Configuraciones/ModalArchivo";
import ModalTipoPostulacion from "../../components/ModalAdmin/Configuraciones/ModalTipoPostulacion";
import {getPeriodos, getTipoPostulacion, getArchivos, getSedes, getCarrerasSede } from '../../services/admin.service'
import Loader from "../../components/Loader/Loader";

const Parametros = props => {

    const [periodos, setPeriodos] = useState([]);
    const [tpostulacions, setTPostulacion] = useState([]);
    const [archivos, setArchivos] = useState([]);
    const [carreras, setCarreras] = useState([]);
    const [sede, setSede] = useState()
    //LOADERS
    const [lPeriodos, setLPeriodos] = useState(true)
    const [lPostulacions, setLPostulacions] = useState(true)
    const [lArchivos, setLArchivos] = useState(true)
    const [lCarreras, setLCarrera] = useState(false)

    const sedeChange = () => e => {
        setSede(e.target.value)
    }

    const openModal = (id) => {
        console.log(id)
        const modal = document.getElementById(id)
        const instance = M.Modal.init(modal)
        instance.open()
    }

    const initAndOpenModal = async (id) => {
        await M.AutoInit()
        await openModal(id)
    }

    useEffect(()=>{
        const fetchDataPeriodos = async () => {
            setLPeriodos(true)
            const result = await getPeriodos()
            if(result.status === 200){
                setPeriodos(result.data)
                setLPeriodos(false)
            }
            else{
                setLPeriodos(false)
                console.log(result)
            }
        }
        const fetchDataTPostulacions = async () => {
            setLPostulacions(true)
            const result = await getTipoPostulacion()
            if(result.status === 200){
                setTPostulacion(result.data)
                setLPostulacions(false)
            }
            else{
                setLPostulacions(false)
                console.log(result)
            }
        }
        const fetchDataArchivos = async () => {
            setLArchivos(true)
            const result = await getArchivos()
            if(result.status === 200){
                setArchivos(result.data)
                setLArchivos(false)
            }
            else{
                setLArchivos(false)
                console.log(result)
            }
        }

        fetchDataPeriodos()
        fetchDataTPostulacions()
        fetchDataArchivos()
    }, [])

    const rowsPeriodos = () => {
        return periodos.map((periodo, index) => {
            return (
                <tr key={index}>
                    <td>{ periodo.periodo }</td>
                    <td>{ periodo.fecha_inicio }</td>
                    <td>{ periodo.fecha_termino }</td>
                    <td>{ periodo.estado }</td>
                </tr>
            )
        })
    }

    const searchCarreras = async () => {
        setLCarrera(true)
        const result = await getCarrerasSede(sede)
        if(result.status === 200){
            setCarreras(result.data)
            setLCarrera(false)
        }
        else{
            console.log(result)
            setLCarrera(false)
        }
    }

    const rowsCarreras = () => {
        return carreras.map((carrera, index) => {
            return (
                <tr key={index}>
                    <td>{ carrera.codigo_carrera }</td>
                    <td>{ carrera.nombre }</td>
                    <td>{ carrera.tipo }</td>
                    <td>{ carrera.jornada }</td>
                    <td>{ carrera.cupo }</td>
                </tr>
            )
        })
    }

    const rowsTPostulacions = () => {
        return tpostulacions.map((tp, index) => {
            return (
                <tr key={index}>
                    <td>{ tp.codigo }</td>
                    <td>{ tp.nombre }</td>
                    <td></td>
                    <td></td>
                </tr>
            )
        })
    }

    const rowsArchivos = () => {
        return archivos.map((archivo) => {
            return (
                <tr key={archivo.id}>
                    <td>{ archivo.id }</td>
                    <td>{ archivo.nombre }</td>
                </tr>
            )
        })
    }

    const rowsSedes = () => {
        const sedes = getSedes()
        return sedes.map((sede)=>{
            return (
                <option key={sede.id} value={sede.id}>{sede.nombre}</option>
            )
        })
    }

    return (
        <div>
            <Header />
            <div className="row container title-scope">
                <div className="title col s12 l12 left"><h6>Menú de</h6><h2>Parametrización</h2></div>
            </div>
            <div className="row container title-scope margin-bottom-24px">
                <div className="col s12 m12 l8 note text-align-justify">A continuación se muestran los parámetros de Sistema que se pueden configurar
                </div>
            </div>
            <div className="container">
                <div className="row" style={{marginTop:40 + "px"}}>
                    <div className="col s12 m12 l12">
                        <div className="card" style={{ padding: 15 + "px" }}>
                            <span className="card-title text-ellipsis theme-text">Periodos</span>
                            <table className="table-responsive">
                                <thead>
                                    <tr>
                                        <th>Periodo</th>
                                        <th>Fecha Inicio</th>
                                        <th>Fecha Término</th>
                                        <th>Estado</th>
                                    </tr>
                                </thead>
                                <tbody>
                                { rowsPeriodos() }
                                </tbody>
                            </table>
                            { <Loader loader={lPeriodos} />}
                        </div>
                    </div>
                </div>
            </div>
            <div className="container">
                <div className="row" style={{marginTop:40 + "px"}}>
                    <div className="col s12 m12 l12">
                        <div className="card" style={{ padding: 15 + "px" }}>
                            <span className="card-title text-ellipsis theme-text">Carreras</span>
                            <div className="row">
                                <div className="col s12 m6 l6">
                                    <div className="input-field">
                                        <select onChange={sedeChange()}>
                                            <option value="" disabled selected>Seleccione una Sede</option>
                                            { rowsSedes() }
                                        </select>
                                        <label>Sede</label>
                                    </div>
                                </div>
                                <div className="col s12 m6 l6  margin-top-24px">
                                    <button type="button" className="button theme-button" onClick={ ()=>searchCarreras() }>Buscar</button>
                                </div>
                            </div>
                            <table className="table-responsive">
                                <thead>
                                <tr>
                                    <th>Código</th>
                                    <th>Carrera</th>
                                    <th>Tipo</th>
                                    <th>Jornada</th>
                                    <th>Cupo</th>
                                    <th></th>
                                </tr>
                                </thead>
                                <tbody>
                                { rowsCarreras() }
                                </tbody>
                            </table>
                            { <Loader loader={lCarreras} />}
                        </div>
                    </div>
                </div>
            </div>
            <div className="container">
                <div className="row" style={{marginTop:40 + "px"}}>
                    <div className="col s12 m12 l12">
                        <div className="card" style={{ padding: 15 + "px" }}>
                            <span className="card-title text-ellipsis theme-text">Tipo de Postulación</span>
                            <table className="table-responsive">
                                <thead>
                                <tr>
                                    <th>Código</th>
                                    <th>Nombre</th>
                                    <th>Archivos</th>
                                    <th></th>
                                </tr>
                                </thead>
                                <tbody>
                                { rowsTPostulacions() }
                                </tbody>
                            </table>
                            { <Loader loader={lPostulacions} />}
                        </div>
                    </div>
                </div>
            </div>
            <div className="container">
                <div className="row" style={{marginTop:40 + "px"}}>
                    <div className="col s12 m12 l12">
                        <div className="card" style={{ padding: 15 + "px" }}>
                            <span className="card-title text-ellipsis theme-text">Archivos</span>
                            <table className="table-responsive">
                                <thead>
                                <tr>
                                    <th>Id</th>
                                    <th>Nombre</th>
                                </tr>
                                </thead>
                                <tbody>
                                { rowsArchivos() }
                                </tbody>
                            </table>
                            { <Loader loader={lArchivos} />}
                        </div>
                    </div>
                </div>
            </div>
            <ModalPeriodo />
            <ModalCarrera />
            <ModalArchivo />
            <ModalTipoPostulacion />
            <div className="fixed-action-btn direction-top">
                <button className="btn-floating btn-large theme-button">
                    <i className="material-icons">menu</i>
                </button>
                <ul>
                    <li>
                        <button onClick={() => initAndOpenModal('modal-periodo') } className="btn-floating tooltipped green"
                           data-position="left" data-tooltip="Nuevo Periodo">
                            <i className="material-icons white">date_range</i>
                        </button>
                        <span style={{fontSize:0.85+ "em"}}>Periodo</span>
                    </li>
                    <li>
                        <button onClick={() => initAndOpenModal('modal-carrera') } className="btn-floating tooltipped green"
                           data-position="left" data-tooltip="Nueva Carrera">
                            <i className="material-icons white">class</i>
                        </button>
                        <span style={{fontSize:0.85+ "em"}}>Carrera</span>
                    </li>
                    <li>
                        <button onClick={() => initAndOpenModal('modal-tipo-postulacion') } className="btn-floating tooltipped green"
                           data-position="left" data-tooltip="Nuevo Tipo Postulación">
                            <i className="material-icons white">markunread_mailbox</i>
                        </button>
                        <span style={{fontSize:0.85+ "em"}}>Tipo Postulación</span>
                    </li>
                    <li>
                        <button onClick={() => initAndOpenModal('modal-archivo') } className="btn-floating tooltipped green"
                           data-position="left" data-tooltip="Nuevo Archivo">
                            <i className="material-icons white">insert_drive_file</i>
                        </button>
                        <span style={{fontSize:0.85+ "em"}}>Archivo</span>
                    </li>
                </ul>
            </div>
        </div>
    )
}

export default withRouter(Parametros)
