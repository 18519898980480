import React, { useState, useEffect } from "react"
import {withRouter} from "react-router-dom"
import Header from "../../components/Header/Header"
import Select from "./../../components/Select/Select"
import { validExtensionInputFile } from "./../../utils"
import showToast from "./../../components/Toast/Toast"
import {
	getTiposAdmision,
	getPeriodos,
	sendExcelIngresoEspecial
} from "../../services/admin.service"
import Loader from "../../components/Loader/Loader"
import ResponseExcel from "../../components/ResponseExcel/ResponseExcel"
const FORMATOS_PERMITIDOS = ["xlsx", "xls"]
const CargaIngresos = () => {
	const [tiposAdmision, setTiposAdmision] = useState([])
	const [periodos, setPeriodos] = useState([])
	const [tipoAdmisionSelected, setTipoAdmisionSelected] = useState("")
	const [periodoSelected, setPeriodoSelected] = useState("")
	const [loader, setLoader] = useState(false)
	const [responseExcel, setResponseExcel] = useState({})
	const [showFilas, setShowFilas] = useState(false)
	const validFormat = id => e => {
		if (!validExtensionInputFile(e.target.value, FORMATOS_PERMITIDOS)) {
			showToast("El formato del archivo no es válido")
			document.getElementById(id).value = ""
			return (e.target.value = "")
		}
	}

	const fetchTiposAdmision = async () => {
		setLoader(true)
		const res = await getTiposAdmision()
		console.log(res)
		if (res?.status === 200) {
			setTiposAdmision(res.data)
		} else {
			showToast("No se cargaron los tipos de admisión, intente de nuevo")
		}
		setLoader(false)
	}

	const fetchPeriodos = async () => {
		const result = await getPeriodos()
		if (result?.status === 200) {
			setPeriodos(result.data)
		} else {
			showToast("No se cargaron los Periodos, intente de nuevo")
		}
	}

	useEffect(() => {
		fetchTiposAdmision()
		fetchPeriodos()
	}, [])

	const handleChangePeriodo = e => {
		console.log(e.target.value)
		setPeriodoSelected(e.target.value)
	}

	const handleChangeTipo = e => {
		console.log(e.target.value)
		setTipoAdmisionSelected(e.target.value)
	}

	const handleSubmitExcel = async e => {
		e.preventDefault()
		setShowFilas(false)
		if (periodoSelected === "") return showToast("Debe seleccionar Periodo")
		if (tipoAdmisionSelected === "") return showToast("Debe seleccionar Tipo de Admisión")
		setLoader(true)
		let inputFile = document.getElementById("inputFileExcel").files[0]
		const formData = new FormData()
		formData.append("archivo", inputFile)
		formData.append("periodo", periodoSelected)
		formData.append("tipo_admision", tipoAdmisionSelected)

		const res = await sendExcelIngresoEspecial(formData)
		if (res?.response === "success") {
			showToast(res.message)
			setResponseExcel({ ...res?.data, isValid: true })
			setShowFilas(true)
			//TODO: gestionar qué sucede ahora
		} else if(res?.response === "error"){
			showToast(res?.message || "Se ha producido un error al cargar archivo")
			if (Object.keys(res?.data).length > 0) {
				setResponseExcel({...res?.data, isValid: false})
				setShowFilas(true)
			}
		}else{
			showToast("Se ha producido un error al cargar el archivo")
		}
		console.log(res)
		setLoader(false)
	}

	return (
		<div>
			<Header />
			<div className="row container title-scope">
				<div className="title col s12 l12 left">
					<h6>Carga de Archivos</h6>
					<h2>INGRESOS ESPECIALES</h2>
				</div>
			</div>
			<div className="row container title-scope margin-bottom-24px">
				<div className="col s12 m12 l8 note text-align-justify">
					A continuación se muestra foasdifoa dsb
				</div>
			</div>
			<div className="container">
				<div className="row my-5 mx-2 card py-5 px-2">
					{loader ? (
						<Loader loader={loader} />
					) : (
						<form onSubmit={handleSubmitExcel}>
							<div className="col s3 input-field">
								<Select
									id="selectPeriodo"
									label="Periodo"
									name="periodo"
									required={false}
									defaultValue={""}
									options={periodos?.map(s => {
										return { value: s.id, name: `Año ${s.periodo} - ${s.estado}` }
									})}
									onChange={handleChangePeriodo}
								/>
							</div>

							<div className="col s3 input-field">
								<Select
									id="selectTipoAdmision"
									defaultValue={""}
									label="Tipo Admisión"
									name="tipo_admision"
									required={false}
									options={tiposAdmision?.map(s => {
										return { value: s.id, name: s.tipo }
									})}
									onChange={handleChangeTipo}
								/>
							</div>

							<div className="file-field input-field col s3">
								<div className="btn-small waves-light purple darken-3">
									<i className="large material-icons">file_upload</i>
									<input
										type="file"
										id="inputFileExcel"
										name="input_archivos"
										required
										onChange={validFormat("inputArchivo")}
									/>
								</div>
								<div className="file-path-wrapper">
									<input
										id="inputArchivo"
										className="file-path validate"
										type="text"
										placeholder="Cargar Excel de Ingresos Especiales"
									/>
								</div>
							</div>

							<div className="col s3 input-field">
								<button
									type="submit"
									className="waves-effect waves-light bg-ula-blue  mt-2 mx-4"
								>
									<i className="material-icons right">backup</i> Realizar Carga
								</button>
							</div>
						</form>
					)}
					{showFilas && (
						<div className="col s12 center">
							<ResponseExcel data={responseExcel} />
						</div>
					)}
				
				</div>
			</div>
		</div>
	)
}

export default withRouter(CargaIngresos)
