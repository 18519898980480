import React, { Fragment, useEffect, useState } from "react"
import M from "materialize-css/dist/js/materialize"
import {withRouter} from "react-router-dom"
import Header from './../../components/Header/Header'
import {getReporteAdmision} from "../../services/admin.service";
import {useAdmin} from "../../context/AdminProvider";
import Loader from "../../components/Loader/Loader";

const ReporteAdmision = props => {

    const {history} = props
    const { admin, updateAdmin } = useAdmin()
    const [data, setData] = useState([]);
    const [loader, setLoader] = useState(true)

    useEffect( () => {
        const fetchData = async () => {
            if (admin !== null) {
                setLoader(true)
                const result = await getReporteAdmision()
                if (result?.status === 200) {
                    setData(result.data)
                } else console.log(result)
                setLoader(false)
            }
        }

        fetchData()
    }, []);


    const rowData = () => {
        return data.map(data => {
            return <tr>
                <td>{ data.nombre }</td>
                <td>{ data.rut }</td>
                <td>{ data.carrera }</td>
                <td>{ data.sede }</td>
                <td>{ data.estado }</td>
            </tr>
        })
    }

    return (
        <div>
            <Header />
            <div className="row container title-scope">
                <div className="title col s12 l12 left"><h6>Reporte</h6><h2>de Admisión</h2></div>
            </div>
            <div className="row container title-scope margin-bottom-24px">
                <div className="col s12 m12 l8 note text-align-justify">A continuación se presenta el reporte de Admisión En-Línea
                </div>
            </div>
            <div className="container content margin-top-48px">
                { (loader)? <Loader loader={loader} /> :
                    <table>
                        <thead>
                            <tr>
                                <th>Nombre</th>
                                <th>Rut</th>
                                <th>Carrera</th>
                                <th>Sede</th>
                                <th>Estado</th>
                            </tr>
                        </thead>
                        <tbody>
                        { rowData() }
                        </tbody>
                    </table>
                }
            </div>
        </div>
    )
}

export default withRouter(ReporteAdmision)
