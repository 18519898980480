import React, { Fragment, useState } from "react"

import Step1 from "./steps/Step1"
import Step2 from "./steps/Step2/Step2"
import Step3 from "./steps/Step3/Step3"
import Step4 from "./steps/Step4"
import Step5 from "./steps/Step5"
import Step6 from "./steps/Step6"
import Loader from "../Loader/Loader"

import { useUser } from "../../context/UserProvider"

const UserForm = () => {
	const [loader, setLoader] = useState(false)

	const { currentStep } = useUser()


		return (
			<Fragment>
				{currentStep === 1 && <Step1 setLoader={setLoader} />}
	
				{currentStep === 2 && (
					<Step2 setLoader={setLoader}  />
				)}
	
				{currentStep === 3 && (
					<Step3 setLoader={setLoader} />
				)}
	
				{currentStep === 4 && <Step4 setLoader={setLoader} />}
	
				{currentStep === 5 && <Step5 setLoader={setLoader} />}
	
				{currentStep === 6 && <Step6 setLoader={setLoader} />}
	
				<Loader loader={loader} />
			</Fragment>
		)

}

export default UserForm
