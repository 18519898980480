import React from "react"

const Loader = ({ loader }) => {
	return loader ? (
		<div className="row">
			<div className="col s12">
				<div className="progress completed">
					<div className="indeterminate purple"></div>
				</div>
			</div>
		</div>
	) : null
}

export default Loader
