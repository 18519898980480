import React, { Fragment } from "react"
import { useUser } from "../../context/UserProvider"
import "./StepList.scss"

const steps = [
	"Presentación y Validación",
	"Datos Personales",
	"Datos de Ubicación",
	"Selección de Carreras",
	"Carga de Documentos",
	"Finalización"
]

const StepList = () => {
	const { user, currentStep, setCurrentStep } = useUser()

	/* Evento para cambiar de etapa al clickear el menu */
	const setStep = step => e => {
		if (step <= user.etapa) {
			setCurrentStep(step)
		}
	}

	const generateList = () => {
		const _classname = "collection-item-custom valign-wrapper"
		const _active = _classname + " item-active purple"
		const _completed = _classname + " completed"

		return steps.map((step, index) => (
			<li
				onClick={setStep(index + 1)}
				key={index + 1}
				className={
					currentStep === index + 1 ? _active : index + 1 < user?.etapa ? _completed : _classname
				}
			>
				<b>{index + 1}.</b> {step}
			</li>
		))
	}

	return (
		<Fragment>
			<div className="pb-1 px-3">
				<span className="title-steps">Admisión en Línea</span>
			</div>

			<ul className="collection with-header collection-custom">{generateList()}</ul>
		</Fragment>
	)
}

export default StepList
