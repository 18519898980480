import React, { useState, useEffect } from "react"
import M from "materialize-css"
import ModalResumen from "../../ResumenAlumno/ModalResumen"
import { useDataForm } from "../../../context/FormProvider"
import { useUser } from "../../../context/UserProvider"

const Step10 = () => {
	const [title, setTitle] = useState("")
	const [texto, setTexto] = useState("")
	const { dataPostulacion, dataPostulante } = useDataForm()
	const { user } = useUser()

	const getModal = () => {
		const modal = document.getElementById("modal-enviar")
		return M.Modal.init(modal, { preventScrolling: false })
	}
	const openModal = () => {
		getModal().open()
	}

	const initAndOpenModal = async () => {
		await M.AutoInit()
		await openModal()
	}

	const generateInfo = estado => {
		switch (estado) {
			case "INTERESA":
				setTitle("Tu postulación ha sido enviada satisfactoriamente")
				setTexto(
					`	${dataPostulante.sexo === "M" ? "Estimado" : "Estimada"} ${user.nombres}, hemos
						recibido tu postulación para proceso de Admisión 2022 exitosamente. Tus antecedentes
						están siendo validados por el equipo de Admisión ULagos. Durante las próximas horas
						te confirmaremos el estado de tu Postulación por correo electrónico, o bien, se contactarán
						contigo para confirmar tu Matrícula 2022. <br>
						En caso de dudas o consultas, puedes
						contactarte con el equipo de Admisión ULagos, mediante las siguientes vías, no
						olvides de indicar tu RUT, carrera y detalle de tu duda o consulta. 
					<p>
							<p>Cualquier duda sobre tu postulación a las Sedes Osorno - Puerto Montt - Chiloé <br />
							<b>Números telefónicos: +56 9 53581342  -  +56 64 2333000</b> <br/>
							<b>Correo electrónico: admision@ulagos.cl </b><br />
							Para estudiantes postulantes Sede Santiago: <br >
							<b>correo: admisionsantiago@ulagos.cl  </b><br />
							<b>teléfono de contacto: +56 9 68036585 </b></p>
					</p>`
				)
				break
			case "INICIO":
				setTitle("Aun no has enviado tu postulación")
				setTexto("")
				break
			case "ACEPTADO":
				setTitle("Tu postulación ha sido ACEPTADA")
				setTexto("")
				break
			case "RECHAZADO":
				setTitle("Tu postulación ha sido rechazada")
				setTexto("")
				break
			case "L_ESPERA":
				setTitle("Tu postulación se encuentra en LISTA DE ESPERA")
				setTexto("")
			default:
				setTitle("No se encuentran datos de postulación para este periodo")
				setTexto("")
				break
		}
	}

	useEffect(() => {
		document.body.classList.add("purple", "darken-1", "fondo-transparente")
	}, [])

	/* seteo el titulo segun el estado de la postulacion */
	useEffect(() => {
		if (dataPostulacion.estado) {
			generateInfo(dataPostulacion.estado)
		} else {
			generateInfo("INTERESA")
		}
	}, [dataPostulacion.estado])

	return (
		<div className="container">
			<div className="row">
				<div className="col s12 center">
					<h3>{title}</h3>
				</div>
				<div className="col s12">
					<p dangerouslySetInnerHTML={{ __html: texto }} />
				</div>
			</div>

			<div className="row">
				<div className="col s12 center">
					<button
						className="waves-effect waves-light btn-large mr-2 purple darken-3"
						onClick={() => initAndOpenModal()}
					>
						Ver Postulación
					</button>
				</div>
			</div>

			<ModalResumen readOnly={true} />
		</div>
	)
}

export default Step10
