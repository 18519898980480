import React, { Fragment, useState } from "react"
import TitleForm from "../../../TitleForm/TitleForm"
import InputField from "../../../InputField/InputField"
import Select from "../../../Select/Select"
import { valideNumberInput } from "./../../../../utils"
import { useEffect } from "react"

const SectionResidencia = props => {
	const { title, register, errors, dataPostulante, optionsSelect } = props
	const [provincias, setProvincias] = useState(optionsSelect?.provincia)
	const [comunas, setComunas] = useState(optionsSelect?.comuna)

	const handleChangeRegion = e => {
		const { value } = e.target
		selectProvincias(value)
		document.getElementById("selectProvincia").value = ""
		document.getElementById("selectCiudad").value = ""
		document.getElementById("selectComuna").value = ""
	}

	const selectProvincias = value => {
		const filteredProv = optionsSelect.provincia.filter(p => p.value.slice(0, 2) === value)
		setProvincias(filteredProv)
	}

	const handleChangeProvincia = e => {
		const { value } = e.target
		selectComunas(value)
		document.getElementById("selectCiudad").value = ""
		document.getElementById("selectComuna").value = ""
	}

	const selectComunas = value => {
		const filteredCom = optionsSelect.comuna.filter(p => p.value.slice(0, 3) === value)
		setComunas(filteredCom)
	}

	const setSelectedOptions = () => {
		const region = document.getElementById("selectRegion").value
		const provincia = document.getElementById("selectProvincia").value

		region && selectProvincias(region)
		provincia && selectComunas(provincia)
 
	}

	useEffect(() => {
		setSelectedOptions()
	}, [])

	return (
		<Fragment>
			<TitleForm>{title}</TitleForm>
			<div className="row">
				<div className="col s5 input-field">
					<InputField
						id="inputCalle"
						name="postulante.calle"
						label="Dirección"
						defaultValue={dataPostulante?.calle || ""}
						register={register}
						errors={errors?.postulante?.calle}
					/>
				</div>

				<div className="col s1 input-field">
					<InputField
						id="inputNumero"
						name="postulante.numero"
						label="Nro"
						onInput={valideNumberInput(5)}
						defaultValue={dataPostulante?.numero || ""}
						register={register}
						errors={errors?.postulante?.numero}
					/>
				</div>

				<div className="col s4 input-field">
					<InputField
						id="inputVilla"
						name="postulante.villa"
						label="Población/Villa/Sector"
						defaultValue={dataPostulante?.villa || ""}
						register={register}
						errors={errors?.postulante?.villa}
					/>
				</div>

				<div className="col s2 input-field">
					<InputField
						id="inputdepto"
						name="postulante.depto"
						label="Depto/Casa"
						defaultValue={dataPostulante?.depto || ""}
						register={register}
						errors={errors?.postulante?.depto}
					/>
				</div>
			</div>

			<div className="row">
				<div className="col s3 input-field">
					<Select
						id="selectRegion"
						name="postulante.region"
						label="Región"
						defaultValue={dataPostulante?.region || ""}
						options={optionsSelect?.region}
						onChange={handleChangeRegion}
						register={register}
						errors={errors?.postulante?.region}
					/>
				</div>

				<div className="col s3 input-field">
					<Select
						id="selectProvincia"
						name="postulante.provincia"
						defaultValue={dataPostulante?.provincia || ""}
						label="Provincia"
						options={provincias}
						onChange={handleChangeProvincia}
						register={register}
						errors={errors?.postulante?.provincia}
					/>
				</div>

				<div className="col s3 input-field">
					<Select
						id="selectCiudad"
						name="postulante.ciudad"
						defaultValue={dataPostulante?.ciudad || ""}
						label="Ciudad"
						options={optionsSelect?.ciudad}
						register={register}
						errors={errors?.postulante?.ciudad}
					/>
				</div>

				<div className="col s3 input-field">
					<Select
						id="selectComuna"
						name="postulante.comuna"
						defaultValue={dataPostulante?.comuna || ""}
						label="Comuna"
						options={comunas}
						register={register}
						errors={errors?.postulante?.comuna}
					/>
				</div>
			</div>
		</Fragment>
	)
}

export default SectionResidencia
