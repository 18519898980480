import React, { useEffect, useState } from "react"
import { BrowserRouter as Router, Switch, Route, Redirect } from "react-router-dom"
import "./assets/styles/App.scss"
import "materialize-css/dist/css/materialize.min.css"
import { getCurrentUser } from "./services/auth.service"

import Home from "./pages/Home"
import User from "./pages/user/User"
import MenuPrincipal from "./pages/admin/MenuPrincipal"
import Usuarios from "./pages/admin/Usuarios"
import Postulacion from "./pages/admin/Postulacion"
import ListaPostulaciones from "./pages/admin/ListaPostulaciones"
import LoginPage from "./pages/admin/LoginPage"
import Configuracion from "./pages/admin/Configuracion"

// import Edelfos from "./pages/admin/Edelfos"
import ClaveUnica from "./ClaveUnica"
import Postulaciones from "./pages/admin/Postulaciones"
import Bandeja from "./pages/admin/Bandeja"
import ListaEspera from "./pages/admin/ListaEspera"
import Parametros from "./pages/admin/Parametros"
import Reportes from "./pages/admin/Reportes"
import ReporteAdmision from "./pages/admin/ReporteAdmision"
import ReporteCargas from "./pages/admin/ReporteCargas"
import Register from "./pages/Register"
import Login from "./pages/Login"
import { FormProvider } from "./context/FormProvider"
import { AdminProvider } from "./context/AdminProvider"
import { useUser } from "./context/UserProvider"
import showToast from "./components/Toast/Toast"
import IngresosEspeciales from "./pages/admin/IngresosEspeciales"
import CargaIngresos from "./pages/admin/CargaIngresos";
import VerCargas from "./pages/admin/VerCargas";
import DetalleExcel from "./pages/admin/DetalleExcel";

function App() {
	const { setUser, setCurrentStep, callOptions } = useUser()
	const [loader, setLoader] = useState(false)

	const setUserIfExists = async () => {
		if (localStorage.getItem("ssid")) {
			setLoader(true)
			const res = await getCurrentUser()
			if(res){
				await setUser(res)
				setCurrentStep(res?.etapa)
				await callOptions()
			}else{
				localStorage.clear()
				showToast("La sesión ha expirado")
			}
			setLoader(false)
		}
	}

	useEffect(() => {
		setUserIfExists()
	}, [])

	return (
		<Router>
			<Switch>
				<Route exact path="/">
					<Home loader={loader} />
				</Route>

				<ProtectedRouteUser exact path="/form">
					<FormProvider>
						<User />
					</FormProvider>
				</ProtectedRouteUser>

				<Route exact path="/claveunica">
					<FormProvider>
						<ClaveUnica />
					</FormProvider>
				</Route>

				<Route exact path="/login">
					<FormProvider>
						<Login />
					</FormProvider>
				</Route>

				<Route exact path="/register">
					<FormProvider>
						<Register />
					</FormProvider>
				</Route>

				<Route exact path="/admin">
					<AdminProvider>
						<MenuPrincipal />
					</AdminProvider>
				</Route>
				<Route exact path="/admin/login">
					<AdminProvider>
						<LoginPage />
					</AdminProvider>
				</Route>
				{/* 	<Route exact path="/admin/edelfos">
					<AdminProvider>
						<Edelfos />
					</AdminProvider>
				</Route> */}
				<Route exact path="/admin/configuracion">
					<AdminProvider>
						<Configuracion />
					</AdminProvider>
				</Route>
				<Route exact path="/admin/configuracion/parametros">
					<AdminProvider>
						<Parametros />
					</AdminProvider>
				</Route>
				<Route exact path="/admin/configuracion/usuarios">
					<AdminProvider>
						<Usuarios />
					</AdminProvider>
				</Route>
				<Route exact path="/admin/postulaciones">
					<AdminProvider>
						<Postulaciones />
					</AdminProvider>
				</Route>
				<Route exact path="/admin/postulaciones/todas">
					<AdminProvider>
						<ListaPostulaciones />
					</AdminProvider>
				</Route>
				<Route exact path="/admin/postulaciones/p/:postulacion">
					<AdminProvider>
						<Postulacion />
					</AdminProvider>
				</Route>
				<Route exact path="/admin/postulaciones/bandeja">
					<AdminProvider>
						<Bandeja />
					</AdminProvider>
				</Route>
				<Route exact path="/admin/postulaciones/listaespera">
					<AdminProvider>
						<ListaEspera />
					</AdminProvider>
				</Route>
				<Route exact path="/admin/ingresos_especiales">
					<AdminProvider>
						<IngresosEspeciales />
					</AdminProvider>
				</Route>
				<Route exact path="/admin/ingresos_especiales/cargar">
					<AdminProvider>
						<CargaIngresos />
					</AdminProvider>
				</Route>
				<Route exact path="/admin/ingresos_especiales/ver">
					<AdminProvider>
						<VerCargas />
					</AdminProvider>
				</Route>
				<Route exact path="/admin/ingresos_especiales/ver/:carga">
					<AdminProvider>
						<DetalleExcel />
					</AdminProvider>
				</Route>
				<Route exact path="/admin/reportes">
					<AdminProvider>
						<Reportes />
					</AdminProvider>
				</Route>
				<Route exact path="/admin/reportes/admision">
					<AdminProvider>
						<ReporteAdmision />
					</AdminProvider>
				</Route>
				<Route exact path="/admin/reportes/cargas">
					<AdminProvider>
						<ReporteCargas />
					</AdminProvider>
				</Route>
			</Switch>
		</Router>
	)
}

const ProtectedRouteUser = ({ children, ...rest }) => {
	const { user } = useUser()

	if (user) {
		return <Route {...rest}>{children}</Route>
	} else {
		return <Redirect to={{ pathname: "/" }} />
	}
}

export default App
