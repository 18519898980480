import React, {useEffect, useState} from "react"
import {withRouter} from "react-router-dom"
import Header from './../../components/Header/Header'
import './css/card.css'
import {useAdmin} from "../../context/AdminProvider";
import Loader from "../../components/Loader/Loader";
import {getCargas} from "../../services/admin.service";
import showToast from "../../components/Toast/Toast";

const VerCargas = props => {
    const { history } = props

    const { admin } = useAdmin()
    const [loader, setLoader] = useState(false)
    const [data, setData] = useState([])

    useEffect(() => {
        const fetchData = async () => {
            if (admin === null) {
                history.push("/admin/login")
            }
            setLoader(true)
            const res = await getCargas()
            console.log(res)
            console.log(res.status)
            if (res?.status === 200) {
                setData(res.data)
            } else {
                showToast("No se cargó la información, intente de nuevo")
            }
            setLoader(false)

        }

        //setData(dataPostulaciones)

        fetchData()
    }, [admin]) // eslint-disable-line react-hooks/exhaustive-deps

    const rowCargas = () => {
        return data.map((carga, i) => {
            return (
                <tr key={"carga-" + carga.id}>
                    <td><a href={"/admin/ingresos_especiales/ver/" + carga.id}>{ carga.id }</a></td>
                    <td>{ carga.fecha }</td>
                    <td>{ carga.tipo_admision }</td>
                    <td>{ carga.periodo }</td>
                    <td>{ carga.filas }</td>
                    <td>{ (carga.sync_edelfos)? "Cargado" : "Sin Cargar" }</td>
                </tr>
            )
        })
    }

    return (
        <div>
            <Header />
            <div className="row container title-scope">
                <div className="title col s12 l12 left">
                    <h6>Cargas</h6>
                    <h2>Realizadas</h2>
                </div>
            </div>
            <div className="row container title-scope margin-bottom-24px">
                <div className="col s12 m12 l8 note text-align-justify">
                    A continuación se muestra la tabla con las cargas de archivo realizadas y su estado actual.
                </div>
            </div>
            <div className="container">
                <div className="row my-5 mx-2 card py-5 px-2">
                    {loader ? (
                        <Loader loader={loader} />
                    ) : (<div>
                        <table>
                            <thead>
                                <tr className="row">
                                    <th className="col-6">Carga</th>
                                    <th className="col-1">Fecha</th>
                                    <th className="col-2">Tipo de Admisión</th>
                                    <th className="col-1">Periodo</th>
                                    <th className="col-1">Filas</th>
                                    <th className="col-1">Estado Sync</th>
                                </tr>
                            </thead>
                            <tbody>
                            { rowCargas() }
                            </tbody>
                        </table>
                    </div>) }
                </div>
            </div>
        </div>
    )
}

export default withRouter(VerCargas)