import React, { useEffect, useState } from "react"
import M from "materialize-css/dist/js/materialize"
import { withRouter } from "react-router-dom"
import Header from "./../../components/Header/Header"
import { getInfoUsuarios, refreshUser } from "../../services/admin.service"
import ModalSedes from "../../components/ModalAdmin/Usuarios/ModalSedes"
import ModalRoles from "../../components/ModalAdmin/Usuarios/ModalRoles"
import Loader from "../../components/Loader/Loader"
import { useAdmin } from "../../context/AdminProvider"

const Usuarios = props => {
	const { updateAdmin } = useAdmin()

	const [data, setData] = useState([])
	const [loader, setLoader] = useState(true)

	const openModal = id => {
		console.log(id)
		const modal = document.getElementById(id)
		const instance = M.Modal.init(modal, {
			onCloseEnd: () => {}
		})
		instance.open()
	}

	const initAndOpenModal = async id => {
		await M.AutoInit()
		await openModal(id)
	}

	const reloadTooltip = async () => {
		await M.AutoInit()
		M.Tooltip.init(document.querySelectorAll(".tooltipped"))
	}

	const fetchData = async () => {
		setLoader(true)
		let result = await getInfoUsuarios()
		console.log(result)
		if (result.status === 200) {
			setData(result.data)
			await reloadTooltip()
		} else console.log(result)

		setLoader(false)
	}

	useEffect(() => {
		const refreshAdminUser = async () => {
			const result = await refreshUser()
			if (result.status === 200) {
				console.log(result)
				await updateAdmin(result.data)
				await reloadTooltip()
			} else console.log(result)
		}

		fetchData()
		refreshAdminUser()
	}, [])

	const viewUsuarios = () => {
		return data.map(user => (
			<tr key={user.usuario.id}>
				<td>{user.usuario.nombres}</td>
				<td>{user.rol.rol}</td>
				<td>{user.rol.sedes}</td>
				<td>
					{user.rol.rol === "Administrador" ? (
						<a
							href="#"
							className="tooltipped"
							onClick={() => initAndOpenModal("modal-sedes-" + user.usuario.id)}
							data-position="left"
							data-tooltip="Modificar Sedes"
						>
							<i className="material-icons blue-text small mr-4">edit</i>
						</a>
					) : null}
					<a
						href="#"
						className="tooltipped"
						onClick={() => initAndOpenModal("modal-roles-" + user.usuario.id)}
						data-position="left"
						data-tooltip="Modificar Roles"
					>
						<i className="material-icons blue-text small">person</i>
					</a>
				</td>
				<ModalSedes
					id={user.usuario.id}
					sedes={user.rol.sedes}
					usuario={user.usuario}
					refresh={fetchData}
				/>
				<ModalRoles
					id={user.usuario.id}
					rol={user.rol}
					usuario={user.usuario}
					refresh={fetchData}
				/>
			</tr>
		))
	}

	return (
		<div>
			<Header />
			<div className="row container title-scope">
				<div className="title col s12 l12 left">
					<h6>Menú de</h6>
					<h2>Usuarios</h2>
				</div>
			</div>
			<div className="row container title-scope margin-bottom-24px">
				<div className="col s12 m12 l8 note text-align-justify">
					A continuación se muestra el menú de Usuarios disponible
				</div>
			</div>
			<div className="container">
				<div className="row" style={{ marginTop: 40 + "px" }}>
					<div className="col s12 m12 l12">
						<div className="card" style={{ padding: 15 + "px" }}>
							<span className="card-title text-ellipsis theme-text">Roles de Usuario</span>
							<table>
								<thead>
									<tr>
										<th>Usuario</th>
										<th>Rol</th>
										<th>Sedes</th>
										<th>Acciones</th>
									</tr>
								</thead>
								<tbody>{viewUsuarios()}</tbody>
							</table>
							<Loader loader={loader} />
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

export default withRouter(Usuarios)
