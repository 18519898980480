import React, { Fragment, useEffect } from "react"
import M from "materialize-css"

const InputField = ({ register, errors, label, ...props }) => {
	useEffect(() => {
		M.updateTextFields()
		if(props["data-length"]){
			M.CharacterCounter.init(document.getElementById(props.id))
		} 
	})


	const isValidate = props.noValidate ? "" : "validate"
	const inputType = props.type || "text"

	let errorMsg =
		errors?.type === "required"
			? "El campo es requerido"
			: errors?.type === "typeError"
			? "Tipo de dato inválido"
			: errors?.message

	return (
		<Fragment>
			<input
				type={inputType}
				className={isValidate}
				ref={register}
				{...props}
			/>
			<label htmlFor={props.id}>{label}</label>
			{errors && <span className="helper-text">{errorMsg}</span>}
		</Fragment>
	)
}

export default InputField
