import React, { useState } from "react"
import { withRouter } from "react-router-dom"
import showToast from "../components/Toast/Toast"
import { loginRut } from "../services/auth.service"
import { useEffect } from "react"
import { useUser } from "../context/UserProvider"
import { useDataForm } from "../context/FormProvider"
import ContainerAuth from "../components/ContainerAuth/ContainerAuth"
import Inputmask from "inputmask"
import Loader from "../components/Loader/Loader"

const Login = props => {
	const { updateUserWithStep, setUserToken, callOptions } = useUser()
	const { saveInitialDataFromLogin } = useDataForm()
	const [loading, setLoading] = useState(false)
	const [rut, setRut] = useState("")

	const setInputMask = () => {
		const maskConfigRut = {
			mask: "(9.999.999)|(99.999.999)",
			autoUnmask: true, //para que .val() devuelva sin mascara (sin puntos ni guion)
			keepStatic: true, //para que el formato de mascara mas corta se mantenga hasta que sea necesario el mas largo
			showMaskOnFocus: false, //oculta la mascara en focus
			showMaskOnHover: false //oculta la mascara en hover
		}

		const inputMaskRut = new Inputmask("inputrut", maskConfigRut)
		inputMaskRut.mask(document.getElementById("rut"))
	}

	useEffect(() => {
		setInputMask()
	}, [])

	const rutChange = () => e => {
		setRut(e.target.value)
	}

	const [pass, setPass] = useState("")

	const passChange = () => e => {
		setPass(e.target.value)
	}

	const submitHandle = async e => {
		setLoading(true)
		e.preventDefault()
		const response = await loginRut({ rut, pass })
		if (response.data.user) {
			if (response.data.postulante_exists) {
				await saveInitialDataFromLogin(response.data.data_form)
			}
			await setUserToken(response.data.user.access_token)
			await updateUserWithStep(response.data.user)
			callOptions()
			return (window.location = "/")
		} else {
			showToast("Error al intentar ingresar." + response.data.message)
		}
		setLoading(false)
	}

	useEffect(() => {})

	return (
		<ContainerAuth onSubmit={submitHandle} title="Iniciar sesión">
			<div className="form-group">
				<label className="text_label" htmlFor="rut">
					Rut
				</label>
				<input
					id="rut"
					className="browser-default "
					placeholder="11.111.111"
					required="required"
					autoComplete="off"
					type="text"
					onChange={rutChange()}
				/>
			</div>

			<div className="form-group">
				<label className="text_label" htmlFor="rut">
					Contraseña
				</label>
				<input
					id="password"
					type="password"
					className="browser-default "
					required="required"
					autoComplete="off"
					onChange={passChange()}
				/>
			</div>

			<div className="form-group center-align mt-5">
				<button className="contact-btn" type="submit">
					INGRESAR
				</button>
			</div>
			<div className="form-group center-align">
				<span onClick={() => props.history.push("/register")} className="a-link link">
					¿No tienes cuenta aún? Regístrate aquí
				</span>
				<Loader loader={loading} />
			</div>
		</ContainerAuth>
	)
}

export default withRouter(Login)
