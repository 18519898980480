import React from "react"
import {withRouter} from "react-router-dom"
import "./styles.scss"
const ContainerAuth = props => {
	return (
		<div className="container container_form" >
			<form onSubmit={props.onSubmit}>
				<div className="row">
					<div className="col m12 l6 offset-l3 card px-5 pb-4">
						<i
							className="material-icons  purple-text darken-2 exit_app"
							onClick={() => props.history.goBack()}
						>
							exit_to_app
						</i>

						<div className="form-group center-align mb-5">
							<h2>{props.title}</h2>
						</div>
						{props.children}
					</div>
				</div>
			</form>
		</div>
	)
}

export default withRouter(ContainerAuth)
