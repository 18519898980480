import React, { useState } from "react"
import { useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import schema from "./schema"
import { sendDataStep2 } from "./../../../../services/stepForm.service"
import showToast from "../../../Toast/Toast"

import SectionPersonal from "./SectionPersonal"
import SectionExtranjera from "./SectionExtranjera"
import SectionAcademica from "./SectionAcademica"

import { useUser } from "../../../../context/UserProvider"
import { useDataForm } from "../../../../context/FormProvider"

/* Las uso para calcular rangos de edad en el datepicker */
/* Cambiar segun corresponda, le puse al ojo mientras */
const CURRENT_YEAR = new Date().getFullYear()
const MIN_AGE_POSTULANT = 16
const MAX_AGE_POSTULANT = 80
const MAX_YEAR = CURRENT_YEAR - MIN_AGE_POSTULANT
const MIN_YEAR = CURRENT_YEAR - MAX_AGE_POSTULANT

const Step2 = props => {
	const [isNacional, setIsNacional] = useState(true)
	const [disableBtn, setDisableBtn] = useState(false)
	const { register, handleSubmit, errors } = useForm({
		resolver: yupResolver(schema(MIN_YEAR, MAX_YEAR))
	})
	const { setLoader } = props
	const { user, updateUserWithStep, nextStep ,optionsSelect} = useUser()
	const {
		dataPostulante,
		dataAcademica,
		dataExtranjera,
		setDataPostulante,
		updatePostulante,
		updateAcademica,
		updateExtranjera,
		formHasChanges
	} = useDataForm()

	
	/* Recibe los datos del form para enviarlos a API */
	const onSubmitStep2 = async data => {
		setLoader(true)
		setDisableBtn(true)
		const noChanges = await !formHasChanges(data, 2)

		/* checkeo si no ha realizado cambios pasa directo a la otra etapa(2,3) */
		if (noChanges) {
			nextStep()
			setLoader(false)
			return
		}

		const _data = { user: user, data: data, etapa: 2 }
		const responseFromApi = await sendDataStep2(_data)

		if (responseFromApi?.response === "success") {
			updatePostulante(responseFromApi.data.postulante)
			updateAcademica(responseFromApi.data.informacion_academica)
			updateExtranjera(responseFromApi.data.informacion_extranjera)
			updateUserWithStep(responseFromApi.user)
		} else {
			showToast("Se ha producido un problema al enviar formulario")
		}
		setLoader(false)
		setDisableBtn(false)
	}

	return (
		<form onSubmit={handleSubmit(onSubmitStep2)} className="custom-form">
			<SectionPersonal
				title="Personal"
				dataPostulante={dataPostulante}
				setDataPostulante={setDataPostulante}
				user={user}
				setIsNacional={setIsNacional}
				maxYear={MAX_YEAR}
				minYear={MIN_YEAR}
				register={register}
				errors={errors}
				optionsSelect={optionsSelect}
			/>

			<SectionExtranjera
				title="Información Extranjero"
				dataExtranjera={dataExtranjera}
				isNacional={isNacional}
				register={register}
				errors={errors}
				optionsSelect={optionsSelect}
			/>

			<SectionAcademica
				title="Información Académica"
				dataPostulante={dataPostulante}
				dataAcademica={dataAcademica}
				register={register}
				errors={errors}
			/>

			<div className="step-buttons">
				<button
					type="submit"
					disabled={disableBtn}
					className="waves-effect waves-light purple darken-1 btn-small right"
				>
					Siguiente
					<i className="large material-icons right">navigate_next</i>
				</button>
			</div>
		</form>
	)
}

export default Step2
