import React, { Fragment, useEffect } from "react"
import { withRouter } from "react-router-dom"
import "./User.scss"

import StepList from "../../components/UserForm/StepList"
import Step0 from "../../components/UserForm/steps/Step0"
import Step10 from "../../components/UserForm/steps/Step10"
import UserForm from "../../components/UserForm/UserForm"
import { useDataForm } from "../../context/FormProvider"

import { useUser } from "./../../context/UserProvider"
import HeaderAlumno from "../../components/HeaderAlumno/HeaderAlumno"

const User = () => {
	const { user, currentStep } = useUser()
	const { loadDataLocalStorage, dataPostulante } = useDataForm()

	useEffect(() => {
		loadDataLocalStorage()
	}, []) // eslint-disable-line react-hooks/exhaustive-deps

	const userStep10 = () => {
		//TODO: implementar validacion
		return user?.etapa === 10 && dataPostulante
	}

	if (userStep10()) {
		return (
			<Fragment>
				<HeaderAlumno />
				<Step10 />
			</Fragment>
		)
	} else if (currentStep === 0) {
		return <Step0 />
	} else {
		return (
			<Fragment>
				<HeaderAlumno />
				<div className="container z-depth-2 mt-3" id="user-container">
					<div className="row">
						<div className="col s4">
							<StepList />
						</div>

						<div className="col s8" id="form-container">
							<UserForm />
						</div>
					</div>
				</div>
			</Fragment>
		)
	}
}

export default withRouter(User)
