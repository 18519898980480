import React, {Fragment, useEffect, useState} from "react"
import showToast from "../../Toast/Toast";
import {cambiarEstadoEspecial, getInfoCarrera, getDetalleById} from "../../../services/admin.service";
import Loader from "../../Loader/Loader";
import M from "materialize-css";

const ModalCambioEstado = props => {

    const { id_fila, carga, refresh } = props

    const [mensaje, setMensaje] = useState(null)
    const [loader,  setLoader]  = useState(false)

    const [infoCarrera, setInfoCarrera] = useState(null)
    const [fila, setFila] = useState(null)

    const [estado, setEstado] = useState("INTERESA")

    const fetchData = async () => {
        if(id_fila!==null){
            setLoader(true)
            const data = {
                id: id_fila
            }
            const res = await getDetalleById(data)
            if (res.status === 200) {
                const data2 = {
                    carrera: res.data.cod_carrera,
                    sede: res.data.sede
                }
                const res2 = await getInfoCarrera(data2)
                if (res2.status === 200) {
                    setFila(res.data)
                    setInfoCarrera(res2.data)
                } else {
                    showToast("No se cargó la información, intente de nuevo")
                }
            } else {
                showToast("No se cargó la información, intente de nuevo")
            }

            var elems = document.querySelectorAll('select');
            var instances = M.FormSelect.init(elems);
            setLoader(false)
        }
    }


    useEffect(()=>{
        console.log(id_fila)
        if(id_fila!==null){
            fetchData()
        }
    }, [id_fila])


    const fillMensaje = () => e => {
        setMensaje(e.target.value)
    }

    const cambiaEstado = () => e => {
        setEstado(e.target.value)
    }

    const procesarMensaje = async () => {
        setLoader(true)

        let params = {}
        let result
        params = {
            fila: fila,
            mensaje: mensaje,
            estado: estado
        }
        result = await cambiarEstadoEspecial(params)
        if(result?.status === 200){
            showToast("Mensaje Enviado exitosamente")
        }
        else{
            showToast(result?.message || "Error enviando el Mensaje, probablemente en el servidor de correos")
        }
        setLoader(false)
        refresh()
        const btn = document.getElementById('modal-m-close')
        btn.click()
        /*
        const modal = document.getElementById('modal-revisar')
        const instance = M.Modal.init(modal)
        instance.close()*/
    }


    return (
            <div className="modal" id="modal-cambio-estado">
                <div className="modal-content">
                    <h4>Detalle de Postulación</h4>
                    A continuación se presenta el detalle de la postulación
                    <br/><br/>
                    <Loader loader={loader} />
                    <div className="row">
                        <div className="col s12">
                            <div className="row">
                                <h2 className="subtitle"><span className="blue-grey-text">Postulante</span></h2>
                                <table>
                                    <tr>
                                        <td>Nombre</td>
                                        <td>{ (fila!==null)? fila.nombres + " " + fila.paterno + " " + fila.materno : null }</td>
                                    </tr>
                                    <tr>
                                        <td>Rut</td>
                                        <td>{ (fila!==null)? fila.rut + "-" + fila.dv : null }</td>
                                    </tr>
                                    <tr>
                                        <td>Fecha de Nacimiento</td>
                                        <td>{ (fila!==null)? fila.nacimiento : null  }</td>
                                    </tr>
                                </table>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col s12">
                            <h2 className="subtitle"><span className="blue-grey-text">Puntaje</span></h2>
                            <table>
                                <thead>
                                <tr>
                                    <th>Promedio EM</th>
                                    <th>NEM</th>
                                    <th>Lenguaje</th>
                                    <th>Matemática</th>
                                    <th>Historia</th>
                                    <th>Ciencias</th>
                                    <th>Ranking</th>
                                    <th>Promedio MLM</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr>
                                    <td>{ (fila!==null)? fila.promedio_notas : null }</td>
                                    <td>{ (fila!==null)? fila.ptje_nem : null }</td>
                                    <td>{ (fila!==null)? fila.leng_actual : null }</td>
                                    <td>{ (fila!==null)? fila.mate_actual : null }</td>
                                    <td>{ (fila!==null)? fila.hcso_actual : null }</td>
                                    <td>{ (fila!==null)? fila.cien_actual : null }</td>
                                    <td>{ (fila!==null)? fila.ptje_ranking : null }</td>
                                    <td>{ (fila!==null)? fila.promlm : null }</td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col s12 m12 l12">
                            <h2 className="subtitle"><span className="blue-grey-text">Cambio de Estado</span></h2>
                        </div>
                        <table>
                            <thead>
                                <tr>
                                    <th>Carrera</th>
                                    <th>Sede</th>
                                    <th>Cupo</th>
                                    <th>Matriculados</th>
                                    <th>Estado</th>
                                </tr>
                            </thead>
                            <tbody>
                            {(fila!==null)? <tr>
                                    <td>{ (infoCarrera!==null)? infoCarrera.codigo + " - " + infoCarrera.nombre : null }</td>
                                    <td>{ (infoCarrera!==null)? infoCarrera.sede : null }</td>
                                    <td>{ (infoCarrera!==null)? infoCarrera.cupo : null }</td>
                                    <td>{ (infoCarrera!==null)? infoCarrera.matriculados : null }</td>
                                    <td>
                                        { (fila.estado==="INTERESA")?
                                        <div className="input-field col s12">
                                                <select defaultValue={fila.estado} onChange={ cambiaEstado() }>
                                                    <option value="" disabled>Seleccione un Estado</option>
                                                    <option value="INTERESA">Interesado</option>
                                                    <option value="ACEPTADO">Aceptado</option>
                                                </select>
                                            <label></label>
                                        </div> : "ACEPTADO"
                                            }
                                    </td>
                                </tr> : null }
                            </tbody>
                        </table>
                    </div>
                    <div className="row">
                        <div className="col s12 m12 l12">
                            <h2 className="subtitle"><span className="blue-grey-text">Observaciones</span></h2>
                            <p>En esta sección usted podrá añadir algún mensaje adicional al correo que recibirá la/el postulante</p>
                        </div>
                        <div className="input-field col s12">
                            <textarea id="textarea-mensaje" className="materialize-textarea" onKeyUp={ fillMensaje() }></textarea>
                            <label htmlFor="textarea-mensaje">Observaciones</label>
                        </div>
                    </div>
                </div>
                <div className="modal-footer">
                    <a href="#" id="modal-m-close" className="modal-close waves-effect waves-green btn-flat">Cerrar</a>
                    { (fila!==null && fila.estado==="INTERESA")?
                        <a href="#" className="waves-effect waves-green btn green"
                           onClick={() => procesarMensaje()}>Procesar</a>
                        : null
                    }
                </div>
            </div>
    )

}

export default ModalCambioEstado