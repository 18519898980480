import React, {Fragment, useEffect, useState} from "react"
import showToast from "../../Toast/Toast";
import {setCambioEstado, setMensajePostulacion} from "../../../services/admin.service";
import Loader from "../../Loader/Loader";

const ModalMensaje = props => {

    const { postulacion, refresh } = props

    const [mensaje, setMensaje] = useState(null)
    const [tipo,    setTipo]    = useState()
    const [loader,  setLoader]  = useState(false)

    useEffect(()=>{
    }, [])


    const fillMensaje = () => e => {
        setMensaje(e.target.value)
    }

    const procesarMensaje = async () => {
        if(mensaje===null){
            showToast("Debe escribir un mensaje")
            return null
        }
        if(tipo===null){
            showToast("Debe seleccionar una opción")
            return null
        }
        setLoader(true)

        let params = {}
        let result
        if(tipo==="subir") {//Sólo envía Mensaje
            params = {
                postulacion: postulacion,
                observacion: mensaje
            }
            result = await setMensajePostulacion(params)
        }
        if(tipo==="actualizar") {
            params = {
                postulacion: postulacion,
                estado: 'INICIO',
                observacion: mensaje,
                carreras: []
            }
            result = await setCambioEstado(params)
        }
        if(result?.status === 200){
            showToast(result.data)
        }
        else{
            showToast("Se ha producido un error")
        }
        setLoader(false)
        refresh()
        const btn = document.getElementById('modal-m-close')
        btn.click()
        /*
        const modal = document.getElementById('modal-revisar')
        const instance = M.Modal.init(modal)
        instance.close()*/
    }

    return (
        <Fragment>
            <div className="modal" id="modal-mensaje">
                <div className="modal-content">
                    <h4>Enviar un Mensaje</h4>
                    Envíe un mensaje al postulante para que pueda actualizar los documentos o subir archivos
                    <br/><br/>
                    <div className="row">
                        <div className="col s12 m12 l12">
                            <h2 className="subtitle"><span className="blue-grey-text">Opción</span></h2>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col s12">
                            <div className="row">
                                <div className="input-field col s6">
                                    <p>
                                    <label>
                                        <input name="groupX" type="radio" value={"actualizar"} onClick={() => setTipo("actualizar") }/>
                                        <span>Actualizar datos</span>
                                    </label>
                                    </p>
                                    <p>
                                    <label>
                                        <input name="groupX" type="radio" value={"subir"} onClick={() => setTipo("subir") }/>
                                        <span>Mensaje Informativo</span>
                                    </label>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col s12 m12 l12">
                            <h2 className="subtitle"><span className="blue-grey-text">Mensaje</span></h2>
                        </div>
                    </div>
                    <div className="row">
                        <form>
                        <div className="col s12">
                            <div className="row">
                                <div className="input-field col s12">
                                    <textarea id="textarea-mensaje" className="materialize-textarea" onKeyUp={ fillMensaje() }></textarea>
                                    <label htmlFor="textarea-mensaje">Mensaje</label>
                                </div>
                            </div>
                        </div>
                        </form>
                    </div>
                    <Loader loader={loader} />
                </div>
                <div className="modal-footer">
                    <a href="#" id="modal-m-close" className="modal-close waves-effect waves-green btn-flat">Cerrar</a>
                    <a href="#" className="waves-effect waves-green btn green" onClick={()=>procesarMensaje() }>Procesar</a>
                </div>
            </div>
        </Fragment>
    )

}

export default ModalMensaje