import React, {Fragment, useEffect, useState} from "react"
import showToast from "../../Toast/Toast";
import {setCambioEstado} from "../../../services/admin.service";
import Loader from "../../Loader/Loader";

const ModalRechazar = props => {

    const { postulacion, refresh } = props

    const [mensaje, setMensaje] = useState(null)
    const [loader,  setLoader]  = useState(false)

    useEffect(()=>{
    }, [])


    const fillMensaje = () => e => {
        setMensaje(e.target.value)
    }

    const procesarMensaje = async () => {
        if(mensaje===null){
            showToast("Debe escribir un mensaje")
            return null
        }
        setLoader(true)
        let params = {}
        let result

            params = {
                postulacion: postulacion,
                estado: 'RECHAZADO',
                observacion: mensaje,
                carreras: []
            }
            result = await setCambioEstado(params)

        if(result?.status === 200){
            showToast(result.data)
        }
        else{
            showToast("Se ha producido un error")
        }
        setLoader(false)
        refresh()
        const btn = document.getElementById('modal-r-close')
        btn.click()
        /*
        const modal = document.getElementById('modal-revisar')
        const instance = M.Modal.init(modal)
        instance.close()*/
    }

    return (
        <Fragment>
            <div className="modal" id="modal-rechazo">
                <div className="modal-content">
                    <h4>Rechazar Postulación</h4>
                    Rechaze la postulación, esto no tiene derecho a cambios, y además envíe un mensaje al postulante comunicándole la desición
                    <br/><br/>
                    <div className="row">
                        <div className="col s12 m12 l12">
                            <h2 className="subtitle"><span className="blue-grey-text">Mensaje</span></h2>
                        </div>
                    </div>
                    <div className="row">
                        <form>
                            <div className="col s12">
                                <div className="row">
                                    <div className="input-field col s12">
                                        <textarea id="textarea-mensaje" className="materialize-textarea" onKeyUp={ fillMensaje() }></textarea>
                                        <label htmlFor="textarea-mensaje">Mensaje</label>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                    <Loader loader={loader} />
                </div>
                <div className="modal-footer">
                    <a href="#" id="modal-r-close" className="modal-close waves-effect waves-green btn-flat">Cerrar</a>
                    <a href="#" className="waves-effect waves-green btn green" onClick={()=>procesarMensaje() }>Procesar</a>
                </div>
            </div>
        </Fragment>
    )

}

export default ModalRechazar