import React, { useEffect } from "react"
import {withRouter} from "react-router-dom"
import Header from './../../components/Header/Header'
import './css/card.css'
import {useAdmin} from "../../context/AdminProvider";

const MenuPrincipal = props => {
	const { history } = props

	const { admin } = useAdmin()

	useEffect(() => {
		const fetchData = async () => {
			if (admin === null) {
				history.push("/admin/login")
			}
		}

		//setData(dataPostulaciones)

		fetchData()
	}, [admin]) // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<div>
			<Header />
			<div className="row container title-scope">
				<div className="title col s12 l12 left">
					<h6>Admisión en Línea</h6>
					<h2>Menú</h2>
				</div>
			</div>
			<div className="row container title-scope margin-bottom-24px">
				<div className="col s12 m12 l8 note text-align-justify">
					A continuación se muestra el menú del área de adiministración.
				</div>
			</div>
			<div className="container content margin-top-48px">
				<div className="row" style={{ marginTop: 64 + "px" }}>
					<div className="col s12 m4 l4">
						<div className="card">
							<div className="card-content">
								<p>Proceso</p>
								<span className="card-title text-ellipsis theme-text">Postulaciones</span>
								<center>
									<i className="large material-icons blue-text">insert_drive_file</i>
								</center>
								<span className="card-content-description">
									Administración de los procesos de postulaciones.
								</span>
							</div>
							<div className="card-action">
								<a
									href="/admin/postulaciones"
									className="btn progress-trigger theme-button-gradient-45deg"
								>
									Ir
								</a>
							</div>
						</div>
					</div>

					<div className="col s12 m4 l4">
						<div className="card">
							<div className="card-content">
								{/* TODO: cambiar nombre y descripciones */}
								<p>Proceso</p>
								<span className="card-title text-ellipsis theme-text">Ingresos Especiales</span>
								<center>
									<i className="large material-icons blue-text">upload_file</i>
								</center>
								<span className="card-content-description">
									Carga, Envío de Correos, Revisión de datos
								</span>
							</div>
							<div className="card-action">
								<a
									href="/admin/ingresos_especiales"
									className="btn progress-trigger theme-button-gradient-45deg"
								>
									Ir
								</a>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

export default withRouter(MenuPrincipal)