import React from "react"
import { DragDropContext } from "react-beautiful-dnd"
import CareerListSelected from "./CareerListSelected"
import "./styles.scss"
const CareerList = props => {
	const { carrerasFiltradas, emptyCarreras, selectedCarreras, setSelectedCarreras, color } = props
	const selectedIds = selectedCarreras.map(x => x.id)

	/* Evento al seleccionar alguna carrera */
	const handleClickCheckbox = car => e => {
		const itemList = e.target.parentElement.parentElement
		const selected = e.target.checked
		let arrayCarreras = []
		if(selected){
			arrayCarreras = [...selectedCarreras, car]
			itemList.classList.add('carrera-selected')
		}else{
			arrayCarreras = selectedCarreras.filter(x => x.codigo_carrera !== car.codigo_carrera)
			itemList.classList.remove('carrera-selected')
		}
		setSelectedCarreras(arrayCarreras)
	}

	/* Evento al finalizar el Drag, reordena el array */
	const onDragEnd = result => {
		const { destination, source, draggableId } = result

		if (!destination) return

		if (destination.droppableId === source.droppableId && destination.index === source.index)
			return

		const newSelectedCarreras = [...selectedCarreras]

		newSelectedCarreras.splice(source.index, 1)
		newSelectedCarreras.splice(
			destination.index,
			0,
			selectedCarreras.find(x => x.codigo_carrera === draggableId)
		)

		setSelectedCarreras(newSelectedCarreras)
	}

	if (carrerasFiltradas && carrerasFiltradas.length > 0) {
		return (
			<div className="row mx-2">
				<div className="col s6">
					<ul className={`collection with-header custom-carreras-1`}>
						<li className="collection-header">
							<h6>Carreras Disponibles</h6>
						</li>
						{carrerasFiltradas.map((car, index) => (
							<li
								key={index}
								className={`collection-item ${
									selectedIds.includes(car.id) ? "carrera-selected" : ""
								}`}
							>
								<label>
									<input
										type="checkbox"
										id={`idLiCar${index}`}
										checked={selectedIds.includes(car.id)}
										onClick={handleClickCheckbox(car)}
										onChange={e => console.log(e.target.value)}
									/>
									<span htmlFor={`idLiCar${index}`}>
										{car.nombre} - ({car.jornada})
									</span>
								</label>
							</li>
						))}
					</ul>
				</div>
				<div className="col s6">
					<DragDropContext onDragEnd={onDragEnd}>
						<CareerListSelected selectedCarreras={selectedCarreras}/>
					</DragDropContext>
				</div>
			</div>
		)
	} else {
		return emptyCarreras ? "No se encontraron carreras para la selección realizada" : null
	}
}

export default CareerList
