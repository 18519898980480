import React, { Fragment, useEffect } from "react"
import Inputmask from "inputmask"

const Datepicker = ({ register, minYear = 1960, maxYear = 2030, errors, label, ...props }) => {

	const setInputMasks = () => {
		const maskConfig = {
			clearIncomplete: true,
			placeholder: "dd/mm/aaaa",
			inputFormat: "dd/mm/yyyy",
			min: `01/01/${minYear}`,
			max: `31/12/${maxYear}`
			// defaultValue: defaultValue
		}
		const inputMask = new Inputmask("datetime", maskConfig)
		inputMask.mask(document.getElementsByName(props.name)[0])
	}

	/* por alguna razon el label se sobrepone al dato que viene por defecto */
	const setActiveLabel = () => {
		if (props.defaultValue !== "") {
			document.getElementById(props.id + "_label").classList.add("active")
		}
	}

	useEffect(() => {
		setInputMasks()
	}, [])

	useEffect(() => {
		setActiveLabel()
	})

	let errorMsg = errors?.type === "required" ? "El campo es requerido" : errors?.message

	return (
		<Fragment>
			<input
				{...props}
				type="text"
				ref={register}
				className="date-mask"
				autoComplete="off"
			></input>
			<label id={props.id + "_label"} htmlFor={props.id}>
				{label}
			</label>
			{errors && (
				<span className="helper-text" data-error="wrong" data-success="right">
					{errorMsg}
				</span>
			)}
		</Fragment>
	)
}

export default Datepicker
