import React, { Fragment } from "react"
import TitleForm from "../TitleForm/TitleForm"

const Archivos = ({ dataArchivos }) => {
	const rowsArchivos = () => {
		if (Object.keys(dataArchivos).length > 0) {
			return dataArchivos.map((file, index) => (
				<tr key={index}>
					<td>{file.nombre}</td>
					<td>
						<a
							//CAMBIAR HREF
							href={`${file.pivot.path}`}
							target="_blank"
							rel="noopener noreferrer"
						>
							{file.pivot.nombre}
						</a>
					</td>
				</tr>
			))
		} else {
			return (
				<tr>
					<td>No ha subido ningún archivo</td>
				</tr>
			)
		}
	}

	return (
		<Fragment>
			<div className="row mb-0 pt-3">
				<div className="col s12 m12 l12">
					<TitleForm light={true}>Archivos Adjuntos</TitleForm>
				</div>
			</div>

			<div className="row">
				<div className="col s12 m12 l12 px-5">
					<table className="table tabla-resumen">
						<tbody>{rowsArchivos()}</tbody>
					</table>
				</div>
			</div>
		</Fragment>
	)
}

export default Archivos
