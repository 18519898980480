import React, { useState } from "react"
import { withRouter } from "react-router-dom"
import showToast from "../../components/Toast/Toast"
import {loginLdap,} from "../../services/auth.service"
import { useEffect } from "react"
import Loader from "../../components/Loader/Loader"
import { useAdmin } from "../../context/AdminProvider"
import ContainerAuth from "../../components/ContainerAuth/ContainerAuth"

const LoginPage = props => {
	const { history } = props
	const { admin, updateAdmin } = useAdmin()
	const [loading, setLoading] = useState(false)

	if (admin !== null) history.push("/admin")

	const [mail, setMail] = useState("")

	const emailChange = () => e => {
		setMail(e.target.value)
	}

	const [pass, setPass] = useState("")

	const passChange = () => e => {
		setPass(e.target.value)
	}

	const submitHandle = async e => {
		setLoading(true)
		e.preventDefault()
		const val = await loginLdap({ mail, pass })
		if (val?.status === 200) {
			await updateAdmin(val.data)
			return history.push("/admin")
		} else {
			showToast("Se produjo un error al iniciar sesión")
		}
		setLoading(false)
	}

	useEffect(() => {
		if (admin) {
			//console.log("Administrador logueado..directo al form")
			history.push("/admin")
		}
	})

	return (
		<ContainerAuth onSubmit={submitHandle} title="Inicio de Sesión">
			<div className="form-group">
				<label className="text_label" htmlFor="email">
					Correo
				</label>
				<input
					id="email"
					className="browser-default "
					required="required"
					type="email"
					onChange={emailChange()}
				/>
			</div>

			<div className="form-group">
				<label className="text_label" htmlFor="password">
					Contraseña
				</label>
				<input
					id="password"
					className="browser-default "
					required="required"
					type="password"
					onChange={passChange()}
				/>
			</div>

			<div className="form-group center-align mt-5">
				<button className="contact-btn" type="submit">
					INGRESAR
				</button>
			</div>
			<Loader loader={loading} />
		</ContainerAuth>
	)
}

export default withRouter(LoginPage)
