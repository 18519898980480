    import React, {Fragment, useEffect, useState} from "react"
    import showToast from "../../Toast/Toast";
    import {setCambioEstado, getInfoCarreraDelfos} from "../../../services/admin.service";
    import Loader from "../../Loader/Loader";

    const ModalRevisar = props => {

        const { postulacion, carreras, rut, periodo, refresh } = props

        const [tipo, setTipo] = useState(null)
        const [observacion, setObservacion] = useState()
        const [loader, setLoader] = useState(false)

        const [mensaje, setMensaje] = useState("")

        const [enable, setEnable] = useState(true)

        const loadInfoDelfos = async () => {
            let car = null
            carreras.map((carrera) => {
                console.log(carrera)
                if(carrera.value==="ACEPTADO") car = carrera
            })
            console.log(postulacion)
            if(car!==null){
                let params = {
                    rut: rut,
                    periodo: periodo,
                    carrera: car.codigo,
                }
                const result = await getInfoCarreraDelfos(params)
                if(result?.status === 200){
                    console.log(result.data)
                    if(result.data===null){
                        setMensaje(<b>Postulante no presenta estado interesado para carrera seleccionada, o bien, selección de Postulación se efectuó desde eDelfos</b>)
                        setEnable(false)
                        return null
                    }
                    if(result.data.caac_cod===null && result.data.sucu_cod===null && result.data.caal_ano_ingreso===null){
                        setMensaje(<b>Alumno puede continuar el proceso</b>)
                        setEnable(true)
                        return null
                    }
                    if(result.data.sucu_cod!=car.sede || result.data.caal_ano_ingreso != periodo.toString()){
                        setMensaje(<b>Campus o año de admisión de postulante son distintos a los registros de carreras actuales en eDelfos</b>)
                        setEnable(false)
                        return null
                    }
                }
                else{
                    showToast("Se ha producido un error")
                    return null
                }
            }else{
                setMensaje(<b>Sin Información</b>)
                return null
            }
            return null
        }

        useEffect(()=>{
            let flag = "L_ESPERA"
            carreras.map((carrera) => {
                if(carrera.value==="ACEPTADO") flag = "ACEPTADO"
            })
            setTipo(flag)
            loadInfoDelfos()
        }, [carreras])


        const fillObservacion = () => e => {
            setObservacion(e.target.value)
        }

        const procesarAceptacion = async () => {
            if(tipo===null){
                showToast("Debe seleccionar un estado para la Postulación")
                return null
            }
            if(!enable){
                showToast("Revisar Registros en eDelfos, no se puede continuar con el proceso")
                return null
            }
            setLoader(true)
            let params = {
                    carreras: carreras,
                    postulacion: postulacion,
                    estado: tipo,
                    observacion: observacion
                }
            const result = await setCambioEstado(params)
            if(result?.status === 200){
                showToast(result.data)
            }
            else{
                showToast("Se ha producido un error")

            }
            setLoader(false)
            refresh()
            const btn = document.getElementById('modal-close')
            btn.click()
            /*
            const modal = document.getElementById('modal-revisar')
            const instance = M.Modal.init(modal)
            instance.close()*/
        }

        return (
            <Fragment>
                <div className="modal" id="modal-revisar">
                    <div className="modal-content">
                        <h4>Revisar Postulación </h4>
                        Este es el resumen de su postulación
                        <br/><br/>
                        <div className="row">
                            <div className="col s12 m12 l12">
                                <h2 className="subtitle"><span className="blue-grey-text">Estado de Carreras</span></h2>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col s12">
                                <table>
                                    <thead>
                                        <tr>
                                            <th>Código</th>
                                            <th>Carrera</th>
                                            <th>Acción</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            carreras.map((carrera) => {
                                                return <tr key={carrera.id}>
                                                    <td>{carrera.codigo}</td>
                                                    <td>{carrera.name}</td>
                                                    <td>{(carrera.value==="ACEPTADO")? "ACEPTADO" : "LISTA ESPERA"}</td>
                                                </tr>
                                            })
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col s12 m12 l12">
                                <h2 className="subtitle"><span className="blue-grey-text">Estado Situación eDelfos</span></h2>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col s12 m12 l12">
                                { mensaje }
                            </div>
                        </div>
                        <div className="row">
                            <div className="col s12 m12 l12">
                                <h2 className="subtitle"><span className="blue-grey-text">Observaciones de la Postulación</span></h2>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col s12">
                                <div className="row">
                                    <div className="input-field col s12">
                                        <textarea id="textarea1" className="materialize-textarea" onKeyUp={fillObservacion()}></textarea>
                                        <label htmlFor="textarea1">Observaciones</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <Loader loader={loader} />
                    </div>
                    <div className="modal-footer">
                        <a href="#" id="modal-close" className="modal-close waves-effect waves-green btn-flat">Cerrar</a>
                        <a href="#" className="waves-effect waves-green btn green" onClick={()=>procesarAceptacion() }>Procesar</a>
                    </div>
                </div>
            </Fragment>
        )

    }

    export default ModalRevisar