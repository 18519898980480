import axios from "axios"
import { authAdminHeader, authHeader } from "./auth-header"
import showToast from "../components/Toast/Toast"
import { API_URL } from "../config"

const API = API_URL() + "api/"
const config = {
	headers: authAdminHeader() || authHeader()
}

const axiosInstance = axios.create()
axiosInstance.interceptors.response.use(
	response => {
		return response
	},
	error => {
		if (error.response.status === 401) {
			showToast("Tu sesión ha expirado")
			localStorage.removeItem("admin")
			setTimeout(() => {
				window.location = "/"
			}, 1000)
			// window.location = "/"
		} else {
			console.log(error)
		}
	}
)

const getInfoUsuarios = () => {
	const URL = API + "admin/get_info_usuarios"
	return axiosInstance
		.get(URL, config)
		.then(response => response)
		.catch(error => error)
}

const getPostulacionesPorSede = () => {
	const URL = API + `admin/get_postulaciones_sedes`
	return axiosInstance
		.get(URL, config)
		.then(response => response)
		.catch(error => error)
}

const getPostulacionesLEPorCarrera = id => {
	const URL = API + `admin/get_postulaciones_le_carrera?carrera=${id}`
	return axiosInstance
		.get(URL, config)
		.then(response => response)
		.catch(error => error)
}

const getAllPostulacionesPorSede = () => {
	const URL = API + `admin/get_all_postulaciones_sedes`
	return axiosInstance
		.get(URL, config)
		.then(response => response)
		.catch(error => error)
}

const getCarrerasLESede = () => {
	const URL = API + `admin/get_carreras_le_sedes`
	return axiosInstance
		.get(URL, config)
		.then(response => response)
		.catch(error => error)
}

const getCarrerasSede = () => {
	const URL = API + `admin/get_carreras_sedes`
	return axiosInstance
		.get(URL, config)
		.then(response => response)
		.catch(error => error)
}

const getListaEsperaPorSede = () => {
	const URL = API + `admin/get_lista_espera_sedes`
	return axiosInstance
		.get(URL, config)
		.then(response => response)
		.catch(error => error)
}

const getPostulacion = id => {
	const URL = API + `admin/get_postulacion?id=${id}`

	return axiosInstance
		.get(URL, config)
		.then(response => response)
		.catch(error => error)
}

const actualizarSede = (sede, usuario) => {
	const URL = API + `admin/actualizar_sede_usuario?sede=${sede}&usuario=${usuario}`
	return axiosInstance
		.get(URL, config)
		.then(response => response)
		.catch(error => error)
}

const getPeriodos = () => {
	const URL = API + "admin/get_periodos"
	return axiosInstance
		.get(URL, config)
		.then(response => response)
		.catch(error => error)
}

const setPeriodos = params => {
	const URL = API + "admin/set_periodos"
	return axiosInstance
		.post(URL, params, config)
		.then(response => response)
		.catch(error => error)
}

const getTipoPostulacion = () => {
	const URL = API + "admin/get_tipo_postulacion"
	return axiosInstance
		.get(URL, config)
		.then(response => response)
		.catch(error => error)
}

const getArchivos = () => {
	const URL = API + "admin/get_archivos"

	return axiosInstance
		.get(URL, config)
		.then(response => response)
		.catch(error => error)
}

const getInfoCarreraDelfos = params => {
	const URL = API + "admin/get_info_carreras_delfos"
	return axiosInstance
		.post(URL, params, config)
		.then(response => response)
		.catch(error => error)
}

const getSedes = () => {
	return [
		{
			id: "05029",
			nombre: "Ancud"
		},
		{
			id: "05041",
			nombre: "Castro"
		},
		{
			id: "05028",
			nombre: "Puerto Montt"
		},
		{
			id: "05027",
			nombre: "Osorno"
		},
		{
			id: "05040",
			nombre: "Santiago"
		}
	]
}

const getRoles = () => {
	return [
		{
			id: "Administrador",
			rol: "Administrador"
		},
		{
			id: "Revisor",
			rol: "Revisor"
		}
	]
}

const setSedeUsuario = params => {
	const URL = API + "admin/set_sede_usuario"

	return axiosInstance
		.post(URL, params, config)
		.then(response => response)
		.catch(error => error)
}

const setRolUsuario = params => {
	const URL = API + "admin/set_rol_usuario"

	return axiosInstance
		.post(URL, params, config)
		.then(response => response)
		.catch(error => error)
}

const refreshUser = () => {
	const URL = API + "admin/get_admin_user"

	return axiosInstance
		.get(URL, config)
		.then(response => response)
		.catch(error => error)
}

const setCambioEstado = params => {
	const URL = API + "admin/set_cambio_estado"
	return axiosInstance
		.post(URL, params, config)
		.then(response => response)
		.catch(error => error)
}

const setMensajePostulacion = params => {
	const URL = API + "admin/set_mensaje_postulacion"
	return axiosInstance
		.post(URL, params, config)
		.then(response => response)
		.catch(error => error)
}

const isSessionOpen = () => {
	const URL = API + "admin/is_session_open"
	return axiosInstance
		.get(URL, config)
		.then(response => {
			return true
		})
		.catch(error => {
			return false
		})
}

const getGenero = id => {
	const URL = API + `postulante/generos?id=${id}`
	return axiosInstance
		.get(URL, config)
		.then(({ data }) => data)
		.catch(err => console.log(err))
}

const getRegion = id => {
	const URL = API + `postulante/regiones?id=${id}`
	return axiosInstance
		.get(URL, config)
		.then(({ data }) => data)
		.catch(err => console.log(err))
}

const getCiudad = id => {
	const URL = API + `postulante/ciudades?id=${id}`
	return axiosInstance
		.get(URL, config)
		.then(({ data }) => data)
		.catch(err => console.log(err))
}

const getComuna = id => {
	const URL = API + `postulante/comunas?id=${id}`
	return axiosInstance
		.get(URL, config)
		.then(({ data }) => data)
		.catch(err => console.log(err))
}

const setConfirmaSerie = (val, user) => {
	const URL = API + "admin/set_valida_serie"
	const data = {
		valida_serie: val,
		usuario: user
	}
	return axiosInstance
		.post(URL, data, config)
		.then(response => response)
		.catch(err => console.log(err))
}

const getTiposAdmision = () => {
	const URL = API + "admin/get_tipo_admision"
	return axiosInstance
		.get(URL, config)
		.then(response => response)
		.catch(error => error)
}

const sendExcelIngresoEspecial = data => {
	const URL = API + "admin/load_excel_ingreso_especial"
	const _headers = {
		headers: {
			...config.headers,
			...{ "Content-type": "multipart/form-data" }
		}
	}
	return axiosInstance
		.post(URL, data, _headers)
		.then(({ data }) => data)
		.catch(err => err)
}

const syncDelfosExcel = data => {
	const URL = API + "admin/excel_ingreso_sync"
	return axiosInstance
		.post(URL, data, config)
		.then(({ data }) => data)
		.catch(err => console.log(err))
}

const getCargas = () => {
	const URL = API + "admin/excel_ingreso_carga"
	return axiosInstance
		.get(URL, config)
		.then(response => response)
		.catch(err => console.log(err))
}

const getCarga = data => {
	const URL = API + "admin/excel_ingreso_carga"
	return axiosInstance
		.post(URL, data, config)
		.then(data  => data)
		.catch(err => console.log(err))
}

const getDetalle = data => {
	const URL = API + "admin/excel_ingreso_carga_detalle"
	return axiosInstance
		.post(URL, data, config)
		.then(data  => data)
		.catch(err => console.log(err))
}

const getDetalleById = data => {
	const URL = API + "admin/excel_ingreso_carga_detalle/byId"
	return axiosInstance
		.post(URL, data, config)
		.then(data  => data)
		.catch(err => console.log(err))
}


const getInfoCarrera = data => {
	const URL = API + "admin/excel_info_carreras"
	return axiosInstance
		.post(URL, data, config)
		.then(data  => data)
		.catch(err => console.log(err))
}

const enviarMensajeMasivo = data => {
	const URL = API + "admin/excel_ingreso_carga_detalle/correo"
	return axiosInstance
		.post(URL, data, config)
		.then(data  => data)
		.catch(err => console.log(err))
}

const cambiarEstadoEspecial = data => {
	const URL = API + "admin/excel_ingreso_carga_detalle/cambio_estado"
	return axiosInstance
		.post(URL, data, config)
		.then(data  => data)
		.catch(err => console.log(err))
}

const migrarExcelEdelfos = data => {
	const URL = API + "admin/excel_ingreso_carga_detalle/migrar_delfos"
	return axiosInstance
		.post(URL, data, config)
		.then(data  => data)
		.catch(err => console.log(err))
}

const getReporteAdmision = () => {
	const URL = API + "admin/get_reporte_admision"
	return axiosInstance
		.get(URL, config)
		.then(response => response)
		.catch(err => console.log(err))
}

const getReporteCargas = () => {
	const URL = API + "admin/get_reporte_cargas"
	return axiosInstance
		.get(URL, config)
		.then(response => response)
		.catch(err => console.log(err))
}

const sendFilesAdmin = (data2) => {
		const URL = API + "admin/update_files"
		const _headers = {
			headers: {
				...config.headers,
				...{ "Content-type": "multipart/form-data" }
			}
		}
		return axiosInstance
			.post(URL, data2, _headers)
			.then(({ data }) => data)
			.catch(error => console.log(error))
}

export {
	getInfoUsuarios,
	getPostulacionesPorSede,
	getPostulacionesLEPorCarrera,
	getAllPostulacionesPorSede,
	getListaEsperaPorSede,
	getInfoCarreraDelfos,
	getCarrerasLESede,
	getCarrerasSede,
	getSedes,
	getRoles,
	getPostulacion,
	getTipoPostulacion,
	getArchivos,
	getPeriodos,
	actualizarSede,
	setPeriodos,
	setRolUsuario,
	setSedeUsuario,
	setCambioEstado,
	setMensajePostulacion,
	refreshUser,
	isSessionOpen,
	getGenero,
	getRegion,
	getCiudad,
	getComuna,
	setConfirmaSerie,
	getTiposAdmision,
	sendExcelIngresoEspecial,
	syncDelfosExcel,
	getCargas,
	getCarga,
	getDetalle,
	getDetalleById,
	getInfoCarrera,
	enviarMensajeMasivo,
	cambiarEstadoEspecial,
	migrarExcelEdelfos,
	getReporteAdmision,
	getReporteCargas,
	sendFilesAdmin
}
