import React, { Fragment } from "react"
import TitleForm from "../../../TitleForm/TitleForm"
import InputField from "../../../InputField/InputField"
import Datepicker from "../../../Datepicker/Datepicker"
import Select from "../../../Select/Select"
import Options from "../select.options"

const SectionExtranjera = props => {
	const {
		title,
		dataExtranjera,
		register,
		errors,
		isNacional,
		optionsSelect
	} = props


	if (isNacional) {
		return null
	} else {
		return (
			<Fragment>
				<TitleForm>{title}</TitleForm>
				<div className="row">
					<div className="col s3 input-field">
						<Select
							name="informacion_extranjera.pais_nacionalidad"
							label="País Nacionalidad"
							defaultValue={dataExtranjera?.pais_nacionalidad || ""}
							options={optionsSelect?.pais}
							register={register}
							errors={errors?.informacion_extranjera?.pais_nacionalidad}
						/>
					</div>

					<div className="col s3 input-field">
						<Select
							name="informacion_extranjera.pais_origen"
							label="País Origen"
							defaultValue={dataExtranjera?.pais_origen || ""}
							options={optionsSelect?.pais}
							register={register}
							errors={errors?.informacion_extranjera?.pais_origen}
						/>
					</div>

					<div className="col s6 input-field">
						{/* esto sería un select que viene con opciones de delfos */}
						<Select
							id="inputResPrev"
							name="informacion_extranjera.residencia_previa"
							label="Tipo de Residencia"
							defaultValue={dataExtranjera?.residencia_previa || ""}
							options={optionsSelect?.tipo_residencia}
							register={register}
							errors={errors?.informacion_extranjera?.residencia_previa}
						/>
					</div>
				</div>

				<div className="row">
					<div className="col s3 input-field">
						<InputField
							id="inputIdPasaporte"
							name="informacion_extranjera.id_pasaporte"
							label="Pasaporte"
							defaultValue={dataExtranjera?.id_pasaporte || ""}
							register={register}
							errors={errors?.informacion_extranjera?.id_pasaporte}
						/>
					</div>

					<div className="col s3 input-field">
						<Select
							name="informacion_extranjera.tipo_visa"
							label="Tipo de Visa"
							defaultValue={dataExtranjera?.tipo_visa || ""}
							options={optionsSelect?.tipo_visa}
							register={register}
							errors={errors?.informacion_extranjera?.tipo_visa}
						/>
					</div>

					<div className="col s3 input-field">
						<Datepicker
							id="inputFechaEmision"
							name="informacion_extranjera.fecha_emision"
							label="Fecha de Emisión"
							defaultValue={dataExtranjera?.fecha_emision || ""}
							register={register}
							errors={errors?.informacion_extranjera?.fecha_emision}
						/>
					</div>

					<div className="col s3 input-field">
						<Datepicker
							id="inputFechaVcto"
							name="informacion_extranjera.fecha_vencimiento"
							label="Fecha de Vencimiento"
							defaultValue={dataExtranjera?.fecha_vencimiento || ""}
							register={register}
							errors={errors?.informacion_extranjera?.fecha_vencimiento}
						/>
					</div>
				</div>
			</Fragment>
		)
	}
}

export default SectionExtranjera
