import React, { Fragment, useEffect } from "react"
import TitleForm from "../../../TitleForm/TitleForm"
import InputField from "../../../InputField/InputField"
import Datepicker from "../../../Datepicker/Datepicker"
import Select from "../../../Select/Select"
import Options from "../select.options"
const SectionPersonal = props => {
	const {
		title,
		user,
		register,
		errors,
		maxYear,
		minYear,
		dataPostulante,
	  setDataPostulante,
		setIsNacional,
		optionsSelect
	} = props

	/* Setea hook IsNacional al inicio para que muestre u oculte seccion de Extranjero */
	const enableSectionExtranjero = () => {
		let selectNacionalidad = document.getElementsByName("postulante.nacionalidad")[0].value
		setIsNacional(selectNacionalidad !== "E")
	}

	/* Maneja evento de select Nacionalidad para mostrar/ocultar seccion extranjero */
	const handleChangeNacionalidad = e => {
		const { value } = e.target
		const isNac = value === "N"
		setIsNacional(isNac)
	}

	const handleChangeFecha = (e) => {
		setDataPostulante({...dataPostulante, nacimiento: e.target.value})
	}

	useEffect(() => {
		enableSectionExtranjero()
	})

	return (
		<Fragment>
			<TitleForm>{title}</TitleForm>

			{/***************** DATOS PRECARGADOS DEL USUARIO LOGUEADO ********************/}
			<div className="row ">
				<div className="input-field col s2 ">
					<InputField name="postulante.run" label="Rut" readOnly noValidate value={user.run} />
				</div>
				<div className="input-field col s4">
					<InputField
						name="postulante.nombres"
						label="Nombres"
						readOnly
						noValidate
						value={user.nombres}
					/>
				</div>
				<div className="input-field col s3">
					<InputField
						name="postulante.paterno"
						label="Apellido Paterno"
						readOnly
						noValidate
						value={user.paterno}
					/>
				</div>
				<div className="input-field col s3">
					<InputField
						name="postulante.materno"
						label="Apellido Materno"
						readOnly
						noValidate
						value={user.materno}
					/>
				</div>
			</div>
			{/********************************************************************************/}
			<div className="row ">
				<div className="col s3 input-field ">
					<Datepicker
						id="inputFechaNacimiento"
						name="postulante.nacimiento"
						label="Fecha de Nacimiento"
						value={dataPostulante.nacimiento || ""}
						onChange={handleChangeFecha}
						minYear={minYear}
						maxYear={maxYear}
						register={register}
						errors={errors?.postulante?.nacimiento}
					/>
				</div>

				<div className="col s3 input-field">
					<Select
						id="selectSexo"
						name="postulante.sexo"
						label="Género"
						defaultValue={dataPostulante.sexo || ""}
						options={optionsSelect?.genero}
						register={register}
						errors={errors?.postulante?.sexo}
					/>
				</div>

				<div className="col s3 input-field">
					<Select
						name="postulante.estado_civil"
						label="Estado Civil"
						defaultValue={dataPostulante.estado_civil || ""}
						options={optionsSelect?.estado_civil}
						register={register}
						errors={errors?.postulante?.estado_civil}
					/>
				</div>

				<div className="col s3 input-field">
					<Select
						name="postulante.nacionalidad"
						label="Nacionalidad"
						defaultValue={dataPostulante.nacionalidad || ""}
						options={optionsSelect.nacionalidad}
						onChange={handleChangeNacionalidad}
						register={register}
						errors={errors?.postulante?.nacionalidad}
					/>
				</div>
			</div>
		</Fragment>
	)
}

export default SectionPersonal
