import React from 'react'
import {withRouter} from 'react-router-dom'

const LoginAdmin = ({history}) => {

	const goLogin = () => {
		history.push('/admin/login')
	}
	return (
		<div id="login-admin" className="icon-text hidden">
			<button className="btn white white-text valign-wrapper icon-center" onClick={goLogin}>
				<i className="material-icons white-text text-lighten-2">person</i>
				<span>Administrador</span>
			</button>
		</div>
	)
}

export default withRouter(LoginAdmin)
