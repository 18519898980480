import React, { Fragment } from "react"

const ModalCarrera = () => {
    //const { id } = props

    return (
        <Fragment>
            <div className="modal" id="modal-carrera">
                <div className="modal-content">
                    <h4>Agregar una nueva Carrera</h4>
                    <p></p>
                </div>
                <div className="modal-footer">
                    <a href="#!" className="modal-close waves-effect waves-green btn-flat">Cerrar</a>
                    <a href="#!" className="waves-effect waves-green btn">Agregar</a>
                </div>
            </div>
        </Fragment>
    )

}

export default ModalCarrera