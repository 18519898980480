import React, { Fragment } from "react"
import "./TitleForm.scss"
const TitleForm = ({light=false, ...props}) => {
	return (
		<Fragment>
			<div
				className={`form-title purple-text ${light ? "text-lighten-5" : " text-darken-4 "}`}
				{...props}
			/>
			{/* <hr className="mb-4" /> */}
		</Fragment>
	)
}

export default TitleForm
