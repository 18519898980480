import * as yup from "yup"


/* Esquema de formulario en etapa2. tipos de datos, si son requeridos y validaciones custom */
const schema = (minYear, maxYear) => {
	return yup.object().shape({
		postulante: yup.object().shape({
			nacimiento: yup
				.string()
				.required()
				.test("invalidYear", "Fecha inválida", function (value) {
					let selectYear = parseInt(value.split("/")[2])
					return !(selectYear > maxYear || selectYear < minYear)
				}),
			estado_civil: yup.string().required(),
			estudios_previos: yup.string().required(),
			nacionalidad: yup.string().required(),
			periodo_egreso_em: yup.string().required(),
			sexo: yup.string().required()
		}),
		informacion_academica: yup.object().nullable().default(null).shape({
			// estado: yup.string().required(),
			titulo: yup.string().required(),
			periodo_egreso: yup.string().required()
		}),
		informacion_extranjera: yup
			.object()
			.nullable()
			.default(null)
			.shape({
				pais_nacionalidad: yup.string().required(),
				pais_origen: yup.string().required(),
				residencia_previa: yup.string().required(),
				id_pasaporte: yup.string().required(),
				tipo_visa: yup.string().required(),
				fecha_emision: yup.string().required(),
				fecha_vencimiento: yup
					.string()
					.required()
					.test("invalidDate", "No puede ser menor a Fecha de Emisión", function (value) {
						let parseDateEm = this.parent.fecha_emision.split("/").reverse().join("-")
						let parseDateVe = value.split("/").reverse().join("-")
						let fecha_em = new Date(parseDateEm)
						let fecha_ve = new Date(parseDateVe)
						return fecha_em < fecha_ve
					})
			})
	})
}

export default schema
