import React, {Fragment, useEffect, useState} from "react"
import showToast from "../../Toast/Toast";
import {enviarMensajeMasivo} from "../../../services/admin.service";
import Loader from "../../Loader/Loader";

const ModalCorreo = props => {

    const { correos, refresh } = props

    const [mensaje, setMensaje] = useState(null)
    const [loader,  setLoader]  = useState(false)

    useEffect(()=>{
    }, [])


    const fillMensaje = () => e => {
        setMensaje(e.target.value)
    }

    const procesarMensaje = async () => {
        if(mensaje===null){
            showToast("Debe escribir un mensaje")
            return null
        }
        setLoader(true)

        let params = {}
        let result
            params = {
                correos: correos,
                mensaje: mensaje,
            }
            result = await enviarMensajeMasivo(params)
        if(result?.status === 200){
            showToast("Mensaje Enviado exitosamente")
        }
        else{
            showToast("Se ha producido un error, probablemente en el servicio de correos")
        }
        setLoader(false)
        refresh()
        const btn = document.getElementById('modal-m-close')
        btn.click()
        /*
        const modal = document.getElementById('modal-revisar')
        const instance = M.Modal.init(modal)
        instance.close()*/
    }

    const rowCorreos = () => {
        return correos.map((fila, i)=> {
            return (
                <tr key={ fila.id }>
                    <td>{ fila.name }</td>
                    <td>{ fila.mail }</td>
                </tr>
            )
        })
    }

    return (
        <Fragment>
            <div className="modal" id="modal-mensaje">
                <div className="modal-content">
                    <h4>Enviar un Mensaje</h4>
                    Envíe un mensaje masivo a todos los destinatarios seleccionados
                    <br/><br/>
                    <Loader loader={loader} />
                    <div className="row">
                        <div className="col s12">
                            <div className="row">
                                <table>
                                    <thead>
                                        <tr>
                                            <th>Nombre</th>
                                            <th>Correo</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        { rowCorreos() }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col s12 m12 l12">
                            <h2 className="subtitle"><span className="blue-grey-text">Mensaje</span></h2>
                        </div>
                    </div>
                    <div className="row">
                        <form>
                            <div className="col s12">
                                <div className="row">
                                    <div className="input-field col s12">
                                        <textarea id="textarea-mensaje" className="materialize-textarea" onKeyUp={ fillMensaje() }></textarea>
                                        <label htmlFor="textarea-mensaje">Mensaje</label>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
                <div className="modal-footer">
                    <a href="#" id="modal-m-close" className="modal-close waves-effect waves-green btn-flat">Cerrar</a>
                    <a href="#" className="waves-effect waves-green btn green" onClick={()=>procesarMensaje() }>Procesar</a>
                </div>
            </div>
        </Fragment>
    )

}

export default ModalCorreo