import React, { useEffect, useState } from "react"
import {withRouter} from "react-router-dom"
import Header from './../../components/Header/Header'
import {getAllPostulacionesPorSede } from "../../services/admin.service";
import Loader from "../../components/Loader/Loader";
import {useAdmin} from "../../context/AdminProvider";

const ListaPostulaciones = props => {

    const { admin } = useAdmin()
    const [data, setData] = useState([])

    const [loader, setLoader] = useState(true)


    useEffect(() => {
        const fetchData = async () => {
            if(admin!==null){
                setLoader(true)
                const result = await getAllPostulacionesPorSede()
                if(result.status === 200){
                    console.log(result.data)
                    setData(result.data)
                }
                else
                    console.log(result)
                setLoader(false)
            }
        };
        //setData(dataPostulaciones)
        fetchData();
    }, [])

    const viewPostulaciones =  () => {
        return data.map((user) =>
            <tr key={user.datos_personales.run} id={user.datos_personales.run}>
                <td></td>
                <td>
                    <div className="row" style={{marginBottom:0}}>
                        <div className="col s2 m1 l1">
                            <a href={"/admin/postulaciones/p/"+ user.postulacion.id}>
                                <b>{ user.postulacion.id }</b>
                            </a>
                        </div>
                        <div className="col s10 m11 l11">
                            <a href={"/admin/postulaciones/p/"+ user.postulacion.id}
                               className="black-text">
                                <b>{ user.datos_personales.nombres + " " + user.datos_personales.paterno + " " + user.datos_personales.materno }</b>
                            </a>
                        </div>
                    </div>
                    <div className="row" style={{marginBottom:0}}>
                        <div className="col s8 m7 offset-m1 l7 offset-l1">
                            <div
                                style={{fontSize:.825 + "rem", color:"#777", fontStyle:"italic", fontWeight:"100"}}>
                                <b>Fecha Postulación</b> {user.postulacion.fecha_validacion} -- <b>Rut</b>: {user.datos_personales.run}-{user.datos_personales.dv}
                                <br />
                                <b>Sede</b>: {user.postulacion.sede}
                                -- <b>Período</b>: {user.postulacion.periodo}
                            </div>
                        </div>
                        <div className="col s4 m4 l4">
                            <span className="new badge theme lighten-0 right" data-badge-caption=""><b>{ user.estado.nombre }</b></span>
                        </div>
                    </div>
                </td>
            </tr>
        )
    }

  return (
    <div>
        <Header />
        <div className="row container title-scope">
            <div className="title col s12 l12 left"><h6>Lista de</h6><h2>Postulaciones</h2></div>
        </div>
        <div className="row container title-scope margin-bottom-24px">
            <div className="col s12 m12 l8 note text-align-justify">A continuación se muestra el listado de Postulaciones ingresadas al Sistema
            </div>
        </div>
      <div className="container">
          <div className="card">
              <div className="row">
                  <div className="col s12">
                      <table>
                          <tbody>
                          { viewPostulaciones() }
                          </tbody>
                      </table>
                      { <Loader loader={loader} /> }
                  </div>
              </div>
          </div>
          <br/>
      </div>
    </div>
  )
}

export default withRouter(ListaPostulaciones)
