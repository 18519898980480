import { authHeader } from "./auth-header"
import axios from "axios"
import showToast from "../components/Toast/Toast"
import { API_URL } from "../config"

const API = API_URL() + "api/"
const config = {
	headers: authHeader()
}

 const axiosInstance = axios.create()

 axiosInstance.interceptors.response.use(
	response => {
		return response
	},
	error => {
		if (error.response.status === 401) {
			showToast("Tu sesión ha expirado")
			localStorage.removeItem('ssid')
			setTimeout(() => {
				window.location='/'
			}, 1000);
		} else {
			console.log(error)
		}
	}
) 

const check401 = (error) => {
	if(error?.response?.status === 401){
		showToast("Tu sesión ha expirado. Debes loguearte nuevamente")
		localStorage.clear()
		setTimeout(() => {
			window.location = "/"
		}, 2000);
		return error
	}else{
		return error
	}
}

/*  Envía si el usuario acepta términos (modal) para que pueda continuar con formulario */
const acceptTermsStep0 = accept => {
	const URL = API + "accept_terms"
	const body = {
		acepta_terminos: accept
	}

	return axiosInstance
		.post(URL, body, config)
		.then(({ data }) => data)
		.catch(error => error)
}

/* Envia el email por post para que api envie correo con token */
const sendEmailStep1 = email => {
	const URL = API + "send_email_token"
	const body = {
		email: email
	}

	return axiosInstance
		.post(URL, body, config)
		.then(({ data }) => data)
		.catch(error => console.log(error))
}

/* Envia por post el token que recibio el user por correo para checkar si es correcto */
const isValidEmailTokenStep1 = email_token => {
	const URL = API + "email_validation"
	const body = {
		email_token: email_token,
		etapa: 1
	}
	return axiosInstance
		.post(URL, body, config)
		.then(({ data }) => data)
		.catch(error => {
			console.log(error)
			return false
		})
}

const sendDataStep2 = data => {
	const URL = API + "postulante_etapa2"

	return axiosInstance
		.post(URL, data, config)
		.then(({ data }) => data)
		.catch(error => console.log(error))
}

const sendDataStep3 = data => {
	const URL = API + "postulante_etapa3"

	return axiosInstance
		.post(URL, data, config)
		.then(({ data }) => data)
		.catch(error => console.log(error))
}

const sendDataStep4 = data => {
	const URL = API + "postulante_etapa4"

	return axiosInstance
		.post(URL, data, config)
		.then(({ data }) => data)
		.catch(error => console.log(error))
}

const getCarreras = (sede, tipo) => {
	const URL = API + `get_carreras?sede=${sede}&tipo=${tipo}`
	return axiosInstance
		.get(URL, config)
		.then(({ data }) => data)
		.catch(error => check401(error))
}

const getTiposCarrera = (sede) => {
	const URL = API + `get_carreras_tipo?sede=${sede}`
	return axiosInstance
		.get(URL, config)
		.then(({ data }) => data)
		.catch(error => check401(error))
}

const sendDataStep5 = data2 => {
	const URL = API + "postulante_etapa5"
	const _headers = {
		headers: {
			...config.headers,
			...{ "Content-type": "multipart/form-data" }
		}
	}
	return axiosInstance
		.post(URL, data2, _headers)
		.then(({ data }) => data)
		.catch(error => console.log(error))
}

const getTiposArchivo = tipo => {
	const URL = API + `get_archivos?tipo=${tipo}`
	return axiosInstance
		.get(URL, config)
		.then(({ data }) => data)
		.catch(err => err)
}

const sendDataStep6 = valida => {
	const URL = API + "postulante_etapa6"
	const data = { valida_informacion: valida }
	return axiosInstance
		.post(URL, data, config)
		.then(({ data }) => data)
		.catch(error => console.log(error))
}

export {
	acceptTermsStep0,
	sendEmailStep1,
	isValidEmailTokenStep1,
	sendDataStep2,
	sendDataStep3,
	sendDataStep4,
	getCarreras,
	sendDataStep5,
	getTiposArchivo,
	sendDataStep6,
	getTiposCarrera
}
