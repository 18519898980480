import React, { Fragment, useEffect, useState } from "react"
import { syncDelfosExcel } from "../../services/admin.service"
import showToast from "../Toast/Toast"

const ResponseExcel = ({ data }) => {
	const [isDataSyncOk, setIsDataSyncOk] = useState(2)
	const [syncProcess, setSyncProcess] = useState(false)
	const [loaderSync, setLoaderSync] = useState()

	const sincronizaDelfos = async () => {
		const dataSync = { id_archivo: data.id_archivo }
		setLoaderSync(true)
		const res = await syncDelfosExcel(dataSync)
		if (res?.response === "success") {
			showToast(res.message || "Sincronización a Delfos Exitosa")
			setIsDataSyncOk(1)
		} else {
			showToast(res?.message || "Ha ocurrido un error")
			setIsDataSyncOk(0)
		}
		console.log(res)
		setLoaderSync(false)
		setSyncProcess(true)
	}

	return (
		<div className="p-5">
			<hr />
			{isDataSyncOk === 1 && (
				<Fragment>
					<h3>Sincronizacion completa</h3>
					<h5>Los datos cargados en la plataforma han sido sincronizados correctamente en eDelfos</h5>
				</Fragment>
			)}
			{isDataSyncOk === 0 && (
				<Fragment>
					<h3>Sincronizacion Incompleta</h3>
					<h5>Los datos no fueron cargados en eDelfos por encontrase inconsistencias en algunos, favor revisar</h5>
				</Fragment>
			)}

			{!syncProcess && data?.isValid && (
				<Fragment>
					<h3>Carga de Archivo Exitosa</h3>
					<h4>Se ha validado correctamente archivo con {data.total_filas} filas.</h4>
					<h5>Para continuar debe sincronizar este archivo con Delfos </h5>
					<div className="row">
						<div className="col s12 center">
							<button
								className="waves-effect waves-light bg-ula-blue btn-large mt-2 mx-4"
								onClick={sincronizaDelfos}
								disabled={loaderSync}
							>
								{loaderSync ? "Cargando..." : "Sincronizar en Delfos"}
							</button>
						</div>
					</div>
				</Fragment>
			)}
			{!syncProcess && !data?.isValid && (
				<Fragment>
					<h3>Carga no realizada</h3>
					<h4>
						Se han encontrado errores en <b>{data?.total_filas}</b> filas
					</h4>
					<table>
						<thead>
							<tr>
								<th>N° Fila</th>
								<th>Descripción</th>
							</tr>
						</thead>
						<tbody>
							{data?.filas?.map((x, index) => {
								return (
									<tr key={index}>
										<td className="left">{x.fila}</td>
										<td>{x.error}</td>
									</tr>
								)
							})}
						</tbody>
					</table>
				</Fragment>
			)}
		</div>
	)
}

export default ResponseExcel
