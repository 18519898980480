import React, {useEffect, useState} from "react"
import {useParams, withRouter} from "react-router-dom"
import Header from './../../components/Header/Header'
import './css/card.css'
import {useAdmin} from "../../context/AdminProvider";
import Loader from "../../components/Loader/Loader";
import {getDetalle, getCarga} from "../../services/admin.service";
import showToast from "../../components/Toast/Toast";
import M from "materialize-css";
import ModalCorreo from "../../components/ModalAdmin/IngresosEspeciales/ModalCorreo";
import ModalCambioEstado from "../../components/ModalAdmin/IngresosEspeciales/ModalCambioEstado";
import ModalSyncDelfos from "../../components/ModalAdmin/IngresosEspeciales/ModalSyncDelfos";

const DetalleExcel = props => {
    const { history } = props

    const { carga } = useParams()

    const { admin } = useAdmin()
    const [loader, setLoader] = useState(false)
    const [data, setData] = useState([])
    const [cargaInfo, setCargaInfo] = useState([])
    const [correos, setCorreos] = useState([])
    const [todos, setTodos] = useState(false)
    const [postulante, setPostulante] = useState(null)

    const fetchData = async () => {
        if (admin === null) {
            history.push("/admin/login")
        }
        setLoader(true)
        const data = {
            id: carga
        }
        let res = await getDetalle(data)
        if (res.status === 200) {
            setData(res.data)
        } else {
            showToast("No se cargó la información, intente de nuevo")
        }

        res = await getCarga(data)
        if (res.status === 200) {
            setCargaInfo(res.data)
        } else {
            showToast("No se cargó la información, intente de nuevo")
        }

        await reloadM()
        setLoader(false)
    }

    useEffect(() => {
        //setData(dataPostulaciones)

        fetchData()
    }, [admin]) // eslint-disable-line react-hooks/exhaustive-deps

    const reloadM = async () => {
        await M.AutoInit()
        // let elems =
        // let instances = M.Tooltip.init(elems)
        // elems = document.querySelectorAll(".fixed-action-btn")
        // instances = M.FloatingActionButton.init(elems)
        // elems = document.querySelectorAll(".modal")
        // instances =
        M.Modal.init(document.querySelectorAll(".tooltipped, .fixed-action-btn, .modal"))
    }

    const openModalByRow = (id, p) => {
        const modal = document.getElementById(id)
        if(modal!==null){
            setPostulante(p)
            const instance = M.Modal.init(modal, {
                onCloseEnd: () => {}
            })
            instance.open()
        }
    }

    const rowCargas = () => {
        return data.map((fila, i) => {
            return (
                <tr key={"fila-" + fila.id}>
                    <td><a href="#">{ fila.nombres + " " + fila.paterno + " " + fila.materno }</a></td>
                    <td>{ fila.cod_carrera }</td>
                    <td>{ fila.sede }</td>
                    <td>{ fila.email }</td>
                    <td>{ (fila.sync_edelfos)? "Cargado" :
                        <a href="#" onClick={() =>openModalByRow("modal-reload", fila.id)}><i className="material-icons blue-text">autorenew</i></a>
                    }</td>
                    <td>{ fila.estado }</td>
                    <td>
                        <a href="#" onClick={() =>openModalByRow("modal-cambio-estado", fila.id)}><i className="material-icons blue-text">rate_review</i></a>
                    </td>
                    <td>
                        { (!fila.email_enviado)?
                        <p>
                            <label>
                                <input
                                    type="checkbox"
                                    name="correo"
                                    data-id={fila.id}
                                    data-correo={fila.email}
                                    data-nombre={fila.nombres + " " + fila.paterno + " " + fila.materno }
                                    value="0"
                                />
                                <span></span>
                            </label>
                        </p> : "Correo Enviado" }
                    </td>
                </tr>
            )
        })
    }


    const initAndOpenModal = async () => {
        //await M.AutoInit()
        await openModal()
    }

    const toggleTodos = () => e => {
        const inputs = document.querySelectorAll("input[name=\"correo\"]")
        document.getElementById('todo').checked = e.target.checked
        for (let i = 0; i < inputs.length; i += 1) {
            inputs[i].checked = e.target.checked;
        }
    }

    const openModal = () => {
        let inputs = []
        if(!todos)
            inputs = document.querySelectorAll("input[name=\"correo\"]:checked")
        else
            inputs = document.querySelectorAll("input[name=\"correo\"]")
        setCorreos([])
        let flag = 0
        for (let i = 0; i < inputs.length; i += 1) {
                flag += 1
                setCorreos(correos =>
                    correos.concat([
                        {
                            id: inputs[i].getAttribute("data-id"),
                            name: inputs[i].getAttribute("data-nombre"),
                            mail: inputs[i].getAttribute('data-correo'),
                        }
                    ])
                )
        }
        if (flag === 0) {
            showToast("Debe al menos seleccionar un destinatario")
            setCorreos([])
            return null
        }
        const modal = document.getElementById("modal-mensaje")
        const instance = M.Modal.init(modal, {
            onCloseEnd: () => {}
        })
        instance.open()
    }

    return (
        <div>
            <Header />
            <div className="row container title-scope">
                <div className="title col s12 l12 left">
                    <h6>Detalle</h6>
                    <h2>Carga - { (cargaInfo!==null)? cargaInfo?.tipo_admision?.nombre : null }</h2>
                </div>
            </div>
            <div className="row container title-scope margin-bottom-24px">
                <div className="col s12 m12 l8 note text-align-justify">
                    A continuación se muestra la tabla con el detalle de la carga seleccionada.
                </div>
            </div>
            <div className="container">
                <div className="row my-5 mx-2 card py-5 px-2">
                    {loader ? (
                        <Loader loader={loader} />
                    ) : (<div>
                        <table>
                            <thead>
                                <tr>
                                    <th width="35%">Nombre</th>
                                    <th>Carrera</th>
                                    <th>Sede</th>
                                    <th>Correo</th>
                                    <th>Sync</th>
                                    <th>Estado</th>
                                    <th>Ver/Editar</th>
                                    <th width="10%">Seleccionar <label><input type="checkbox" id="todo" name="todo" value="1" onChange={toggleTodos() }/><span style={{ fontSize: 8 + "px !important" }}>(Todos)</span></label></th>
                                </tr>
                            </thead>
                            <tbody>

                            { rowCargas() }
                            </tbody>

                        </table>
                    </div>) }
                </div>
            </div>
            <div className="fixed-action-btn direction-top">
                <button className="btn-floating btn-large theme-button">
                    <i className="material-icons">menu</i>
                </button>
                <ul>
                    <li>
                        <button
                            onClick={() => initAndOpenModal("modal-correo")}
                            className="btn-floating tooltipped green"
                            data-position="left"
                            data-tooltip="Enviar Correo"
                        >
                            <i className="material-icons white purple-text">email</i>
                        </button>
                        <span style={{ fontSize: 0.85 + "em" }}>Enviar Correo</span>
                    </li>
                </ul>
            </div>
            <ModalCorreo correos={correos} refresh={fetchData}/>
            <ModalCambioEstado id_fila={postulante} carga={cargaInfo} refresh={fetchData}/>
            <ModalSyncDelfos id_fila={postulante} carga={cargaInfo} refresh={fetchData}/>
        </div>
    )
}

export default withRouter(DetalleExcel)