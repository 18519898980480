import React, { Fragment, useEffect, useState } from "react"
import showToast from "../../Toast/Toast";
import {setCambioEstado} from "../../../services/admin.service";
import Loader from "../../Loader/Loader";

const ModalCambiar = props => {
    const { postulacion, carreras, refresh } = props
    const [tipo, setTipo] = useState(null)
    const [observacion, setObservacion] = useState()
    const [loader, setLoader] = useState(false)
    //const [newcarreras, setNewcarreras] = useState([])

    useEffect(()=>{
        let flag = "L_ESPERA"
        carreras.map((carrera) => {
            if(carrera.value==="ACEPTADO") flag = "ACEPTADO"
        })
        setTipo(flag)
    }, [carreras])

    const fillObservacion = () => e => {
        setObservacion(e.target.value)
    }

    const cambiarEstado = async () => {
        let inputs = document.querySelectorAll('input.carreramod')
        let newcarreras = []
        let flag = 0
        for(let i=0; i<inputs.length; i+=1){
            if(inputs[i].checked){
                flag += 1
                newcarreras = newcarreras.concat([{
                    codigo: inputs[i].getAttribute('data-codigo'),
                    id: inputs[i].getAttribute('data-id'),
                    name: inputs[i].getAttribute('data-nombre'),
                    value: inputs[i].value
                }])
            }
        }
        setLoader(true)
        if(flag===0){
            showToast("No hay cambios")
            setLoader(false)
            return null
        }
        if(flag>1){
            showToast("Sólo puede aceptar una carrera")
            setLoader(false)
            return null
        }

        let params = {
            carreras: newcarreras,
            postulacion: postulacion,
            estado: "ACEPTADO",
            observacion: observacion
        }

        const result = await setCambioEstado(params)
        if(result?.status === 200){
            showToast(result.data)
        }
        else{
            showToast("Error el cambiar estado")
        }
        setLoader(false)
        refresh()
        const btn = document.getElementById('modal-c-close')
        btn.click()
    }
    return (
        <Fragment>
            <div className="modal" id={"modal-cambiar"}>
                <div className="modal-content">
                    <h4>Cambiar Estado</h4>
                    <p>Usted podrá cambiar el estado de la Postulación (Siempre que la postulación no haya sido previamente aceptada)</p>
                    <div className="row">
                        <form className="col s12">
                            <div className="row">
                                <div className="col s12">
                                    <table>
                                        <thead>
                                        <tr>
                                            <th>Código</th>
                                            <th>Carrera</th>
                                            <th>Estado</th>
                                            <th>Acción</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {
                                            carreras.map((carrera) => {
                                                if(carrera.value!==null)
                                                return <tr key={carrera.id}>
                                                    <td>{carrera.codigo}</td>
                                                    <td>{carrera.name}</td>
                                                    <td>{carrera.value}</td>
                                                    <td>
                                                        {(carrera.value==="ACEPTADO" || carrera.value!=="L_ESPERA" || tipo==="ACEPTADO")? null : (
                                                                <label>
                                                                    <input type="checkbox" className="carreramod" data-codigo={carrera.codigo} data-nombre={carrera.name} defaultValue={"ACEPTADO"} data-id={carrera.id}/>
                                                                    <span>Aceptar</span>
                                                                </label>
                                                            )}
                                                    </td>
                                                </tr>
                                            })
                                        }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div className="row">
                                <div className="input-field col s12">
                                    <textarea id="textarea1" className="materialize-textarea" onKeyUp={fillObservacion()}></textarea>
                                    <label htmlFor="textarea1">Observaciones</label>
                                </div>
                                <Loader loader={loader} />
                            </div>
                        </form>
                    </div>
                </div>
                <div className="modal-footer">
                    <a href="#" id="modal-c-close" className="modal-close waves-effect waves-green btn-flat">Cerrar</a>
                    <a href="#" className="waves-effect waves-green btn" onClick={()=>cambiarEstado()}>Cambiar</a>
                </div>
            </div>
        </Fragment>
    )

}

export default ModalCambiar