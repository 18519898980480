import React, { useEffect } from "react"
import {  withRouter } from "react-router-dom"
import queryString from "query-string"
import { redirectResponseClaveUnicaToApi } from "./services/auth.service"
import showToast from "./components/Toast/Toast"
import Loader from "./components/Loader/Loader"

import { useUser } from "./context/UserProvider"
import {useDataForm} from "./context/FormProvider"

const ClaveUnica = ({ location }) => {
	const { updateUserWithStep, setUserToken, callOptions } = useUser()
	const { saveInitialDataFromLogin } = useDataForm()
	/* Obtengo los datos code y state de clave unica y los envio a api para 
		que me devuelva el usuario logueado con los datos del formulario si es que tiene
	 */
	const getDataFromClaveUnica = async () => {
		const dataParams = queryString.parse(location.search)
		const response = await redirectResponseClaveUnicaToApi(dataParams)

		console.log(JSON.stringify(response, null, 2))
		if (response?.status === 200) {
			if(response.data.postulante_exists){
				await saveInitialDataFromLogin(response.data.data_form)
			}
			await setUserToken(response.data.user.access_token)
			await updateUserWithStep(response.data.user)
			 callOptions()
			 return (window.location = "/")
		} else {
			showToast("Ha habido un problema en la conexión")
			updateUserWithStep(null)
			return (window.location.href = "/")
		}
	}

	useEffect(() => {
		getDataFromClaveUnica()
	}, []) // eslint-disable-line react-hooks/exhaustive-deps

	return <Loader loader={true} />
}

export default withRouter(ClaveUnica)
