import React, { useState, useEffect } from "react"
import TitleForm from "../../TitleForm/TitleForm"
import { getSedes } from "./../../../services/admin.service"
import CareerList from "../../CareerList/CareerList"
import Select from "../../Select/Select"
import { getCarreras, getTiposCarrera, sendDataStep4 } from "../../../services/stepForm.service"
import showToast from "../../Toast/Toast"
import { useUser } from "../../../context/UserProvider"
import { useDataForm } from "../../../context/FormProvider"

const Step4 = props => {
	const { setLoader } = props

	const [selectedSede, setSelectedSede] = useState("") //sede seleccionada
	const [selectedTipoCarrera, setSelectedTipoCarrera] = useState("") //tipo carrera selccionada

	const [carrerasFiltradas, setCarrerasFiltradas] = useState([]) //lista de carreras segun selccion
	const [selectedCarreras, setSelectedCarreras] = useState([]) //carreras seleccionadas
	const [btnDisabled, setBtnDisabled] = useState(true) //boton para buscar carreras
	const [emptyCarreras, setEmptyCarreras] = useState(null) //si la busqueda no trae carreras
	const [disableStepBtn, setDisableStepBtn] = useState(false)
	const [tiposCarrera, setTiposCarrera] = useState([])
	const [disableTiposCarrera, setDisableTiposCarrera] = useState(false)
	const { user, optionsSelect, updateUserWithStep, nextStep, prevStep } = useUser()
	const {
		updatePostulacion,
		updateCarreras,
		dataCarreras,
		dataPostulacion,
		formHasChanges,
		dataPostulante,
		setTiposArchivo
	} = useDataForm()

	const setData = () => {
		if (dataPostulacion && Object.keys(dataPostulacion).length > 0) {
			loadCarreras(dataPostulacion.sede, dataPostulacion.tipo)
			setSelectedSede(dataPostulacion.sede)
			setSelectedTipoCarrera(dataPostulacion.tipo)
			setSelectedCarreras(dataCarreras || [])
		}
	}

	useEffect(() => {
		setData()
	}, [])

	const handleChangeSede = async e => {
		const { value } = e.target
		setSelectedSede(value)
		setSelectedTipoCarrera("")
		setBtnDisabled(true)
		resetLista()

		setLoader(true)
		setDisableTiposCarrera(true)

		const response = await getTiposCarrera(value)
		if (response?.response === "success") {
			setTiposCarrera(response.data)
			setLoader(false)
			setDisableTiposCarrera(false)
		} else {
			setLoader(false)
			setDisableTiposCarrera(false)
			return showToast("Ha ocurrido un problema al cargar los tipos de carrera.")
		}

	}

	const handleChangeTipoCarrera = e => {
		const { value } = e.target
		setSelectedTipoCarrera(value)
		setBtnDisabled(false)
		resetLista()
	}

	const resetLista = () => {
		setEmptyCarreras(null)
		setCarrerasFiltradas([])
		setSelectedCarreras([])
	}

	const loadCarreras = async (sede, tipo) => {
		setLoader(true)
		const carreras = await getCarreras(sede, tipo)

		if (carreras.data.length > 0) {
			setEmptyCarreras(false)
			setCarrerasFiltradas(carreras.data)
		} else {
			setEmptyCarreras(true)
			setCarrerasFiltradas([])
		}

		setLoader(false)
	}

	const getAndSendCarreras = async () => {
		const orderCarrerasId = selectedCarreras.map((car, index) => {
			return { id: car.id, orden: index }
		})

		if (selectedCarreras.length === 0) {
			return showToast("Debes seleccionar alguna carrera")
		}

		const noChanges = await !formHasChanges(selectedCarreras, 4)
		if (noChanges) {
			nextStep()
			return
		}

		setLoader(true)
		setDisableStepBtn(true)

		const dataToApi = {
			etapa: 4,
			tipo: selectedTipoCarrera,
			sede: selectedSede,
			carreras: orderCarrerasId
		}

		const responseFromApi = await sendDataStep4(dataToApi)

		if (responseFromApi?.response === "success") {
			updateUserWithStep(responseFromApi.user)
			updatePostulacion(responseFromApi.data.postulacion)
			updateCarreras(responseFromApi.data.carreras)
			setTiposArchivo(responseFromApi.data.tipos_archivo)
		} else {
			showToast("Ha habido un problema al enviar esta etapa")
		}
		setLoader(false)
		setDisableStepBtn(false)
	}

	return (
		<div className="custom-form">
			<TitleForm>Selección de Carreras</TitleForm>
			<p>
				{dataPostulante.sexo === "M" ? "Estimado" : "Estimada"} {user.nombres}, en esta etapa podrás revisar la oferta de Carreras de la Universidad de Los Lagos a las que
				podrás postular, según Campus/Sedes. <br/> Aquí puedes seleccionar las carreras y ordenarlas según tu orden de preferencia arrastrando el ítem en la lista de Carreras Seleccionadas.
			</p>

			<div className="row mx-2">
				<div className="col s4 input-field">
					<Select
						id="selectSedes"
						label="Sede/Campus"
						name="sede"
						defaultValue={selectedSede || ""}
						options={getSedes()?.map(s => {
							return { value: s.id, name: s.nombre }
						})}
						onChange={handleChangeSede}
					/>
				</div>

				<div className="col s4 input-field">
					<Select
						id="selectTipoCarrera"
						defaultValue={selectedTipoCarrera || ""}
						label="Tipo Formación"
						name="tipo_carrera"
						disabled={disableTiposCarrera}
						options={
							tiposCarrera.length === 0
								? optionsSelect?.tipo_carrera
								: optionsSelect?.tipo_carrera.filter(x => tiposCarrera.includes(x.value))
						}
						onChange={handleChangeTipoCarrera}
					/>
				</div>

				<div className="col s4 input-field">
					<button
						type="button"
						className="waves-effect waves-light bg-ula-blue  btn-small mt-2"
						disabled={btnDisabled}
						onClick={() => loadCarreras(selectedSede, selectedTipoCarrera)}
					>
						<i className="material-icons right">search</i>Buscar Carreras
					</button>
				</div>
			</div>

			{/* <dragList/> */}
			<CareerList
				carrerasFiltradas={carrerasFiltradas}
				emptyCarreras={emptyCarreras}
				selectedCarreras={selectedCarreras}
				setSelectedCarreras={setSelectedCarreras}
			/>

			<div className="step-buttons">
				<button
					type="button"
					disabled={disableStepBtn}
					className={"waves-effect waves-light purple darken-1 btn-small mx-2"}
					onClick={() => prevStep()}
				>
					<i className="large material-icons left">navigate_before</i>
					Anterior
				</button>

				<button
					type="button"
					disabled={disableStepBtn}
					className="waves-effect waves-light purple darken-1 btn-small right"
					onClick={getAndSendCarreras}
				>
					Siguiente
					<i className="large material-icons right">navigate_next</i>
				</button>
			</div>
		</div>
	)
}

export default Step4
