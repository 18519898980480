import React, { Fragment, useEffect } from "react"
import { withRouter } from "react-router-dom"
import { useUser } from "./../context/UserProvider"
import Login from "./../components/Login/Login"
import LoginAdmin from "./../components/LoginAdmin/LoginAdmin"
import HeaderAlumno from "../components/HeaderAlumno/HeaderAlumno"
import HomeDescription from "../components/HomeDescription/HomeDescription"
import { useState } from "react"

const Home = ({ loader }) => {
	const { user } = useUser()
	const [isLogged, setIsLogged] = useState(false)

	useEffect(() => {
		if (user) {
			setIsLogged(true)
		}
	}, [user])

	return (
		<Fragment>
			<HeaderAlumno />
			<Login isLogged={isLogged} loader={loader} />
			<HomeDescription />
			<LoginAdmin />
		</Fragment>
	)
}

export default withRouter(Home)
