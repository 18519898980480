import React, { Fragment } from "react"
import TitleForm from "../TitleForm/TitleForm"

const AntecedentesPersonales = props => {
	const { dataPostulante, user, dataExtranjera, dataAcademica, optionsSelect } = props

	const rowsAcademica = () => {
		if (Object.keys(dataAcademica).length > 0) {
			return (
				<Fragment>
					<tr className="no-borders">
						<td colSpan="2" className="table-subtitle purple darken-3">
							<b>Datos Académicos</b>
						</td>
					</tr>
					<tr>
						<td>Título</td>
						<td>{dataAcademica.titulo}</td>
					</tr>
					<tr>
						<td>Periodo Egreso</td>
						<td>{dataAcademica.periodo_egreso}</td>
					</tr>
				</Fragment>
			)
		} else {
			return null
		}
	}

	const rowsExtranjeria = () => {
		if (Object.keys(dataExtranjera).length > 0) {
			return (
				<Fragment>
					<tr className="no-borders">
						<td colSpan="2" className="table-subtitle purple darken-3">
							<b>Datos Extranjería</b>
						</td>
					</tr>
					<tr>
						<td>ID Pasaporte</td>
						<td>{dataExtranjera.id_pasaporte}</td>
					</tr>
					<tr>
						<td>País Nacionalidad</td>
						<td>
							{optionsSelect?.pais.find(x => x.value === dataExtranjera.pais_nacionalidad).name}
						</td>
					</tr>
					<tr>
						<td>País Origen</td>
						<td>
							{optionsSelect?.pais.find(x => x.value === dataExtranjera.pais_origen).name}
						</td>
					</tr>
					<tr>
						<td>Tipo de Visa</td>
						<td>
							{optionsSelect?.tipo_visa.find(x => x.value === dataExtranjera.tipo_visa).name}
						</td>
					</tr>
					<tr>
						<td>Fecha de Emisión</td>
						<td>{dataExtranjera.fecha_emision}</td>
					</tr>
					<tr>
						<td>Fecha de Vencimiento</td>
						<td>{dataExtranjera.fecha_vencimiento}</td>
					</tr>
					<tr>
						<td>Residencia Previa</td>
						<td>
							{
								optionsSelect?.tipo_residencia.find(
									x => x.value === dataExtranjera.residencia_previa
								).name
							}
						</td>
					</tr>
				</Fragment>
			)
		} else {
			return null
		}
	}
	return (
		<Fragment>
			<div className="row mb-0">
				<div className="col s12 m12 l12">
					<TitleForm light={true}>Antecedentes Personales</TitleForm>
				</div>
			</div>
			<div className="row">
				<div className="col s12 m12 l12 px-5">
					<table className="table-responsive tabla-resumen">
						<tbody>
							<tr className="no-borders">
								<td colSpan="2" className="table-subtitle purple darken-3">
									<b>Datos Personales</b>
								</td>
							</tr>
							<tr>
								<td>Rut</td>
								<td>{user.run + "-" + user.dv}</td>
							</tr>
							<tr>
								<td>Nombres</td>
								<td>{user.nombres}</td>
							</tr>
							<tr>
								<td>Apellido Paterno</td>
								<td>{user.paterno}</td>
							</tr>
							<tr>
								<td>Apellido Materno</td>
								<td>{user.materno}</td>
							</tr>
							<tr>
								<td>Sexo</td>
								<td>{optionsSelect?.genero.find(x => x.value === dataPostulante.sexo).name}</td>
							</tr>
							<tr>
								<td>Fecha Nacimiento</td>
								<td>{dataPostulante.nacimiento}</td>
							</tr>
							<tr>
								<td>Nacionalidad</td>
								<td>
									{
										optionsSelect?.nacionalidad.find(
											x => x.value === dataPostulante.nacionalidad
										).name
									}
								</td>
							</tr>
							<tr>
								<td>Teléfonos</td>
								<td>{dataPostulante.telefono + " - " + dataPostulante.telefono_trabajo}</td>
							</tr>
							<tr>
								<td>Correo Electrónico</td>
								<td>{user.email}</td>
							</tr>
							{rowsAcademica()}
							{rowsExtranjeria()}
							<tr className="no-borders">
								<td colSpan="2" className="table-subtitle purple darken-3">
									<b>Datos Ubicación</b>
								</td>
							</tr>
							<tr>
								<td>Dirección</td>
								<td>
									{dataPostulante != null
										? dataPostulante.calle +
										  " " +
										  dataPostulante.numero +
										  " " +
										  (dataPostulante.depto_casa != null ? dataPostulante.depto_casa : "")
										: null}
								</td>
							</tr>
							<tr>
								<td>Comuna - Ciudad</td>
								<td>
									{dataPostulante != null
										? optionsSelect?.comuna.find(x => x.value === dataPostulante.comuna)?.name +
										  " - " +
										  optionsSelect?.ciudad.find(x => x.value === dataPostulante.ciudad)?.name
										: null}
								</td>
							</tr>
							<tr>
								<td>Región</td>
								<td>
									{dataPostulante != null
										? optionsSelect?.region.find(x => x.value === dataPostulante.region).name
										: null}
								</td>
							</tr>
						</tbody>
					</table>
				</div>
			</div>
		</Fragment>
	)
}

export default AntecedentesPersonales
