import React, { Fragment, useState } from "react"
import TitleForm from "../../TitleForm/TitleForm"
import showToast from "../../Toast/Toast"
import { sendEmailStep1, isValidEmailTokenStep1 } from "./../../../services/stepForm.service"

import { useUser } from "../../../context/UserProvider"

//Presentacion y validacion de correo
const Step1 = props => {
	const { setLoader } = props

	const [validationEmail, setValidationEmail] = useState("")
	const [validationCod, setValidationCod] = useState("")
	const [isValidCod, setIsValidCod] = useState(false)
	const [isValidUser, setIsValidUser] = useState(false)
	const [btnDisabled, setBtnDisabled] = useState(false)
	const { user, updateUserWithStep } = useUser()

	/* Maneja evento onchange del input email */
	const emailChange = () => e => {
		setValidationEmail(e.target.value)
	}

	/* Maneja evento onChange de input cod */
	const validationCodChange = () => e => {
		setValidationCod(e.target.value.toUpperCase())
	}

	/* envia el email a la api para enviar correo */
	const sendValidationEmail = async e => {
		e.preventDefault()
		setLoader(true)
		setBtnDisabled(true)

		/* retorna true solo si response == success */
		const emailSent = await sendEmailStep1(validationEmail)

		if (emailSent?.response === 'success') {
			setIsValidUser(true)
			showToast(emailSent?.message)
		} else {
			setIsValidUser(false)
			showToast(Object.values(emailSent?.message)[0])

		}
		setLoader(false)
		setBtnDisabled(false)
	}

	/* Envía el cod a api para validar si está correcto */
	const checkValidationCod = async e => {
		e.preventDefault()
		setLoader(true)
		setBtnDisabled(true)

		const response = await isValidEmailTokenStep1(validationCod)
		const tokenValid = response?.response === "success"

		if (tokenValid) {
			updateUserWithStep(response.user)
		}

		setIsValidCod(tokenValid)
		showToast(response.message)

		setLoader(false)
		setBtnDisabled(false)
	}

	const showCodInput = () => {
		setIsValidUser(true)
	}

	const showEmailInput = () => {
		setIsValidUser(false)
	}

	if (user.etapa !== 1) {
		return (
			<Fragment>
				<TitleForm>Presentación y Validación</TitleForm>
				<p>Su correo ya ha sido validado. </p>
				<p>
				</p>
			</Fragment>
		)
	} else {
		return (
			<div className="custom-form">
				<TitleForm>Presentación y Validación</TitleForm>
				<p>
					Estimada/o {user.nombres}, para iniciar tu postulación debes ingresar tu correo electrónico personal. <br/>
					Antes de continuar debemos confirmar que tu correo electrónico sea válido,
					ya que este será el medio por el cual estaremos informando tus avances en el proceso. <br/>
					Para que lo podamos validar, te enviaremos un código de validación a tu correo electrónico
					personal, el cual deberás ingresar en esta etapa, pues será el medio oficial por el cual te contactaremos.
				</p>

				{isValidCod ? null : isValidUser ? (
					<div className="row">
						<div className="col s12">
							Te hemos enviado un código de validación a tu correo electrónico <br />
							Ingresa el código de validación a continuación:
							<div className="row">
								<form onSubmit={checkValidationCod}>
									<div className="input-field col s6">
										<input
											id="cod_validation_email"
											type="text"
											className="validate text-uppercase"
											onChange={validationCodChange()}
											required
										/>
										<label htmlFor="cod_validation_email">Código</label>
										<span onClick={showEmailInput} className="a-link">
											Aún no he recibido el código
										</span>
									</div>
									<div className="input-field col s6">
										<button
											className={`${
												btnDisabled ? "disabled" : ""
											} waves-effect waves-light bg-ula-blue  btn-small mt-2`}
											type="submit"
										>
											<i className="material-icons right">send</i>Validar
										</button>
									</div>
								</form>
							</div>
						</div>
					</div>
				) : (
					<div>

						<div className="row">
							<div className="col s12">
								Ingresa tu correo de contacto:
								<div className="row">
									<form onSubmit={sendValidationEmail} className="padding-form">
										<div className="input-field col s6">
											<input
												id="correo_contacto"
												type="email"
												className="validate"
												onChange={emailChange()}
												required
											/>
											<label htmlFor="correo_contacto">Email</label>
											<span onClick={showCodInput} className="a-link">
												Ya he recibido el código
											</span>
										</div>
										<div className="input-field col s6">
											<button
												className={`${
													btnDisabled ? "disabled" : ""
												} waves-effect waves-light bg-ula-blue  btn-small mt-2`}
												type="submit"
											>
												<i className="material-icons right">send</i>Validar
											</button>
										</div>
									</form>
								</div>
							</div>
						</div>
					</div>
				)}
			</div>
		)
	}
}

export default Step1
