import React, {  useEffect } from "react"
// import M from "materialize-css/dist/js/materialize"
import {withRouter} from "react-router-dom"
import {useAdmin} from "../../context/AdminProvider";


const SidenavAdmin = () => {

    useEffect(()=>{
    }, [])

 /*    const reloadSidenav = () => {
        let elems = document.getElementById('slide-out');
        let instances = M.Sidenav.init(elems);
    } */

    const { admin } = useAdmin()
    return (
        <ul id="slide-out" className="sidenav purple">
            <li>
                <div className="user-view">
                    <div className="background">
                    </div>
                    {/* <a href="#user"></a> */}
                    <a href="#name"><span className="white-text name">{ (admin!==null)? admin.nombre : "Anónimo" }</span></a>
                    <a href="#email"><span className="white-text email">{ (admin!==null)? admin.rol : "Anónimo" }</span></a>
                </div>
            </li>
            <li><span className="subheader white-text ml-4">Postulaciones</span></li>
            <li><a className="waves-effect ml-4" href="/admin/postulaciones/bandeja"> Bandeja de Entrada</a></li>
            <li><a className="waves-effect ml-4" href="/admin/postulaciones/listaespera"> Lista de Espera</a></li>
            <li><a className="waves-effect ml-4" href="/admin/postulaciones/todas"> Todas las postulaciones</a></li>
            <li><span className="subheader white-text ml-4">Ingresos Eseciales</span></li>
            <li><a className="waves-effect ml-4" href="/admin/ingresos_especiales/cargar"> Cargar Archivo</a></li>
            <li><a className="waves-effect ml-4" href="/admin/ingresos_especiales/ver"> Ver Cargas</a></li>
        </ul>
    )
}

export default withRouter(SidenavAdmin)