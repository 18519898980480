import React, { Fragment, useState, useEffect } from "react"
import TitleForm from "../../../TitleForm/TitleForm"
import InputField from "../../../InputField/InputField"
import Select from "../../../Select/Select"
import Options from "../select.options"

const SectionAcademica = props => {
	const { dataPostulante, dataAcademica, register, errors, title } = props
	const [carreraPrevia, setCarreraPrevia] = useState(false)

	/* Setea hook carreraPrevia al inicio para que muestre u oculte seccion de CarrerPrevia */
	const enableSectionAcademica = () => {
		let selectCarreraPrevia = document.getElementsByName("postulante.estudios_previos")[0].value
		setCarreraPrevia(selectCarreraPrevia === "true")
	}

	/* Maneja evento de select EstudiosPrevios para mostrar/ocultar seccion CArreraPrevia */
	const handleChangeCarreraPrevia = e => {
		const carr = e.target.value === "true"
		setCarreraPrevia(carr)
	}

	useEffect(() => {
		enableSectionAcademica()
	})

	return (
		<Fragment>
			<TitleForm>{title}</TitleForm>
			<div className="row">
				<div className="col s4 input-field">
					<Select
						name="postulante.periodo_egreso_em"
						label="Año Egreso Enseñanza Media"
						defaultValue={dataPostulante.periodo_egreso_em?.toString() || ""}
						options={Options().egresoMedia}
						register={register}
						errors={errors?.postulante?.periodo_egreso_em}
					/>
				</div>

				<div className="col s6 input-field">
					<Select
						name="postulante.estudios_previos"
						label="¿Tiene estudios Técnicos o Pregrados previos? "
						defaultValue={dataPostulante.estudios_previos ? "true" : "false" || ""}
						options={Options().siOno}
						onChange={handleChangeCarreraPrevia}
						register={register}
						errors={errors?.postulante?.estudios_previos}
					/>
				</div>
			</div>

			{carreraPrevia && (
				<div className="row">
					<div className="col s6 input-field">
						<InputField
							id="inputTitulo"
							name="informacion_academica.titulo"
							label="Título Carrera Previa"
							defaultValue={dataAcademica?.titulo || ""}
							register={register}
							errors={errors?.informacion_academica?.titulo}
						/>
					</div>

{/* 					<div className="col s3 input-field">
						<Select
							name="informacion_academica.estado"
							label="Estado Carrera Previa"
							defaultValue={dataAcademica?.estado || ""}
							options={Options().estadoCarrera}
							register={register}
							errors={errors?.informacion_academica?.estado}
						/>
					</div> */}

					<div className="col s4 input-field">
						<Select
							label="Año Egreso (Término)"
							name="informacion_academica.periodo_egreso"
							defaultValue={dataAcademica?.periodo_egreso?.toString() || ""}
							options={Options().egresoMedia}
							register={register}
							errors={errors?.informacion_academica?.periodo_egreso}
						/>
					</div>
				</div>
			)}
		</Fragment>
	)
}

export default SectionAcademica
