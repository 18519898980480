import React, { Fragment, useEffect, useState } from "react"
import M from "materialize-css/dist/js/materialize"
import { withRouter, useParams } from "react-router-dom"
import Header from "./../../components/Header/Header"
import {
	getPostulacion,
	getGenero,
	getRegion,
	getCiudad,
	getComuna,
	sendFilesAdmin
} from "../../services/admin.service"
import Timeline from "../../components/Timeline/Timeline"

import ModalRevisar from "../../components/ModalAdmin/Postulaciones/ModalRevisar"
import ModalCambiar from "../../components/ModalAdmin/Postulaciones/ModalCambiar"
import showToast from "../../components/Toast/Toast"
import Loader from "../../components/Loader/Loader"
import ModalMensaje from "../../components/ModalAdmin/Postulaciones/ModalMensaje"
import ModalRechazar from "../../components/ModalAdmin/Postulaciones/ModalRechazar"
import ModalConfirmaSerie from "../../components/ModalAdmin/Postulaciones/ModalConfirmaSerie"
import { formatDate, validExtensionInputFile } from "./../../utils"
const FORMATOS_PERMITDOS = ["pdf", "jpeg", "jpg", "png"]

const Postulacion = props => {
	const [data, setData] = useState([])
	const [loaded, setLoaded] = useState(false)
	const [carreras, setCarreras] = useState([])

	const [genero, setGenero] = useState("")
	const [region, setRegion] = useState("")
	const [ciudad, setCiudad] = useState("")
	const [comuna, setComuna] = useState("")

	const [formaIngreso, setFormaIngreso] = useState("")
	const [registros, setRegistros] = useState(null)
	const [problem, setProblem] = useState(false)

	const { postulacion } = useParams()

	const validFormatAdmin = id => e => {
		if (!validExtensionInputFile(e.target.value, FORMATOS_PERMITDOS)) {
			showToast("El formato del archivo no es válido")
			document.getElementById(`inputName_${id}`).value = ""
			return (e.target.value = "")
		}
	}

	const uploadFilesAdmin = async () => {
		const formData = new FormData()

		for (let index = 0; index < data.archivos.length; index++) {
			const idArchivo = data.archivos[index]["id"]
			const inputFile = document.getElementById(`inputFile_${idArchivo}`).files[0]

			if (inputFile) {
				formData.append("archivos[]", inputFile)
				formData.append("id_archivos[]", idArchivo)
			}
		}

		formData.append("id_postulante", data.postulante.id)
		formData.append("id_postulacion", data.postulacion.id)

		if(!formData.get('archivos[]'))	return showToast("Debe seleccionar archivos");
	
		showToast("Subiendo archivos...")
		const res = await sendFilesAdmin(formData)
		if(res?.response === "success"){
			fetchData()
			showToast("Archivos actualizados correctamente")
			clearInputFiles()
		}else{
			showToast('Ha ocurrido un problema. Intente nuevamente')
			setLoaded(true)
		}
	}

	const clearInputFiles = () => {
		Array.from(document.getElementsByClassName('file-path')).forEach(el => el.value = "")
		Array.from(document.getElementsByName('input_archivos')).forEach(el => el.value = "")
	}
	
	

	const fetchData = async () => {
		const result = await getPostulacion(postulacion)
		console.log(result.data)
		if (result.status === 200) {
			setLoaded(true)
			setData(result.data)
			if (result.data.registros !== null) {
				setRegistros(result.data.registros)
			}
			result.data.registros.map((reg, i) => {
				if (result.data.periodo.periodo == reg.maal_ano) {
					console.log("true")
					setProblem(true)
				}
			})
			await reloadM()
		} else {
			setLoaded(false)
			console.log(result)
		}
	}

	/* ------------- PARA OBTENER LOS TEXTOS SEGUN EL COD DE DELFOS ------------ */
	const getGeneroCod = async () => {
		const resGenero = await getGenero(data.postulante?.sexo)
		if (resGenero?.response === "success") {
			setGenero(resGenero.data[0].texto)
		} else {
			setGenero(data.postulante?.sexo)
		}
	}

	const getRegionCod = async () => {
		const resRegion = await getRegion(data.postulante?.region)
		if (resRegion?.response === "success") {
			setRegion(resRegion.data[0].vatg_des)
		} else {
			setRegion(data.postulante?.region)
		}
	}

	const getCiudadCod = async () => {
		const resCiudad = await getCiudad(data.postulante?.ciudad)
		if (resCiudad?.response === "success") {
			setCiudad(resCiudad.data[0].texto)
		} else {
			setCiudad(data.postulante?.ciudad)
		}
	}

	const getComunaCod = async () => {
		const resComuna = await getComuna(data.postulante?.comuna)
		if (resComuna?.response === "success") {
			setComuna(resComuna.data[0].texto)
		} else {
			setComuna(data.postulante?.comuna)
		}
	}

	const setDataCodigos = () => {
		if (data?.postulante) {
			getGeneroCod()
			getRegionCod()
			getCiudadCod()
			getComunaCod()
		}
	}
	/* ----------------------------------------------------------------------- */

	const checkAndSetFormaIngreso = () => {
		if (data.postulante) {
			if (data.postulante.user.serie_confirmada && !data.postulante.user.numero_serie) {
				setFormaIngreso("Clave Única")
			} else {
				setFormaIngreso("Formulario de Registro")
			}
		}
	}

	const DivConfirmaSerie = () => {
		if (data.postulante.user.serie_confirmada) {
			return (
				<Fragment>
					Nro de Documento Confirmado.{" "}
					<i style={{ fontSize: 28 + "px" }} className="green-text material-icons">
						check
					</i>
					<span
						className="a-link"
						onClick={() => openModalConfirmaSerie()}
						style={{ cursor: "pointer", marginLeft: 2 + "rem" }}
					>
						Cambiar confirmación
					</span>
				</Fragment>
			)
		} else {
			return (
				<Fragment>
					Nro de Documento No Confirmado.{" "}
					<i style={{ fontSize: 28 + "px" }} className="red-text material-icons">
						error
					</i>
					<span
						className="a-link"
						onClick={() => openModalConfirmaSerie()}
						style={{ cursor: "pointer", marginLeft: 2 + "rem" }}
					>
						Validar documento
					</span>
				</Fragment>
			)
		}
	}

	useEffect(() => {
		fetchData()
	}, []) // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		setDataCodigos()
		checkAndSetFormaIngreso()
	}, [data.postulante]) // eslint-disable-line react-hooks/exhaustive-deps

	const openModal = id => {
		console.log(id)
		let inputs = []
		if (id === "modal-mensaje" || id === "modal-rechazo") {
			const modal = document.getElementById(id)
			const instance = M.Modal.init(modal, {
				onCloseEnd: () => {}
			})
			instance.open()
			return null
		}
		if (data.estado_actual != null && data.estado_actual.nombre === "INTERESA") {
			inputs = document.getElementsByTagName("select")
		} else {
			inputs = document.querySelectorAll("input.carrera")
		}
		setCarreras([])
		let flag = 0
		let unique = 0
		for (let i = 0; i < inputs.length; i += 1) {
			if (inputs[i].value !== "-selectoption-") {
				flag += 1
				if (inputs[i].value === "ACEPTADO") unique += 1
				setCarreras(carreras =>
					carreras.concat([
						{
							codigo: inputs[i].getAttribute("data-codigo"),
							id: inputs[i].getAttribute("data-id"),
							name: inputs[i].getAttribute("data-nombre"),
							sede: inputs[i].getAttribute("data-sede"),
							value: inputs[i].value
						}
					])
				)
			}
		}
		if (unique > 1) {
			showToast("Sólo puede aceptar una carrera por postulación")
			setCarreras([])
			return null
		}
		if (flag === 0) {
			showToast("Debe al menos seleccionar una opción para una carrera")
			setCarreras([])
			return null
		}
		const modal = document.getElementById(id)
		const instance = M.Modal.init(modal, {
			onCloseEnd: () => {}
		})
		instance.open()
	}

	const openModalConfirmaSerie = () => {
		const modal = document.getElementById("modal-confirma-serie")
		const instance = M.Modal.init(modal, {
			onCloseEnd: () => {}
		})
		instance.open()
	}

	const reloadM = async () => {
		await M.AutoInit()
		// let elems =
		// let instances = M.Tooltip.init(elems)
		// elems = document.querySelectorAll(".fixed-action-btn")
		// instances = M.FloatingActionButton.init(elems)
		// elems = document.querySelectorAll(".modal")
		// instances =
		M.Modal.init(document.querySelectorAll(".tooltipped, .fixed-action-btn, .modal"))
	}

	const initAndOpenModal = async id => {
		//await M.AutoInit()
		await openModal(id)
	}

	const rowCarreras = carreras => {
		return carreras.map((carrera, i) => {
			return (
				<tr key={"carrera-" + i}>
					<td>{carrera.orden}</td>
					<td>{carrera.nombre}</td>
					<td>{carrera.tipo}</td>
					<td>{carrera.sede}</td>
					<td>{carrera.jornada}</td>
					<td>{carrera.aceptados}</td>
					<td>{carrera.lista_espera}</td>
					<td>{carrera.matriculados}</td>
					<td>{carrera.cupo}</td>
					<td>
						{data.estado_actual != null && data.estado_actual.nombre === "INTERESA" ? (
							<form action="" id={"form-carrera-" + i}>
								<div className="input-field col s12">
									<select
										name={"carrera[" + carrera.id + "]"}
										data-id={carrera.id}
										data-codigo={carrera.codigo}
										data-nombre={carrera.nombre}
										data-sede={carrera.sede}
										defaultValue="-selectoption-"
									>
										<option value="-selectoption-" disabled>
											Opción
										</option>
										<option value="ACEPTADO">Aceptar</option>
										<option value="L_ESPERA">Lista de Espera</option>
									</select>
									<label></label>
								</div>
							</form>
						) : (
							<form>
								<input
									type="hidden"
									className="carrera"
									data-id={carrera.id}
									data-codigo={carrera.codigo}
									data-nombre={carrera.nombre}
									value={carrera.estado}
								/>
								{carrera.estado}
							</form>
						)}
					</td>
				</tr>
			)
		})
	}

	const rowArchivos = archivos => {

		return archivos?.map((archivo, i) => {
			return (
				<tr key={i}>
					<td>
							<span className="icon-center" id={`span_file_${archivo.id}`}>
							<i className="material-icons blue-text text-darken-3 mx-2">attachment</i>
							<a
								href={`${archivo.pivot.path}`}
								target="_blank"
								style={{fontSize: 120+"%"}}
								rel="noopener noreferrer"
								id={"_f_" + i}
								key={"arc-" + i}
							>
								{archivo.nombre}
							</a>
							</span>
					</td>

					<td>
						<div className="file-field input-field">
							<div className="btn-small waves-light purple darken-3">
								<i className="large material-icons">file_upload</i>
								<input
									type="file"
									name="input_archivos"
									id={`inputFile_${archivo.id}`}
									onChange={validFormatAdmin(archivo.id)}
								/>
							</div>
							<div className="file-path-wrapper">
								<input
									id={`inputName_${archivo.id}`}
									className="file-path validate"
									type="text"
								/>
							</div>
						</div>
					</td>
				</tr>
			)
		})
	}

	const rowRegistros = () => {
		return registros.map((reg, i) => {
			return (
				<tr>
					<td>{reg.maal_ano}</td>
					<td>{reg.sucu_cod + " - " + reg.sucu_nom}</td>
					<td>{reg.coal_cod}</td>
					<td>{reg.caac_cod + " - " + reg.caac_nom}</td>
					<td>{reg.joac_cod}</td>
					<td>{reg.peac_cod}</td>
					<td>{reg.sucu_cod_1}</td>
					<td>{reg.evec_cod}</td>
					<td>{reg.eval_fec_registro}</td>
					<td>{reg.eval_obs}</td>
				</tr>
			)
		})
	}

	const rowAcademia = academia => {
		return (
			<Fragment>
				<tr>
					<td colSpan="2" className="table-subtitle">
						<b>Datos Académicos</b>
					</td>
				</tr>
				<tr>
					<td>Título</td>
					<td>{academia.titulo}</td>
				</tr>
				<tr>
					<td>Periodo Egreso</td>
					<td>{academia.periodo_egreso}</td>
				</tr>
			</Fragment>
		)
	}

	const rowExtranjero = extranjero => {
		return (
			<Fragment>
				<tr>
					<td colSpan="2" className="table-subtitle">
						<b>Datos Extranjería</b>
					</td>
				</tr>
				<tr>
					<td>ID Pasaporte</td>
					<td>{extranjero.id_pasaporte}</td>
				</tr>
				<tr>
					<td>País Nacionalidad</td>
					<td>{extranjero.pais_nacionalidad}</td>
				</tr>
				<tr>
					<td>País Origen</td>
					<td>{extranjero.pais_origen}</td>
				</tr>
				<tr>
					<td>Tipo de Visa</td>
					<td>{extranjero.tipo_visa}</td>
				</tr>
				<tr>
					<td>Fecha de Emisión</td>
					<td>{formatDate(extranjero.fecha_emision)}</td>
				</tr>
				<tr>
					<td>Fecha de Vencimiento</td>
					<td>{formatDate(extranjero.fecha_vencimiento)}</td>
				</tr>
				<tr>
					<td>Residencia Previa</td>
					<td>{extranjero.residencia_previa}</td>
				</tr>
			</Fragment>
		)
	}

	const menuInteresado = () => {
		return (
			<ul>
				{!problem ? (
					<li>
						<button
							onClick={() => initAndOpenModal("modal-revisar")}
							className="btn-floating tooltipped green"
							data-position="left"
							data-tooltip="Revisar Postulación"
						>
							<i className="material-icons white purple-text">rate_review</i>
						</button>
						<span style={{ fontSize: 0.85 + "em" }}>Revisar</span>
					</li>
				) : null}
				<li>
					<button
						onClick={() => initAndOpenModal("modal-rechazo")}
						className="btn-floating tooltipped green"
						data-position="left"
						data-tooltip="Cambiar estado"
					>
						<i className="material-icons white purple-text">delete</i>
					</button>
					<span style={{ fontSize: 0.85 + "em" }}>Rechazar Postulación</span>
				</li>
				{!problem ? (
					<li>
						<button
							onClick={() => initAndOpenModal("modal-mensaje")}
							className="btn-floating tooltipped green"
							data-position="left"
							data-tooltip="Enviar un Correo al postulante"
						>
							<i className="material-icons white purple-text">mail_outline</i>
						</button>
						<span style={{ fontSize: 0.85 + "em" }}>Enviar Mensaje</span>
					</li>
				) : null}
			</ul>
		)
	}

	const menuListaEspera = () => {
		return (
			<ul>
				{!problem ? (
					<li>
						<button
							onClick={() => initAndOpenModal("modal-cambiar")}
							className="btn-floating tooltipped green"
							data-position="left"
							data-tooltip="Cambiar estado"
						>
							<i className="material-icons white purple-text">autorenew</i>
						</button>
						<span style={{ fontSize: 0.85 + "em" }}>Cambiar Estado</span>
					</li>
				) : null}
				<li>
					<button
						onClick={() => initAndOpenModal("modal-rechazo")}
						className="btn-floating tooltipped green"
						data-position="left"
						data-tooltip="Cambiar estado"
					>
						<i className="material-icons white purple-text">delete</i>
					</button>
					<span style={{ fontSize: 0.85 + "em" }}>Rechazar Postulación</span>
				</li>
				{!problem ? (
					<li>
						<button
							onClick={() => initAndOpenModal("modal-mensaje")}
							className="btn-floating tooltipped green"
							data-position="left"
							data-tooltip="Enviar un Correo al postulante"
						>
							<i className="material-icons white purple-text">mail_outline</i>
						</button>
						<span style={{ fontSize: 0.85 + "em" }}>Enviar Mensaje</span>
					</li>
				) : null}
			</ul>
		)
	}

	return (
		<div>
			<Header />
			<div className="row container title-scope">
				<div className="title col s12 l12 left">
					<h6>Detalle de</h6>
					<h2>Postulación</h2>
				</div>
			</div>
			<div className="row container title-scope margin-bottom-24px">
				<div className="col s12 m12 l8 note text-align-justify">
					A continuación se muestra el detalle de la postulación realizada
				</div>
			</div>
			{!loaded ? (
				<Loader loader={!loaded} />
			) : (
				<div className="container">
					<div className="card">
						<div className="row">
							<div className="col s12">
								<div className="row" style={{ marginBottom: 0 + "px" }}>
									<div className="col s12 m12 l12">
										<span style={{ fontSize: 2.6 + "rem" }}>Postulación </span>
										{/* <span> */}
										{/* <a href="#"> */}
										<span style={{ fontSize: 3.6 + "rem" }}>
											{data.postulacion != null ? data.postulacion.id : null}
										</span>
										{/* </a> */}
										{/* </span> */}
									</div>
								</div>
								<div className="row">
									<div className="col s12 m12 l12">
										<span>
											<b>Fecha de Emisión </b>
										</span>
										<span>{data.postulacion != null ? data.postulacion.fecha : null}</span>
									</div>
								</div>
							</div>
						</div>
						<div className="row">
							<div className="col s12 m12 l12">
								{data.estados != null ? Timeline({ estados: data.estados }) : null}
							</div>
						</div>
						<div className="row margin-bottom-8px">
							<div className="col s12 m12 l12">
								<h2 className="subtitle">
									<span className="blue-grey-text">Antecedentes del Postulante</span>
								</h2>
							</div>
						</div>
						<div className="row">
							<div className="col s12 m12 l12">
								<table className="table-responsive">
									<tbody>
										<tr>
											<td colSpan="2" className="table-subtitle">
												<b>Datos Personales</b>
											</td>
										</tr>
										<tr>
											<td>Forma de Ingreso</td>
											<td>{formaIngreso}</td>
										</tr>
										<tr>
											<td>Rut</td>
											<td>
												{data.datos_personales != null
													? data.datos_personales.run + "-" + data.datos_personales.dv
													: null}
											</td>
										</tr>
										{data.postulante != null && data.postulante.user.numero_serie && (
											<tr>
												<td>Número de Documento</td>
												<td style={{ position: "relative" }}>
													{data.postulante.user.numero_serie}
													<span
														className="icon-center"
														style={{ position: "absolute", left: 25 + "%", top: 0.85 + "rem" }}
													>
														{DivConfirmaSerie()}
													</span>
												</td>
											</tr>
										)}

										<tr>
											<td>Nombres</td>
											<td>
												{data.datos_personales != null ? data.datos_personales.nombres : null}
											</td>
										</tr>
										<tr>
											<td>Apellido Paterno</td>
											<td>
												{data.datos_personales != null ? data.datos_personales.paterno : null}
											</td>
										</tr>
										<tr>
											<td>Apellido Materno</td>
											<td>
												{data.datos_personales != null ? data.datos_personales.materno : null}
											</td>
										</tr>
										<tr>
											<td>Sexo</td>
											<td>{genero}</td>
											{/* <td>{ (data.postulante!=null)? data.postulante.sexo : null }</td> */}
										</tr>
										<tr>
											<td>Fecha Nacimiento</td>
											<td>
												{data.postulante != null
													? formatDate(data.postulante.nacimiento)
													: null}
											</td>
										</tr>
										<tr>
											<td>Nacionalidad</td>
											<td>
												{data?.postulante?.nacionalidad === "N"
													? "Nacional"
													: data?.postulante?.nacionalidad === "E"
													? "Extranjero"
													: ""}
											</td>
										</tr>
										<tr>
											<td>Teléfonos</td>

											<td>
												{data.postulante != null
													? data.postulante.telefono + " - " + data.postulante.telefono_trabajo
													: null}
											</td>
										</tr>
										<tr>
											<td>Correo Electrónico</td>
											<td>
												{data.datos_personales != null ? data.datos_personales.email : null}
											</td>
										</tr>
										{data.academia != null ? rowAcademia(data.academia) : null}
										{data.extranjero != null ? rowExtranjero(data.extranjero) : null}
										<tr>
											<td colSpan="2" className="table-subtitle">
												<b>Datos Ubicación</b>
											</td>
										</tr>
										<tr>
											<td>Dirección</td>
											<td>
												{data.postulante != null
													? data.postulante.calle +
													  " " +
													  data.postulante.numero +
													  " " +
													  (data.postulante.depto_casa != null
															? data.postulante.depto_casa
															: "")
													: null}
											</td>
										</tr>
										<tr>
											<td>Comuna - Ciudad</td>
											<td>
												{comuna} - {ciudad}
											</td>
											{/* <td>
												{data.postulante != null
													? data.postulante.comuna + " - " + data.postulante.ciudad
													: null}
											</td> */}
										</tr>
										<tr>
											<td>Región</td>
											<td>{region}</td>
											{/* <td>{data.postulante != null ? data.postulante.region : null}</td> */}
										</tr>
										<tr>
											<td>Periodo Egreso EM</td>
											<td>
												{data.postulante != null && data.postulante.periodo_egreso_em != null
													? data.postulante.periodo_egreso_em
													: null}
											</td>
										</tr>
									</tbody>
								</table>
							</div>
						</div>
						<div className="row margin-bottom-8px">
							<div className="col s12 m12 l12">
								<h2 className="subtitle">
									<span className="blue-grey-text">Antecedentes previos</span>
								</h2>
							</div>
						</div>
						{registros !== null ? (
							<div className="row yellow lighten-4">
								<div className="col s12">
									<table>
										<thead>
											<tr>
												<th>Año Matrícula</th>
												<th>Sede</th>
												<th>Carrera</th>
												<th>Estado Matrícula</th>
												<th>Jornada</th>
												<th>Periodo Evento</th>
												<th>Sede Evento</th>
												<th>Código Evento</th>
												<th>Fecha Registro</th>
												<th>Detalles/Observaciones</th>
											</tr>
										</thead>
										<tbody>{rowRegistros()}</tbody>
									</table>
								</div>
							</div>
						) : (
							<div className="row">
								<div className="col s12">
									<p>Sin antecedentes</p>
								</div>
							</div>
						)}
						<div className="row margin-bottom-8px">
							<div className="col s12 m12 l12">
								<h2 className="subtitle">
									<span className="blue-grey-text">Selección Carreras</span>
								</h2>
							</div>
						</div>
						<div className="row">
							<div className="col s12 m12 l12">
								<table className="table-responsive">
									<thead>
										<tr>
											<th>Pref.</th>
											<th>Carrera</th>
											<th>Tipo</th>
											<th>Sede</th>
											<th>Jornada</th>
											<th>Aceptados</th>
											<th>Lista de Espera</th>
											<th>Matriculados</th>
											<th>Total Cupo</th>
											<th>
												{data.estado_actual != null && data.estado_actual.nombre === "INTERESA"
													? "Seleccionar"
													: "Estado"}
											</th>
										</tr>
									</thead>
									<tbody>{data.carreras != null ? rowCarreras(data.carreras) : null}</tbody>
								</table>
							</div>
						</div>
						<div className="row margin-bottom-8px">
							<div className="col s12 m12 l12">
								<h2 className="subtitle">
									<span className="blue-grey-text">Archivos Adjuntos</span>
								</h2>
							</div>
						</div>
						<div className="row">
							<div className="col s12">
								<table>
									<thead>
										<tr>
											<th>Archivo Subido</th>
											<th> Reemplazar archivo</th>
										</tr>
									</thead>
									<tbody>
										{data.archivos != null ? rowArchivos(data.archivos) : null}
										<tr>
											<td colSpan="2">
												<button
													onClick={uploadFilesAdmin}
													className="waves-effect waves-light bg-ula-blue "
												>
													<i className="material-icons right">backup</i> Subir archivos
												</button>
											</td>
										</tr>
									</tbody>
								</table>
							</div>
						</div>
						<br />
						<div className="fixed-action-btn direction-top">
							<button className="btn-floating btn-large theme-button">
								<i className="material-icons">menu</i>
							</button>
							{data.estado_actual != null && data.estado_actual.nombre === "INTERESA"
								? menuInteresado()
								: null}
							{data.estado_actual != null && data.estado_actual.nombre === "L_ESPERA"
								? menuListaEspera()
								: null}
						</div>
					</div>
				</div>
			)}
			<ModalRevisar
				postulacion={postulacion}
				carreras={carreras}
				rut={data.datos_personales != null ? data.datos_personales.run : null}
				periodo={data.periodo != null ? data.periodo.periodo : null}
				refresh={fetchData}
			/>
			<ModalCambiar postulacion={postulacion} carreras={carreras} refresh={fetchData} />
			<ModalMensaje postulacion={postulacion} refresh={fetchData} />
			<ModalRechazar postulacion={postulacion} refresh={fetchData} />
			<ModalConfirmaSerie user={data?.postulante?.user} refresh={fetchData} />
		</div>
	)
}

export default withRouter(Postulacion)
